import React from "react";
import Modal from "react-modal";
import { IModalProps } from "../../../../../utils/type";
import { BtnSubmit } from "../../../../common/Button";
import { InputAdmin } from "../../../../common/Input";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import useCrudTeam from "../hooks/useCrudTeam";
import { ITeam } from "../../../../../utils/api/team/team.type";
import { getImage } from "../../../../../utils/Utils";

const styles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		zIndex: 1,
		overflowY: "auto",
		height: "520px",
	},
};
Modal.setAppElement("#root");
const AddMembreModal: React.FC<IModalProps<ITeam>> = ({ isOpen, closeModal, item }) => {
	const { register, onSubmit, errors, isLoading, image, handleChangeImage } = useCrudTeam(
		closeModal,
		item
	);
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={styles}
			contentLabel="addAdminModal"
		>
			<div className="modal-header pe-2 py-1">
				<h5 className="modal-title-admin" id="ChangePasswordModalLabel">
					{item ? "Modifer le" : "Ajouter un"} membre
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student">
					<div className="col-12 col-form-add-student">
						<InputAdmin
							type="text"
							label="Nom complet"
							id="name"
							required
							placeholder="Nom complet"
							{...register("name")}
							error={errors?.name?.message}
						/>
					</div>
					<div className="col-12 col-form-add-student">
						<InputAdmin
							type="text"
							label="Fonction"
							id="function"
							required
							placeholder="Fonction"
							{...register("function")}
							error={errors?.function?.message}
						/>
					</div>
					<div className="col-12">
						<InputAdmin
							type="file"
							label="Photo"
							id="image"
							required
							onChange={handleChangeImage}
							error={errors?.image?.message}
						/>

						{(item || image) && (
							<img
								src={image ? image : item?.image ? getImage(item.image) : ""}
								alt={item?.name}
								className="w-100 img-thumbnail"
								style={{
									width: "100px",
									height: "100px",
									maxWidth: "100px",
								}}
							/>
						)}
					</div>
				</div>
				<div className="row row-content-btn-action pt-3">
					<div className="col-md-4 offset-md-8">
						<BtnSubmit
							label={item ? "Modifier" : "Ajouter"}
							isLoading={isLoading}
							full
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddMembreModal;
