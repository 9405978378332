import { useCrudArticle } from "./hooks/useCrudArticle";
import ReactQuill from "react-quill";
import { FormError, InputAdmin, RequiredText } from "../../../common/Input";
import { PreviewImage, ViewUniqueImage } from "../../../common/PreviewImage";
import { BtnSubmit } from "../../../common/Button";
import * as Yup from "yup";
import { SelectInput } from "../../../common/SelectInput";

export default function ArticleForm({ schema }: { schema: Yup.AnyObjectSchema }) {
	const {
		register,
		onSubmit,
		errors,
		description,
		handleChangeImageCouverture,
		imageCouverture,
		onChangeDescription,
		isLoading,
		item,
		categories,
	} = useCrudArticle(schema);
	return (
		<form onSubmit={onSubmit}>
			<div className="row">
				<div className="col-md-6 mb-3">
					<div className="mb-3">
						<InputAdmin
							label="Titre"
							type="text"
							id="titre"
							error={errors?.title}
							data-testid="titreId"
							placeholder="Titre"
							{...register("title")}
							required
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<SelectInput
							label="Catégorie"
							options={categories}
							required
							{...register("category")}
							error={errors?.category}
						/>
					</div>
				</div>
				<div className="col-md-12 mb-3">
					<InputAdmin
						label="Images"
						required
						type="file"
						id="image"
						error={errors?.image}
						data-testid="imageId"
						onChange={handleChangeImageCouverture}
					/>
					{imageCouverture ? (
						<PreviewImage image={imageCouverture} />
					) : item?.image ? (
						<ViewUniqueImage image={item?.image} />
					) : null}
				</div>
				<div className="col--12">
					<label htmlFor="contenu" className="form-label form-label-admin">
						Contenu
						<RequiredText />
					</label>
					<ReactQuill theme="snow" value={description} onChange={onChangeDescription} />
					<FormError error={errors?.content} />
				</div>
			</div>
			<div className="container-btn-modal  flex-r mt-4 w-100">
				<BtnSubmit label={item ? "Modifier" : "Ajouter"} isLoading={isLoading} />
			</div>
		</form>
	);
}
