import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import ImgLogin from "../../assets/appImages/login-img.png";
import { FcGoogle } from "react-icons/fc";
import useLoginForm, { useSocialLogin } from "./useAuthForm/useLoginForm";
import ErrorMessage from "../common/ErrorMessage";
import { RiEyeLine } from "react-icons/ri";
import { AiFillEyeInvisible } from "react-icons/ai";
import { IResolveParams, LoginSocialApple, LoginSocialGoogle } from "reactjs-social-login";
import { BsApple } from "react-icons/bs";
import { GoogleClientId } from "../../utils/http";

const Login = () => {
	const { register, onSubmit, errors, isLoading } = useLoginForm();
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};
	const { loginWithGoogle } = useSocialLogin();

	return (
		<div>
			<div className="container-page-login-yeewuna">
				<div className="auth-row row">
					<div className="col-md-6 auth-col auth-left-side-col d-flex">
						<div className="auth-left-side-container w-100">
							<img src={ImgLogin} alt="connexion" className="img-login" />
						</div>
					</div>
					<div className="col-md-6 auth-col auth-right-side-col d-flex">
						<div className="auth-right-side-container w-100">
							<div className="auth-form-container">
								<div className="container-img-logo pb-4 text-center">
									<img src={Logo} alt="Logo" className="img-logo-login" />
								</div>
								<h1 className="auth-form-title">Connectez-vous</h1>
								<p className="text-message-login">
									Entrez votre email et votre mot de passe pour vous connecter
								</p>
								<div className="mt-4">
									<form id="auth-form" onSubmit={onSubmit}>
										<div className="row auth-form-row">
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label className="form-label form-label-auth-login">
														Email
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`email`}
														className="form-control auth-form-control"
														id="email"
														placeholder="Email"
														aria-label="Email"
														{...register("email")}
													/>
													{errors?.email && (
														<ErrorMessage
															message={errors?.email?.message}
														/>
													)}
												</div>
											</div>
											<div className="col-md-12 auth-input-col mb-2">
												<div className="auth-form-group position-relative">
													<label className="form-label form-label-auth-login">
														Mot de passe
														<span
															className="text-danger"
															style={{ fontSize: 18 }}
														>
															*
														</span>
													</label>
													<input
														type={`${
															showPassword ? "text" : "password"
														}`}
														className="form-control auth-form-control"
														id="password"
														placeholder="Mot de passe"
														aria-label="Password"
														{...register("password")}
													/>
													<span
														className="span-eye-password-log"
														onClick={toggleShowPassword}
													>
														{!showPassword ? (
															<RiEyeLine />
														) : (
															<AiFillEyeInvisible />
														)}
													</span>
												</div>
												{errors?.password && (
													<ErrorMessage
														message={errors?.password?.message}
													/>
												)}
											</div>
											<div className="d-flex mb-3">
												<Link
													to={"/mot-de-passe-oublie"}
													className="forget-password-link"
												>
													Mot de passe oublié ?
												</Link>
											</div>
											<div className="d-flex my-3 gap-3">
												<div className="content-switch-register">
													<label
														htmlFor="with-warrenty"
														className="switch-container"
													>
														<input
															hidden
															type="checkbox"
															id="with-warrenty"
															{...register("remember_me")}
														/>
														<div className="switch-bg"></div>
														<div className="round-box"></div>
														<div className="switch-left">
															<span>Non</span>
														</div>
														<div className="switch-right">
															<span>Oui</span>
														</div>
													</label>
												</div>
												<div>
													<p className="text-se-souvenir-de-moi mb-0">
														Se souvenir de moi
													</p>
												</div>
											</div>
											<div className="col-md-12 auth-submit-btn-container mt-4">
												{!isLoading ? (
													<button
														type="submit"
														className="btn auth-submit-btn trans-0-2 w-100"
														disabled={isLoading}
													>
														Connexion
													</button>
												) : (
													<button
														type="submit"
														className="btn auth-submit-btn trans-0-2 w-100"
													>
														<span
															className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
															role="status"
														></span>
														<span>Chargement...</span>
													</button>
												)}
											</div>
										</div>
										<div className="content-text-message-login mt-3 text-center">
											<span className="auth-form-message">
												Vous n’avez pas de compte?
											</span>
											<NavLink
												to="/inscription"
												className="btn-text-message-login ps-2"
											>
												Inscrivez-vous
											</NavLink>
										</div>
										<div className="row align-items-center my-3">
											<div className="col-md-4">
												<hr />
											</div>
											<div className="col-md-4">
												<p className="text-login-social-media mb-0">
													Ou se connecter avec
												</p>
											</div>
											<div className="col-md-4">
												<hr />
											</div>
										</div>
										<div className="container-social-media-login pt-5">
											<LoginSocialGoogle
												client_id={GoogleClientId}
												isOnlyGetToken={false}
												onLoginStart={() => console.log("login")}
												onResolve={loginWithGoogle}
												onReject={(err) => {
													console.log(err);
												}}
											>
												<button
													className="btn btn-social-media-login google"
													type="button"
												>
													<FcGoogle />
												</button>
											</LoginSocialGoogle>
											<LoginSocialApple
												client_id={"yeewuna"}
												scope="name"
												redirect_uri="https://yeewuna-app.withvolkeno.com/"
												onLoginStart={() => console.log("login")}
												onResolve={({ provider, data }: IResolveParams) => {
													console.log(data, provider);
												}}
												onReject={(err) => {
													console.log(err);
												}}
											>
												<button
													className="btn btn-social-media-login apple"
													type="button"
												>
													<BsApple />
												</button>
											</LoginSocialApple>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
