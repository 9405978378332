import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Book } from "../../utils/api/book/book.type";

type Cart = {
	cartItems: CartItemType[];
	user: string;
	totalCount: number;
	tax: number;
	subAmount: number;
	totalAmount: number;
};

export type CartItemType = {
	item: Book;
	item_type: "livre" | "ebook" | "audiobook";
	price: number;
	qty: number;
	user: number;
};
const initialState: Cart = {
	cartItems: [],
	user: "",
	totalCount: 0,
	tax: 0,
	subAmount: 0,
	totalAmount: 0,
};
export const useCartSlice = createSlice({
	name: "panier",
	initialState,
	reducers: {
		addCartBook: (state, action: PayloadAction<CartItemType>) => {
			let cartIndex = state.cartItems.findIndex(
				(item) =>
					item?.item?.id === action.payload.item?.id &&
					item?.item_type === action?.payload?.item_type
			);
			// if (!state?.user) {
			// 	state.user = action.payload.user;
			// }
			if (cartIndex >= 0) {
				state.cartItems[cartIndex].qty += action?.payload?.qty;
			} else {
				let tempBook = { ...action.payload };
				state.cartItems.push(tempBook);
			}
		},
		getCartBooks: (state, action: PayloadAction<number>) => {
			let items = state?.cartItems?.filter((item) => item?.user === action?.payload) || [];
			state.cartItems = items;
			return {
				...state,
			};
		},
		getCartCount: (state, action: PayloadAction<number>) => {
			let cartCount = state.cartItems
				?.filter((item) => item?.user === action?.payload)
				?.reduce((total, item) => {
					return item?.qty + total;
				}, 0);
			state.totalCount = cartCount;
		},
		getSubTotal: (state, action: PayloadAction<number>) => {
			state.subAmount = state.cartItems
				?.filter((item) => item?.user === action?.payload)
				?.reduce((acc, item) => {
					return acc + item?.price * item?.qty;
				}, 0);
		},
		removeCartItem: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) =>
					item?.item?.id === action.payload?.item?.id &&
					item?.item_type === action?.payload?.item_type
			);
			if (index !== -1) {
				state.cartItems.splice(index, 1);
			}
		},
		increment: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) =>
					item?.item?.id === action.payload.item?.id &&
					item?.user === action?.payload?.user &&
					item?.item_type === action?.payload?.item_type
			);
			state.cartItems[index].qty += 1;
		},
		decrement: (state, action: PayloadAction<CartItemType>) => {
			let index = state.cartItems.findIndex(
				(item) =>
					item?.item?.id === action.payload.item?.id &&
					item?.user === action?.payload?.user &&
					item?.item_type === action?.payload?.item_type
			);
			if (state.cartItems[index].qty <= 0) {
				state.cartItems[index].qty = 0;
				state.cartItems.splice(index, 1);
			} else {
				state.cartItems[index].qty -= 1;
			}
		},
		calculateTax: (state) => {
			// GST value: 18% => action.payload
			let totalTax = (18 / 100) * state.subAmount;
			state.tax = totalTax;
		},
		getTotalAmount: (state) => {
			state.totalAmount = state?.tax + state?.subAmount;
		},
		removeCart: (state) => {
			state = initialState;
			return state;
		},
	},
});

export const {
	addCartBook,
	getCartBooks,
	removeCartItem,
	getCartCount,
	getSubTotal,
	increment,
	decrement,
	calculateTax,
	getTotalAmount,
	removeCart,
} = useCartSlice.actions;
export default useCartSlice.reducer;
