import React, { useState } from "react";
import { AiOutlineClockCircle, AiOutlineFire } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import "./Communaute.css";
import { NavLink } from "react-router-dom";
import { useGetForumSubjectsQuery } from "../../../../utils/api/forum/forum.api";
import { usePagination } from "../../../../utils/hooks";
import ForumSubjectItem, { ForumSubjectItemSkeleton } from "./ForumSubjectItem";
import { AlertInfo } from "../../../common/Alert";
import MapSkeleton from "../../../common/MapSkeleton";
import { ForumStatus } from "../../../../utils/api/forum/forum.type";

function Communaute() {
	const [status, setStatus] = useState<ForumStatus | undefined>();
	const { page, limit } = usePagination(10);
	const { data, isLoading } = useGetForumSubjectsQuery({ page, limit, statut: status });
	const handleChangeStatus = (status: ForumStatus) => {
		setStatus(status);
	};
	const getStatus = (s: ForumStatus) => {
		if (status === s) {
			return "active";
		}
		return "";
	};
	return (
		<div className="dashbord-visiteur-component">
			<div className="user-dash-page-content-container align-items-center mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="tabs-search-input-container">
							<div className="yeewuna-parcours-utilisateur-titre-communaute">
								Communauté
							</div>
							<div className="yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication-container">
								<NavLink
									to={"/utilisateur/ajouter-publication"}
									className="btn yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication no-link"
								>
									<HiPlus /> Nouvelle publication
								</NavLink>
							</div>
						</div>
					</div>
				</div>
				<div className="yeewuna-parcours-utilisateur-livres-container mb-4">
					<div className="yeewuna-parcours-utilisateur-communaute-btn-filter-container">
						<button
							className={`btn utilisateur-communaute-btn-filter ${
								!status ? "active" : ""
							}`}
							onClick={() => setStatus(undefined)}
						>
							Tout
						</button>
						<button
							className={`btn utilisateur-communaute-btn-filter ${getStatus(
								ForumStatus.new
							)}`}
							onClick={() => handleChangeStatus(ForumStatus.new)}
						>
							<AiOutlineClockCircle /> Nouveau
						</button>
						<button
							className={`btn utilisateur-communaute-btn-filter ${getStatus(
								ForumStatus.hot
							)}`}
							onClick={() => handleChangeStatus(ForumStatus.hot)}
						>
							<AiOutlineFire /> Chauds
						</button>
						<button
							className={`btn utilisateur-communaute-btn-filter ${getStatus(
								ForumStatus.close
							)}`}
							onClick={() => handleChangeStatus(ForumStatus.close)}
						>
							<BsCheck2Circle /> Fermés
						</button>
					</div>
				</div>
				<div className="row">
					{!isLoading ? (
						<>
							{data && data?.results?.length > 0 ? (
								<>
									{data?.results?.map((item) => (
										<ForumSubjectItem key={item.slug} item={item} />
									))}
								</>
							) : (
								<AlertInfo message="Aucune publication trouvée" />
							)}
						</>
					) : (
						<>
							<MapSkeleton total={4}>
								<ForumSubjectItemSkeleton />
							</MapSkeleton>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Communaute;
