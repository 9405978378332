import { Category } from "../category/category.type";
import { IUser } from "../user/user.type";
export enum ForumStatus {
	new = "new",
	hot = "hot",
	close = "close",
}
export interface ILikeForum {
	id: number;
	slug: string;
	message: IForum;
	user: IUser;
}

export type LikeForumData = Partial<Pick<ILikeForum, "slug"> & { message: number; user: number }>;
export interface IForum {
	id: number;
	slug?: string;
	subject: string;
	text: string;
	nb_views: number;
	status: ForumStatus;
	categories: Category[];
	created_at: string;
	created_by: IUser;
	message_likes: ILikeForum[];
	response_messages: number;
}

export interface ICommentForum {
	id: number;
	slug: string;
	response: string;
	message: number;
	response_message: number;
	responses: ICommentForum[];
	message_likes: ILikeForum[];
	created_at: string;
	created_by: IUser;
}

export type ForumFormData = Pick<IForum, "text" | "slug" | "subject" | "status"> & {
	created_by: number;
	categories: number[];
};
export type ForumIncrementViewData = Pick<IForum, "slug" | "nb_views">;
export type ForumCommentFormData = Pick<
	ICommentForum,
	"response" | "message" | "response_message"
> & {
	created_by: number;
	forum: number;
};


