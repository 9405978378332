import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { TeamFormData, ITeam } from "./team.type";

export const TeamApi = createApi({
	reducerPath: "teamAPi",
	tagTypes: ["team", "team_by_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getTeam: builder.query<PaginationResults<ITeam>, TypeQuery>({
			query: () => `team/`,
			providesTags: ["team"],
		}),
		getTeamForVisitors: builder.query<ITeam[], void>({
			query: () => ({
				url: `visitor_team`,
			}),
			providesTags: ["team"],
			transformResponse: ({ results }) => results,
		}),
		getTeamBySlug: builder.query<ITeam, string>({
			query: (slug) => `team/${slug}/`,
			providesTags: ["team_by_slug"],
		}),
		addOrUpdateTeam: builder.mutation<ITeam, { slug?: string; data: TeamFormData | FormData }>({
			invalidatesTags: ["team"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `team/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `team/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editTeam: builder.mutation<ITeam, { slug?: string; data: TeamFormData | FormData }>({
			invalidatesTags: ["team"],
			query: ({ slug, data }) => {
				return {
					url: `team/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteTeam: builder.mutation<ITeam, string>({
			query: (slug) => ({
				url: `team/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["team"],
		}),
	}),
});

export const {
	useGetTeamQuery,
	useGetTeamBySlugQuery,
	useEditTeamMutation,
	useLazyGetTeamQuery,
	useAddOrUpdateTeamMutation,
	useDeleteTeamMutation,
	useGetTeamForVisitorsQuery,
} = TeamApi;
