import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";
import { ITeam, TeamFormData } from "../../../../../utils/api/team/team.type";
import { useAddOrUpdateTeamMutation } from "../../../../../utils/api/team/team.api";

function useCrudTeam(closeModal: () => void, item?: ITeam) {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nom"),
		function: yup.string().required().label("Fonction"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<TeamFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [image, setImage] = useState<string>();

	const [addOrUpdateOffer, { isLoading }] = useAddOrUpdateTeamMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			setValue("name", item?.name);
			setValue("function", item?.function);
		}
	}, [item]);

	const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file = e.currentTarget.files[0];
			setImage(URL.createObjectURL(file));
			setValue("image", file);
		}
	};

	const onSubmit = async (data: TeamFormData) => {
		if (!item && !data?.image) {
			return setError("image", { message: "La Photo est obligatoire" });
		}
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrUpdateOffer({ slug: item?.slug, data: fd });
		if ("data" in res) {
			closeModal();
			Swal.fire({
				icon: "success",
				title: `Membre ${!item ? "ajoutée" : "modifiée"} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1000,
			}).then(() => {
				reset();
				setImage(undefined);
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		image,
		handleChangeImage,
	};
}

export default useCrudTeam;
