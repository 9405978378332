import React from 'react'
import { IOffer } from "../../../../utils/api/offer/offer.type";
import ListOffer from "../../offer/ListOffer";

function AbonnementModale({ handleChoiceOffer }: { handleChoiceOffer: (item?: IOffer) => void }) {
	return (
		<div className="modal-dialog modal-xl">
			<div className="modal-content">
				<div className="modal-body">
					<section className="section-avantage-home-page container-abonnement-home-page">
						<div className="container-app-yeewuna">
							<div className="content-title-section-avantage text-center">
								<h2 className="h2-theme position-relative">
									Nos offres d’abonnement
								</h2>
								<p className="text-avantage-list-homepage pt-4">
									Choisissez le forfait qui vous convient
								</p>
							</div>
							<ListOffer handleChoiceOffer={handleChoiceOffer} />
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}

export default AbonnementModale