import React from "react";

const Logo = () => {
	return (
		<svg
			width="88"
			height="85"
			viewBox="0 0 88 85"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.8936 69.149C9.54058 72.145 8.11088 75.33 6.60428 78.703C5.09768 82.075 4.29838 83.867 4.20618 84.079L2.76048 83.56L4.92238 79.073C4.86608 78.932 4.42958 77.914 3.61288 76.018C2.79628 74.123 1.79918 71.857 0.621582 69.22L2.18248 68.54C2.95048 70.479 3.73128 72.395 4.52488 74.289C5.31848 76.182 5.73648 77.177 5.77868 77.274H5.79208C5.83178 77.205 6.22858 76.278 6.98248 74.492C7.73648 72.706 8.55888 70.737 9.44968 68.582L10.8936 69.149Z"
				fill="#E59B34"
			/>
			<path
				d="M22.0254 73.6241H14.4683C14.4684 74.3301 14.6293 75.0261 14.9387 75.6601C15.2583 76.2921 15.7468 76.8241 16.3499 77.1961C16.7759 77.4611 17.2421 77.6551 17.7303 77.7721C18.3403 77.9111 18.9652 77.9761 19.5908 77.9641C19.9863 77.9641 20.3588 77.9541 20.6814 77.9331C21.0039 77.9121 21.2171 77.8981 21.2939 77.8911L21.1019 79.2811C21.0462 79.2811 20.8619 79.3001 20.547 79.3191C20.2321 79.3381 19.8654 79.3441 19.4391 79.3441C18.4514 79.3641 17.4674 79.2171 16.5284 78.9101C15.7728 78.6621 15.0798 78.2531 14.4971 77.7121C14.0145 77.2451 13.6237 76.6911 13.3451 76.0801C13.0225 75.3901 12.8604 74.6361 12.8708 73.8741C12.8597 73.1401 12.98 72.4111 13.226 71.7201C13.4706 71.0641 13.8316 70.4581 14.2916 69.9301C14.712 69.4511 15.2377 69.0751 15.8276 68.8321C16.4276 68.5781 17.0729 68.4471 17.7246 68.4481C18.3137 68.4371 18.8997 68.5361 19.4526 68.7401C19.9505 68.9331 20.3978 69.2371 20.7601 69.6291C21.1872 70.1051 21.5079 70.6661 21.7009 71.2761C21.9287 72.0381 22.0381 72.8301 22.0254 73.6241ZM20.4049 72.4531C20.2935 71.5891 19.9992 70.9111 19.5217 70.4201C19.2759 70.1731 18.9813 69.9791 18.6567 69.8531C18.3321 69.7261 17.9845 69.6681 17.6363 69.6831C17.2773 69.6721 16.9197 69.7321 16.5841 69.8601C16.2486 69.9881 15.9419 70.1821 15.6817 70.4291C15.1291 70.9831 14.7512 71.6871 14.595 72.4531H20.4049Z"
				fill="#E59B34"
			/>
			<path
				d="M33.4072 73.624H25.8501C25.8502 74.33 26.0111 75.0261 26.3205 75.6601C26.6401 76.2921 27.1286 76.824 27.7317 77.196C28.1577 77.461 28.6239 77.6551 29.1122 77.7721C29.7222 77.9111 30.347 77.976 30.9726 77.964C31.3682 77.964 31.7406 77.954 32.0632 77.933C32.3858 77.912 32.5989 77.898 32.6757 77.891L32.4837 79.281C32.428 79.281 32.2437 79.3 31.9288 79.319C31.6139 79.338 31.2472 79.344 30.821 79.344C29.8332 79.364 28.8492 79.2171 27.9102 78.9101C27.1546 78.6621 26.4616 78.253 25.8789 77.712C25.3963 77.245 25.0055 76.691 24.7269 76.08C24.4044 75.39 24.2422 74.636 24.2526 73.874C24.2417 73.141 24.362 72.412 24.6078 71.722C24.8524 71.066 25.2134 70.46 25.6734 69.932C26.0938 69.453 26.6195 69.077 27.2094 68.834C27.8094 68.579 28.4547 68.449 29.1064 68.45C29.6955 68.439 30.2815 68.538 30.8344 68.742C31.3323 68.935 31.7796 69.239 32.1419 69.631C32.569 70.107 32.8897 70.668 33.0827 71.278C33.3103 72.039 33.4197 72.83 33.4072 73.624ZM31.7867 72.4531C31.6754 71.5891 31.381 70.911 30.9035 70.42C30.6578 70.173 30.3631 69.979 30.0385 69.853C29.7139 69.726 29.3663 69.668 29.0181 69.683C28.6591 69.672 28.3015 69.732 27.966 69.86C27.6304 69.988 27.3237 70.182 27.0635 70.43C26.5109 70.983 26.133 71.6871 25.9768 72.4531H31.7867Z"
				fill="#E59B34"
			/>
			<path
				d="M51.342 69.127C50.401 71.431 49.537 73.683 48.75 75.884C47.9627 78.084 47.5551 79.224 47.5269 79.303H46.2424C46.2207 79.242 45.9397 78.419 45.3995 76.836C44.8594 75.252 44.1925 73.359 43.3989 71.156H43.3778C42.6815 73.004 42.0024 74.81 41.3407 76.575L40.3154 79.303H39.0309C38.981 79.11 38.6008 78.015 37.8904 76.02C37.18 74.024 36.3269 71.748 35.3311 69.19L36.8517 68.509L39.7413 77.085H39.7624L42.8133 68.897H44.1208L46.9317 77.085H46.9528L49.906 68.551L51.342 69.127Z"
				fill="#E59B34"
			/>
			<path
				d="M62.9121 79.303H61.3131V78.089C60.7291 78.462 60.1201 78.793 59.4891 79.078C58.8181 79.403 58.0851 79.576 57.3401 79.585C56.3801 79.585 55.6281 79.299 55.0821 78.727C54.5371 78.155 54.2681 77.354 54.2681 76.321C54.2681 75.764 54.2731 75.101 54.2841 74.33C54.2951 73.562 54.2991 73 54.2991 72.65C54.2991 72.016 54.2911 71.234 54.2741 70.302C54.2551 69.371 54.2471 68.847 54.2471 68.728H55.8981C55.8981 68.82 55.8981 69.321 55.8771 70.235C55.8561 71.149 55.8561 71.876 55.8561 72.42C55.8561 72.902 55.8601 73.502 55.8681 74.219C55.8681 74.937 55.8681 75.448 55.8681 75.755C55.8811 76.576 56.0251 77.179 56.2961 77.563C56.5661 77.947 57.0061 78.139 57.6131 78.139C58.3221 78.119 59.0181 77.947 59.6561 77.636C60.2271 77.395 60.7751 77.102 61.2921 76.759C61.2921 76.674 61.2951 76.29 61.3031 75.607C61.3111 74.923 61.3111 74.067 61.3031 73.038C61.3031 72.306 61.2941 71.429 61.2761 70.408C61.2591 69.386 61.2511 68.831 61.2511 68.739H62.9021C62.9021 68.858 62.8951 69.25 62.8811 69.916C62.8671 70.582 62.8601 71.716 62.8601 73.32C62.8601 74.216 62.8641 75.006 62.8721 75.689C62.8791 76.373 62.8931 77.577 62.9121 79.303Z"
				fill="#E59B34"
			/>
			<path
				d="M75.882 79.3041H74.231C74.231 79.1121 74.246 78.4651 74.257 77.3701C74.269 76.2761 74.273 75.5981 74.273 75.3411C74.273 74.9771 74.273 74.4421 74.273 73.7361C74.273 73.0271 74.273 72.5091 74.257 72.1821C74.237 71.3801 74.084 70.8041 73.799 70.4541C73.513 70.1051 73.016 69.9301 72.309 69.9281C71.763 69.9581 71.228 70.088 70.73 70.312C70.07 70.579 69.434 70.9001 68.828 71.2721C68.828 71.3491 68.828 71.7641 68.828 72.5171C68.828 73.2691 68.824 73.9561 68.816 74.5771C68.816 75.3841 68.825 76.3601 68.843 77.5051C68.861 78.6491 68.869 79.2491 68.868 79.3041H67.217C67.217 79.2561 67.217 78.8281 67.238 78.0231C67.259 77.2191 67.259 76.0211 67.259 74.4311C67.259 73.6971 67.259 72.8401 67.259 71.8581C67.259 70.8761 67.246 69.8301 67.221 68.7191H68.853L68.841 69.923L68.853 69.9441C69.624 69.4801 70.436 69.0901 71.28 68.7761C71.8 68.5831 72.349 68.4721 72.904 68.4481C73.301 68.4311 73.697 68.4971 74.067 68.6411C74.437 68.7861 74.773 69.0071 75.052 69.2891C75.591 69.8521 75.861 70.6201 75.861 71.5931C75.861 72.4701 75.861 73.2291 75.845 73.8661C75.83 74.5041 75.83 75.0181 75.83 75.4201C75.83 76.0601 75.839 76.8531 75.857 77.8001C75.872 78.7601 75.882 79.2561 75.882 79.3041Z"
				fill="#E59B34"
			/>
			<path
				d="M87.3781 73.342C87.3781 75.071 87.3781 76.479 87.3781 77.566C87.3781 78.653 87.3751 79.239 87.3671 79.323C87.2981 79.323 86.9731 79.356 86.3951 79.402C85.8171 79.448 85.0721 79.469 84.1591 79.469C83.5791 79.468 83.0011 79.416 82.4311 79.313C81.9161 79.227 81.4141 79.076 80.9371 78.864C80.4031 78.627 79.9361 78.261 79.5771 77.8C79.2261 77.338 79.0431 76.77 79.0591 76.19C79.0461 75.558 79.2641 74.944 79.6721 74.462C80.1351 73.939 80.7171 73.535 81.3691 73.285C81.9561 73.058 82.5641 72.888 83.1831 72.778C84.0381 72.615 84.9021 72.501 85.7691 72.438C85.7511 71.962 85.6101 71.5 85.3621 71.094C85.1151 70.731 84.7611 70.453 84.3491 70.299C84.0191 70.168 83.6751 70.078 83.3231 70.032C82.8871 69.976 82.4471 69.95 82.0061 69.953C81.6931 69.953 81.4301 69.953 81.2081 69.974L80.3151 70.026L80.4821 68.636L81.1041 68.6C81.4121 68.583 81.8351 68.574 82.3731 68.573C83.1141 68.564 83.8541 68.65 84.5731 68.828C85.1671 68.974 85.7161 69.26 86.1761 69.662C86.5791 70.031 86.8871 70.492 87.0751 71.006C87.2791 71.558 87.3781 72.332 87.3781 73.342ZM85.7791 75.863V74.965C85.7791 74.602 85.7791 74.183 85.7691 73.709C85.4621 73.722 84.9261 73.783 84.1601 73.891C83.5001 73.973 82.8521 74.13 82.2271 74.358C81.8381 74.502 81.4831 74.727 81.1861 75.016C81.0391 75.156 80.9221 75.324 80.8431 75.511C80.7651 75.699 80.7271 75.9 80.7311 76.103C80.7091 76.522 80.8461 76.934 81.1151 77.255C81.4041 77.565 81.7681 77.794 82.1711 77.919C82.5391 78.044 82.9191 78.13 83.3041 78.175C83.7391 78.229 84.1771 78.255 84.6161 78.254C84.7561 78.254 84.9271 78.254 85.1281 78.244L85.7661 78.223C85.7661 78.173 85.7661 77.944 85.7661 77.532C85.7661 77.119 85.7791 76.56 85.7791 75.863Z"
				fill="#E59B34"
			/>
			<path
				d="M25.8692 62.999H23.2734C23.2723 63.241 23.3275 63.48 23.4347 63.698C23.5446 63.915 23.7128 64.098 23.9204 64.226C24.067 64.315 24.2272 64.38 24.3947 64.418C24.6044 64.465 24.819 64.487 25.034 64.483C25.1703 64.483 25.2951 64.483 25.4084 64.483L25.6177 64.468L25.5543 64.946L25.3623 64.959C25.2529 64.959 25.1262 64.959 24.9783 64.959C24.639 64.965 24.301 64.915 23.978 64.811C23.7187 64.725 23.481 64.585 23.2811 64.398C23.1142 64.238 22.9794 64.048 22.8836 63.838C22.7724 63.601 22.7166 63.341 22.7204 63.079C22.7169 62.828 22.7579 62.578 22.8414 62.34C22.9257 62.115 23.0499 61.907 23.2081 61.726C23.3513 61.559 23.5314 61.427 23.7342 61.342C23.9406 61.254 24.1627 61.209 24.387 61.209C24.5886 61.205 24.7892 61.239 24.9783 61.309C25.1495 61.375 25.3032 61.48 25.4276 61.614C25.5763 61.781 25.687 61.977 25.7521 62.19C25.8323 62.452 25.8718 62.725 25.8692 62.999ZM25.3124 62.598C25.2912 62.337 25.1845 62.092 25.0091 61.899C24.9248 61.814 24.8237 61.747 24.7123 61.704C24.6009 61.66 24.4816 61.64 24.362 61.645C24.2387 61.641 24.1158 61.662 24.0004 61.706C23.8851 61.749 23.7796 61.816 23.69 61.901C23.4965 62.09 23.3629 62.333 23.306 62.598H25.3124ZM25.6119 60.052L24.0318 60.887L23.8167 60.551L25.3527 59.646L25.6119 60.052Z"
				fill="#283262"
			/>
			<path
				d="M32.0286 65.0051H31.7118C31.3563 65.0111 31.0008 64.9911 30.6481 64.9451C30.3878 64.9041 30.1349 64.8251 29.8974 64.7111C29.6037 64.5791 29.3524 64.3671 29.1716 64.1011C29.0077 63.8481 28.9216 63.5531 28.924 63.2521C28.9196 62.9121 29.0158 62.5781 29.2005 62.2921C29.3979 62.0001 29.6752 61.7721 29.9992 61.6331C30.2217 61.5311 30.4567 61.4581 30.698 61.4161C30.9512 61.3681 31.2083 61.3441 31.466 61.3431L31.4565 59.6851H32.0228C32.0228 59.7331 32.0228 60.0691 32.0228 60.6911C32.0228 61.3131 32.0228 61.9441 32.0228 62.5841C32.0228 63.0881 32.0228 63.5451 32.0228 63.9551C32.0248 64.3601 32.0267 64.7111 32.0286 65.0051ZM31.4718 62.4801V61.8161C31.2451 61.8151 31.0188 61.8361 30.796 61.8771C30.6083 61.9101 30.4252 61.9661 30.2507 62.0421C30.0195 62.1421 29.8239 62.3091 29.6895 62.5221C29.5552 62.7351 29.4884 62.9831 29.498 63.2351C29.4926 63.4621 29.5599 63.6841 29.69 63.8701C29.8136 64.0441 29.976 64.1871 30.1643 64.2871C30.341 64.3761 30.5289 64.4401 30.723 64.4791C30.9371 64.5231 31.1552 64.5451 31.3739 64.5441H31.468C31.468 64.5231 31.468 64.3521 31.468 64.0081C31.468 63.6651 31.4718 63.1671 31.4718 62.4801Z"
				fill="#283262"
			/>
			<path
				d="M36.4334 60.616H35.7998V59.998H36.4334V60.616ZM36.4008 64.952H35.8248C35.8248 64.913 35.8248 64.677 35.8344 64.243C35.844 63.809 35.8344 63.452 35.8344 63.168C35.8344 62.922 35.8344 62.651 35.8344 62.354C35.8344 62.056 35.8344 61.713 35.8209 61.313H36.3969C36.3969 61.659 36.3873 61.981 36.3835 62.273C36.3796 62.565 36.3835 62.849 36.3835 63.076C36.3835 63.635 36.3835 64.084 36.3835 64.42C36.3835 64.756 36.4008 64.936 36.4008 64.952Z"
				fill="#283262"
			/>
			<path
				d="M42.1758 64.506L42.1105 64.963H41.9877C41.9205 64.963 41.8648 64.963 41.8168 64.963C41.4213 64.992 41.0282 64.883 40.7032 64.656C40.5712 64.539 40.468 64.393 40.4014 64.23C40.3348 64.066 40.3067 63.89 40.3192 63.713C40.3192 63.447 40.3192 63.162 40.3192 62.857C40.3192 62.552 40.3192 62.176 40.3192 61.724H39.7163V61.304H40.3518V60.463H40.8721V61.313H42.1585V61.734H40.8721C40.8721 61.785 40.8721 61.935 40.8721 62.183C40.8721 62.433 40.8721 62.617 40.8721 62.738V63.629C40.8607 63.753 40.8773 63.878 40.9209 63.995C40.9644 64.111 41.0337 64.217 41.1237 64.303C41.3489 64.457 41.6196 64.53 41.8917 64.51H42.1624L42.1758 64.506Z"
				fill="#283262"
			/>
			<path
				d="M46.1736 60.616H45.5361V59.998H46.1697L46.1736 60.616ZM46.1409 64.952H45.5649C45.5649 64.913 45.5649 64.677 45.5745 64.243C45.5841 63.809 45.5745 63.452 45.5745 63.168C45.5745 62.922 45.5745 62.651 45.5745 62.354C45.5745 62.056 45.5745 61.713 45.5611 61.313H46.1371C46.1371 61.659 46.1371 61.981 46.1237 62.273C46.1102 62.565 46.1237 62.849 46.1237 63.076C46.1237 63.635 46.1237 64.084 46.1332 64.42C46.1428 64.756 46.1409 64.936 46.1409 64.952Z"
				fill="#283262"
			/>
			<path
				d="M53.1339 63.133C53.1349 63.406 53.0839 63.676 52.9839 63.93C52.8909 64.176 52.7449 64.398 52.5559 64.581C52.4039 64.728 52.2249 64.845 52.0299 64.927C51.8209 65.009 51.5979 65.049 51.3729 65.046C51.1509 65.05 50.9289 65.013 50.7199 64.936C50.5189 64.859 50.3359 64.742 50.1809 64.592C49.9939 64.41 49.8499 64.189 49.7579 63.945C49.6579 63.686 49.6069 63.411 49.6089 63.133C49.6049 62.872 49.6489 62.612 49.7369 62.365C49.8239 62.131 49.9589 61.918 50.1349 61.741C50.2919 61.576 50.4829 61.445 50.6929 61.357C50.9069 61.268 51.1369 61.222 51.3689 61.223C51.6009 61.219 51.8309 61.261 52.0469 61.346C52.2509 61.43 52.4349 61.557 52.5859 61.718C52.7639 61.901 52.9019 62.118 52.9939 62.356C53.0869 62.604 53.1339 62.868 53.1339 63.133ZM52.5579 63.158C52.5579 62.934 52.5209 62.712 52.4479 62.5C52.3789 62.295 52.2629 62.111 52.1079 61.96C52.0129 61.869 51.8999 61.797 51.7759 61.751C51.6479 61.701 51.5119 61.676 51.3749 61.676C51.2279 61.672 51.0809 61.7 50.9449 61.757C50.8139 61.814 50.6959 61.899 50.5989 62.004C50.4649 62.146 50.3619 62.315 50.2999 62.5C50.2269 62.7 50.1899 62.912 50.1899 63.126C50.1879 63.355 50.2249 63.583 50.3019 63.8C50.3749 64.004 50.4979 64.186 50.6609 64.329C50.7549 64.414 50.8639 64.479 50.9829 64.521C51.2459 64.615 51.5339 64.611 51.7939 64.51C51.9229 64.457 52.0399 64.379 52.1369 64.28C52.2749 64.138 52.3809 63.969 52.4479 63.784C52.5229 63.584 52.5609 63.372 52.5599 63.158H52.5579Z"
				fill="#283262"
			/>
			<path
				d="M59.5519 64.9511H58.9759C58.9759 64.8841 58.9759 64.6631 58.9859 64.2871C58.9949 63.9111 58.9859 63.6781 58.9859 63.5881C58.9859 63.4651 58.9859 63.2811 58.9859 63.0371C58.9859 62.7931 58.9859 62.6161 58.9859 62.5031C58.9959 62.2941 58.9409 62.0861 58.8259 61.9101C58.7599 61.8441 58.6799 61.7941 58.5919 61.7631C58.5039 61.7321 58.4099 61.7211 58.3169 61.7311C58.1299 61.7401 57.9459 61.7841 57.7739 61.8601C57.5479 61.9511 57.3289 62.0621 57.1209 62.1901C57.1209 62.2171 57.1209 62.3591 57.1209 62.6181C57.1209 62.8781 57.1209 63.1141 57.1209 63.3271C57.1209 63.6031 57.1209 63.9391 57.1209 64.3331C57.1209 64.7271 57.1209 64.9321 57.1209 64.9511H56.5449C56.5449 64.9341 56.5449 64.7881 56.5449 64.5111C56.5449 64.2351 56.5449 63.8221 56.5449 63.2751C56.5449 63.0231 56.5449 62.7301 56.5449 62.3921C56.5449 62.0541 56.5449 61.6951 56.5339 61.3131H57.0939V61.7271C57.3609 61.5691 57.6409 61.4351 57.9309 61.3281C58.1099 61.2611 58.2989 61.2231 58.4899 61.2151C58.6269 61.2091 58.7629 61.2311 58.8899 61.2811C59.0169 61.3301 59.1329 61.4061 59.2289 61.5031C59.4229 61.7201 59.5229 62.0051 59.5079 62.2961C59.5079 62.5971 59.5079 62.8581 59.5079 63.0771C59.5079 63.2961 59.5079 63.4751 59.5079 63.6111C59.5079 63.8301 59.5079 64.1031 59.5169 64.4291C59.5269 64.7551 59.5519 64.9361 59.5519 64.9511Z"
				fill="#283262"
			/>
			<path
				d="M65.2759 63.897C65.2789 64.065 65.2339 64.23 65.1469 64.373C65.0619 64.511 64.9459 64.626 64.8089 64.711C64.6479 64.807 64.4719 64.877 64.2889 64.917C64.0709 64.966 63.8479 64.989 63.6239 64.988C63.5069 64.988 63.3859 64.988 63.2599 64.971L63.0459 64.951L63.0229 64.481L63.2729 64.5C63.4189 64.512 63.5629 64.516 63.7069 64.516C63.8149 64.515 63.9219 64.508 64.0299 64.494C64.1419 64.486 64.2519 64.459 64.3559 64.416C64.4599 64.376 64.5519 64.31 64.6229 64.224C64.6809 64.144 64.7119 64.046 64.7089 63.947C64.7129 63.861 64.686 63.775 64.632 63.707C64.573 63.638 64.5029 63.578 64.4249 63.531C64.3029 63.45 64.1749 63.381 64.0409 63.323C63.8969 63.261 63.7579 63.191 63.6219 63.112C63.4749 63.026 63.3489 62.907 63.2539 62.765C63.1679 62.624 63.1239 62.461 63.1269 62.296C63.1249 62.125 63.1759 61.958 63.2729 61.818C63.3719 61.676 63.5039 61.559 63.6569 61.478C63.8029 61.399 63.9629 61.347 64.1269 61.325C64.2919 61.3 64.4579 61.287 64.6249 61.286C64.7709 61.286 64.8919 61.286 64.9859 61.286H65.1469L65.1869 61.764L64.9649 61.749C64.8339 61.739 64.6899 61.735 64.5339 61.735C64.4289 61.735 64.3229 61.745 64.2199 61.764C64.1309 61.785 64.0459 61.818 63.9679 61.864C63.8769 61.905 63.8009 61.972 63.7469 62.056C63.7059 62.121 63.6819 62.196 63.6799 62.273C63.6789 62.365 63.7119 62.453 63.7739 62.521C63.8369 62.591 63.9109 62.65 63.9929 62.695C64.0909 62.753 64.225 62.822 64.396 62.901C64.523 62.958 64.6469 63.022 64.7669 63.093C64.9179 63.182 65.0489 63.303 65.1509 63.446C65.2359 63.581 65.2799 63.738 65.2759 63.897Z"
				fill="#283262"
			/>
			<path
				d="M41.4082 15.0158C40.9897 14.6587 40.5564 14.3201 40.1084 14.0001C39.4632 13.5378 38.7892 13.1171 38.0905 12.7406C37.0957 12.2009 36.0532 11.7542 34.9762 11.4062C33.5127 10.9337 31.9967 10.6429 30.4623 10.5403L30.2953 10.5307C29.9266 10.5076 29.5561 10.4961 29.1817 10.4961C28.3811 10.4958 27.5814 10.5471 26.7874 10.6497C26.557 10.6785 26.3285 10.7111 26.0981 10.7495C25.5099 10.8461 24.9273 10.9743 24.3529 11.1335V34.1467C25.5292 33.8356 26.7329 33.6395 27.9471 33.5611C28.3541 33.5342 28.765 33.5207 29.1797 33.5207C30.8124 33.5202 32.4382 33.7319 34.0162 34.1505C34.3369 34.235 34.6517 34.3271 34.9647 34.4308C36.0435 34.7749 37.0885 35.217 38.0866 35.7518V45.9278C33.8743 48.1841 28.9635 48.7604 24.3433 47.5406V52.091C28.9639 53.308 33.874 52.7313 38.0866 50.4763C38.7651 50.1123 39.4199 49.7057 40.0469 49.259C40.1218 49.2091 40.1986 49.1553 40.2735 49.0958C41.2256 48.403 42.1102 47.622 42.9154 46.763V16.45C42.4405 15.9439 41.9373 15.465 41.4082 15.0158ZM34.9762 29.8862C33.1043 29.2811 31.1489 28.9739 29.1817 28.9761V15.0465C30.129 15.0457 31.075 15.1163 32.0117 15.2577C33.0173 15.4093 34.0088 15.643 34.9762 15.9566C35.9421 16.2685 36.8804 16.6602 37.7813 17.1278C37.8831 17.1815 37.9849 17.2334 38.0866 17.2891V31.2167C37.092 30.6805 36.051 30.2352 34.9762 29.8862Z"
				fill="#283262"
			/>
			<path
				d="M53.034 34.4289C53.346 34.3265 53.662 34.2337 53.98 34.1505C55.561 33.7312 57.189 33.5195 58.824 33.5207C59.241 33.5207 59.65 33.5342 60.059 33.563C61.273 33.6375 62.477 33.8304 63.653 34.139V11.122C63.077 10.9723 62.501 10.8455 61.906 10.7495C60.887 10.5804 59.856 10.4956 58.823 10.4961C58.439 10.4961 58.076 10.5076 57.707 10.5287L57.542 10.5403C56.008 10.643 54.493 10.9332 53.03 11.4043C51.951 11.7517 50.907 12.1978 49.91 12.7367C49.21 13.1121 48.535 13.5328 47.89 13.9963C47.4408 14.315 47.0068 14.6535 46.5883 15.0119C46.0602 15.4629 45.5576 15.943 45.083 16.45V46.7591C45.8876 47.6181 46.7715 48.3992 47.723 49.0919C47.7979 49.1515 47.8747 49.2052 47.9496 49.2571C48.576 49.7036 49.231 50.1096 49.91 50.4724C54.122 52.7303 59.033 53.307 63.653 52.0871V47.5367C59.035 48.7567 54.125 48.1818 49.914 45.9278V35.7518C50.911 35.2169 51.956 34.7741 53.034 34.4289ZM49.914 17.2852L50.219 17.1239C51.124 16.6582 52.065 16.2671 53.034 15.9547C54 15.6419 54.99 15.4095 55.994 15.2596C56.932 15.1176 57.878 15.0463 58.826 15.0465V28.9742C56.86 28.9723 54.905 29.2788 53.034 29.8823C51.959 30.2292 50.919 30.6733 49.925 31.2091L49.914 17.2852Z"
				fill="#283262"
			/>
			<path
				d="M42.8612 14.4805C42.6865 14.3211 42.5079 14.1656 42.3275 14.012C38.376 10.649 33.3543 8.80614 28.1655 8.81464C27.507 8.81464 26.858 8.84344 26.2129 8.90104V5.08594C33.1652 5.14744 39.2785 8.87224 42.8612 14.4805Z"
				fill="#283262"
			/>
			<path
				d="M45.1387 14.4805C45.3134 14.3211 45.492 14.1656 45.6724 14.012C49.624 10.649 54.646 8.80614 59.834 8.81464C60.493 8.81464 61.142 8.84344 61.787 8.90104V5.08594C54.835 5.14744 48.721 8.87224 45.1387 14.4805Z"
				fill="#283262"
			/>
			<path
				d="M42.8478 11.7078C41.0474 9.37203 38.7684 7.44803 36.1637 6.06483C33.5589 4.68163 30.6886 3.87133 27.7451 3.68803V0.207031C30.9958 0.784531 34.0618 2.12923 36.6885 4.12943C39.3152 6.12973 41.4268 8.72773 42.8478 11.7078Z"
				fill="#283262"
			/>
			<path
				d="M45.1387 11.7078C46.9389 9.37223 49.218 7.44843 51.822 6.06523C54.426 4.68203 57.296 3.87153 60.239 3.68803V0.207031C56.989 0.785131 53.924 2.13003 51.297 4.13023C48.671 6.13043 46.5598 8.72813 45.1387 11.7078Z"
				fill="#283262"
			/>
		</svg>
	);
};

export default Logo;
