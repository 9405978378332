import React from "react";
import { animatedComponents, FormError, InputUser, RequiredText } from "../../../common/Input";
import ReactTags from "react-tag-autocomplete";
import useCrudPublication from "./hooks/useCrudPublication";
import { BtnSubmit } from "../../../common/Button";
import Select from "react-select";

function AjouterPublication() {
	const {
		register,
		errors,
		onSubmit,
		isLoading,
		categories,
		suggestions,
		reactCatsRef,
		onAddition,
		onDelete,
		handleSelectAuthor,
		options,
		option,
		control,
		Controller,
		loading
	} = useCrudPublication();
	return (
		<div className="dashbord-visiteur-component">
			<div className="user-dash-page-content-container align-items-center mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="tabs-search-input-container">
							<div className="yeewuna-parcours-utilisateur-titre-communaute">
								Communauté
							</div>
						</div>
					</div>
				</div>
				<div className="ajouter-publication-form-container">
					<form className="mb-3" onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label
										htmlFor="categories"
										className="form-label form-label-user-costom"
									>
										Catégories
										<RequiredText />
									</label>
									{/* <ReactTags
										ref={reactCatsRef}
										tags={categories}
										suggestions={suggestions}
										onDelete={onDelete}
										onAddition={onAddition}
										placeholderText="Choisir une catégorie"
										noSuggestionsText="Aucune catégorie correspondante"
										// allowNew={true}
									/> */}
									<Controller
										name="categories"
										control={control}
										render={({ field }) => {
											return (
												<Select
													{...field}
													closeMenuOnSelect={false}
													components={animatedComponents}
													placeholder="Selectionner les catégories"
													isLoading={loading}
													options={options}
													isMulti
													classNamePrefix="react-select-custom-lecteur"
													// onInputChange={handleSearch}
													onChange={handleSelectAuthor}
													value={option}
												/>
											);
										}}
									/>
									<FormError error={errors?.categories?.message} />
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<InputUser
										id="titre "
										label="Titre "
										placeholder="Titre "
										type={"text"}
										required
										{...register("subject")}
										error={errors?.subject}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label htmlFor="nom" className="form-label form-label-admin">
										Texte
										<RequiredText />
									</label>
									<textarea
										className="form-control form-control-admin"
										rows={5}
										placeholder="Texte"
										style={{ background: "none" }}
										{...register("text")}
									/>
									<FormError error={errors?.text} />
									{/* <div className="text-area-nurber-of-caracter mt-1">
										350 caractères
									</div> */}
									{/* <div className="ajouter-publication-btn-ajouter-img-container mt-4">
										<button className="btn yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication no-link">
											<FiImage /> Ajouter une image
										</button>
									</div> */}
									<div className="ajouter-publication-btn-container my-5">
										<div className="ajouter-publication-btn-publier-container ">
											<BtnSubmit
												label="Publier"
												isLoading={isLoading}
												className="btn yeewuna-parcours-utilisateur-communaute-btn-nouvelle-publication no-link"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default AjouterPublication;
