import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { IoLibraryOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import VersionChecker from "../../../../../versionChecker/VersionChecker";
import logoHeader from "../../../../../assets/appImages/logoHeader.png";
import { FiMail, FiUser, FiUsers } from "react-icons/fi";
import { MdAddShoppingCart } from "react-icons/md";
import { GrHomeRounded } from "react-icons/gr";

const SidebarUserMobile = () => {
	return (
		<div>
			<div className="container-nav">
				<input type="checkbox" id="check" />
				<label htmlFor="check">
					<AiOutlineMenuUnfold />
					<i className="fas fa-times" id="cancel"></i>
				</label>
				<div className="sidebar" id="scrollbar-nav-mobile">
					<div className="content-nav-mobile">
						<div className="container-user-connect-info pb-4 d-flex justify-content-center">
							<div className="container-info-user-connect-navmenu">
								<img
									src={logoHeader}
									className="yeewuna-logo-header"
									alt="logo de yeewuna"
								/>
							</div>
						</div>
						<ul className="nav nav-items-custom flex-column">
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/accueil"
								>
									<GrHomeRounded />
									<span className="item-nav-menu drop-header">Accueil</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/auteurs"
								>
									<FiUser />
									<span className="item-nav-menu drop-header">Auteurs</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/Bibliotheque"
								>
									<IoLibraryOutline />
									<span className="item-nav-menu drop-header">Bibliothèque</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/communaute"
								>
									<FiUsers />
									<span className="item-nav-menu drop-header">Communauté</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/panier"
								>
									<MdAddShoppingCart />
									<span className="item-nav-menu drop-header">Panier</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/messages"
								>
									<FiMail />
									<span className="item-nav-menu drop-header">Messages</span>
								</NavLink>
							</li>
							<li className="nav-item nav-item-vertical-custom">
								<NavLink
									className="nav-link nav-link-vertival-custom"
									to="/utilisateur/mon-profil"
								>
									<BiUserCircle />
									<span className="item-nav-menu drop-header">Mon profil</span>
								</NavLink>
							</li>
						</ul>
						<div className="container-btn-logout">
							<div className="version-view-container ps-3">
								{"v" + VersionChecker()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SidebarUserMobile;
