import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IBecomeTraderProps } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";

function CheckPaiementStatus(props: IBecomeTraderProps) {
	const { onGetTransactionStatus, isLoad: isLoading, seconds } = props;

	return (
		<div className="yeewuna-devenir-autheur-col-right-form-content">
			<div className="yeewuna-devenir-autheur-col-right-form-titre text-center mb-4">
				Vérifier le statut de votre paiement
			</div>
			<div className="modal-payment-body">
				<div className="my-5 flex-col itm-center">
					<BsFillInfoCircleFill
						className=" mb-4"
						style={{ fontSize: 40, color: Color.bleu }}
					/>
					<p className="title-payment-modal mb-4 text-center">
						Rassurer vous d'avoir confirmé la transaction depuis votre téléphone avant
						de vérifier le paiement
					</p>
				</div>
			</div>
			<div>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						className="btn auth-submit-btn mb-1"
						onClick={() => onGetTransactionStatus()}
						disabled={isLoading || (seconds > 0 && seconds < 20)}
					>
						{isLoading ? (
							<>
								<span
									className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
									role="status"
								></span>
								<span>Vérification...</span>
							</>
						) : (
							"Vérifier le paiement"
						)}
					</button>
				</div>
				{seconds <= 0 || seconds === 20 ? null : (
					<div className="col-12  ">
						<small className="text-danger mt-1 py-2">
							Veuillez revérifier dans {seconds} secondes
						</small>
					</div>
				)}
			</div>
		</div>
	);
}

export default CheckPaiementStatus;
