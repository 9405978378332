import React from "react";
import { useGetOfferForVisitorsQuery } from "../../../utils/api/offer/offer.api";
import { IOffer } from "../../../utils/api/offer/offer.type";
import { AlertInfo } from "../../common/Alert";
import OfferItem, { OfferItemSkeleton } from "./OfferItem";

const ListOffer = ({ handleChoiceOffer }: { handleChoiceOffer?: (item?: IOffer) => void }) => {
	const { data, isLoading } = useGetOfferForVisitorsQuery();
	return (
		<div className="row row-abonnement">
			{!isLoading ? (
				<>
					{data && data?.length > 0 ? (
						data?.map((item) => (
							<OfferItem
								item={item}
								key={item?.slug}
								handleChoiceOffer={handleChoiceOffer}
							/>
						))
					) : (
						<AlertInfo message="Aucune offre trouvée" />
					)}
				</>
			) : (
				<>
					{[...Array(4)]?.map((item, i) => (
						<OfferItemSkeleton key={i} />
					))}
				</>
			)}
		</div>
	);
};

export default ListOffer;
