import React from "react";
import "./Communaute.css";

import { IForum } from "../../../../utils/api/forum/forum.type";
import ForumSubjectItem from "./ForumSubjectItem";
import { useLocationState } from "../../../../utils/Utils";
import { CommentaireItem, FormCommentaire } from "./Commentaire";
import { useGetForumCommentQuery } from "../../../../utils/api/forum/forum.api";

function DetailsDiscussion() {
	const item = useLocationState<IForum>(undefined);
	const { data, isLoading } = useGetForumCommentQuery({ slug: item?.slug });
	return (
		<div className="dashbord-visiteur-component">
			<div className="user-dash-page-content-container align-items-center mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="tabs-search-input-container">
							<div className="yeewuna-parcours-utilisateur-titre-communaute">
								Communauté
							</div>
						</div>
					</div>
				</div>
				<ForumSubjectItem item={item} />
				<FormCommentaire item={item} />
				{!isLoading &&
					data &&
					data?.results?.length > 0 &&
					data?.results?.map((item) => <CommentaireItem key={item.slug} item={item} />)}
			</div>
		</div>
	);
}

export default DetailsDiscussion;
