import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import "./GestionUsers.css";
import ListUsersTable from "./ListUsersTable/ListUsersTable";
import { hasAccess } from "../../../../routes/routerUtils";
import { useAppSelector } from "../../../../redux/hooks";

const GestionUsers = () => {
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "utilisateur")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Utilisateurs</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<NavLink to="/admin/ajouter-utilisateur" className="btn btn-page-title-admin">
						<MdOutlineAdd />
						<span className="ps-2">Ajouter un utilisateur</span>
					</NavLink>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<ListUsersTable />
			</div>
		</div>
	);
};

export default GestionUsers;
