import * as React from "react";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";

const Help = () => {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-banner-policy">
          <h2 className="title-banner-policy">Aide</h2>
        </div>
        <div className="container">
          <div className="container-text-policy">
            <p className="text-policy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugia bsnskmf Egestas nibh ac sit pretium. Sed diam sit
              dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod nporttit quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget Sem
              bibendum quis faucibus enim a. Habitasse at egestas dolor mauris
              fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p>
            <p className="text-policy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugia bsnskmf Egestas nibh ac sit pretium. Sed diam sit
              dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod nporttit quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget Sem
              bibendum quis faucibus enim a. Habitasse at egestas dolor mauris
              fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p>
            <p className="text-policy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugia bsnskmf Egestas nibh ac sit pretium. Sed diam sit
              dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod nporttit quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget Sem
              bibendum quis faucibus enim a. Habitasse at egestas dolor mauris
              fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p>
            <p className="text-policy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugia bsnskmf Egestas nibh ac sit pretium. Sed diam sit
              dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod nporttit quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget Sem
              bibendum quis faucibus enim a. Habitasse at egestas dolor mauris
              fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p>
            <p className="text-policy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugia bsnskmf Egestas nibh ac sit pretium. Sed diam sit
              dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod nporttit quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget Sem
              bibendum quis faucibus enim a. Habitasse at egestas dolor mauris
              fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Help;
