import React from "react";
import fmoney from "../../../../assets/appImages/fmoney.png";
import omoney from "../../../../assets/appImages/omoney.png";
import wmoney from "../../../../assets/appImages/wmoney.png";
import { IBecomeTraderProps } from "../../../../utils/api/user/user.type";
import { ServicePaymentCode } from "../../../../utils/type";

export const modePaiements = [
	{
		id: "CARTE",
		icon: wmoney,
		value: ServicePaymentCode.WAVE,
	},
	{
		id: "WAVE",
		icon: omoney,
		value: ServicePaymentCode.OM,
	},
	{
		id: "FREE",
		icon: fmoney,
		value: ServicePaymentCode.FREE,
	},
];

type Props = Pick<IBecomeTraderProps, "register"> & {
	checked: string;
	onChecked: (item: string) => void;
};

function ModePaiementModal(props: Props) {
	const { register, onChecked, checked } = props;
	return (
		<div className="modal-dialog  modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-body">
					<div className="content-title-section-avantage text-center">
						<h2 className="h2-theme position-relative">Nos mode de paiement</h2>
						<p className="text-avantage-list-homepage pt-4">
							Choisissez le mode de paiement qui vous convient
						</p>
					</div>
					<div className="carte-mode-paiement-container">
						{modePaiements.map(({ id, icon, value }) => (
							<div className="custom-carte-paiement-check-item mb-2 pt-1" key={id}>
								<label className="form-check-label" htmlFor={id}>
									<img
										src={icon}
										className="card-img-financer-campagne"
										style={{ marginTop: 0 }}
										alt="img campagne w-100"
									/>
								</label>
								<input
									className="form-check-input m-0 mt-2"
									type="radio"
									id={id}
									value={value}
									{...register("payment_method")}
									data-testid="freemoney"
									onChange={() => onChecked(icon)}
								/>
							</div>
						))}
					</div>
					<div className="flex-c mt-4">
						{checked && (
							<button
								className="btn btn-page-title-admin"
								type="button"
								data-bs-dismiss="modal"
							>
								Valider
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ModePaiementModal;
