import React, { useEffect } from "react";
import Logo from "../../assets/appImages/logo.png";
import ImgLogin from "../../assets/appImages/login-img.png";
import { ForgetPasswordFormData } from "../../utils/api/auth/auth.type";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmMailMessage = () => {
  const state = useLocation().state as ForgetPasswordFormData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.email) {
      setTimeout(() => navigate("/mot-de-passe-oublie"), 1);
    }
  }, [state?.email]);

  return (
    <div>
      <div className="container-page-login-yeewuna">
        <div className="auth-row row">
          <div className="col-md-6 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container w-100">
              <img src={ImgLogin} alt="connexion" className="img-login" />
            </div>
          </div>
          <div className="col-md-6 auth-col auth-right-side-col d-flex">
            <div className="auth-right-side-container w-100">
              <div className="auth-form-container">
                <div className="container-img-logo pb-4 text-center">
                  <img src={Logo} alt="Logo" className="img-logo-login" />
                </div>
                <p className="text-message-confirm-mail">
                  Mail contenant un code de 6 chiffres qui permettra de vous
                  reconnecter a été envoyé à{" "}
                  <span className="auth-form-message" style={{ fontSize: 28 }}>
                    {state?.email && state?.email}
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMailMessage;
