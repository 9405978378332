import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useGetArticlesQuery } from "../../../../utils/api/article/article.api";
import { usePagination } from "../../../../utils/hooks";
import { isShowPagination } from "../../../../utils/Utils";
import { ArticleItem } from "../../Auteur/GestionActualites/GestionArticles";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { AlertInfo } from "../../../common/Alert";
import { hasAccess } from "../../../../routes/routerUtils";
import { useAppSelector } from "../../../../redux/hooks";

const GestionArticlesAdmin = () => {
	const { limit, page, setPage, setPerPage } = usePagination(12);
	const { data, isLoading } = useGetArticlesQuery({ limit, page });

	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "blog")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin d-fle">
					<h3 className="h3-title-admin">Liste des articles</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<Link to="ajouter" className="btn btn-page-title-admin">
						<MdOutlineAdd />
						<span className="ps-2">Nouvel article</span>
					</Link>
				</div>
			</div>
			<div className="mt-4">
				<div className="row">
					{!isLoading ? (
						data && data?.results?.length > 0 ? (
							<>
								{data?.results?.map((item) => (
									<div className="col-md-6 col-lg-4 mb-3" key={item?.slug}>
										<ArticleItem item={item} />
									</div>
								))}
								{isShowPagination(data?.count || 0, limit) && (
									<CustomPagination
										nbPages={data?.count}
										page={page}
										onChange={(page, perPage) => {
											setPerPage(perPage);
											setPage(page);
										}}
										perPage={limit}
									/>
								)}
							</>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default GestionArticlesAdmin;
