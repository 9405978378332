import React from "react";
import * as yup from "yup";
import ArticleForm from "./ArticleForm";

const EditArticle = () => {
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Titre"),
		content: yup.string().required().label("Description"),
		image: yup.mixed().label("Image"),
	});
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin d-fle">
					<h3 className="h3-title-admin">Modifier l'article</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<ArticleForm schema={validationSchema} />
			</div>
		</div>
	);
};

export default EditArticle;
