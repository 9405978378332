import { InputAdmin } from "../../../common/Input";
import { Category } from "../../../../utils/api/category/category.type";
import { Modal } from "reactstrap";
import { BtnCloseModal, BtnSubmit } from "../../../common/Button";
import useCategoryForm from "./hooks/useCategoryForm";
import { IModalProps } from "../../../../utils/type";

const AddCategoryModal = ({ item, isOpen, closeModal }: IModalProps<Category>) => {
	const { register, onSubmit, errors, isLoading } = useCategoryForm(closeModal, item);
	return (
		<Modal isOpen={isOpen} centered className="custom-modal">
			<div className="modal-header py-0">
				<h5 className="modal-title-admin">{item ? "Modifier" : "Ajouter"} une catégorie</h5>
				<BtnCloseModal onClick={closeModal} />
			</div>
			<div className="modal-body">
				<form onSubmit={onSubmit}>
					<div className="col-12">
						<InputAdmin
							label="Nom"
							id="nom"
							type="text"
							placeholder="Nom catégorie"
							{...register("name")}
							error={errors?.name}
						/>
					</div>
					<div className="flex-r">
						<BtnSubmit label="Enregistrer" isLoading={isLoading} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AddCategoryModal;
