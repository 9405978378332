import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { FaqFormData, IFaq } from "./faq.type";

export const FaqApi = createApi({
	reducerPath: "faqAPi",
	tagTypes: ["faq", "faq_by_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getFaq: builder.query<PaginationResults<IFaq>, TypeQuery>({
			query: () => `faq/`,
			providesTags: ["faq"],
		}),
		getFaqForVisitors: builder.query<IFaq[], { sujet?: string }>({
			query: ({ sujet }) => ({
				url: `visitor/faq/`,
				params: { sujet },
			}),
			providesTags: ["faq"],
			transformResponse: ({ results }) => results,
		}),
		getFaqBySlug: builder.query<IFaq, string>({
			query: (slug) => `faq/${slug}/`,
			providesTags: ["faq_by_slug"],
		}),
		addOrUpdateFaq: builder.mutation<IFaq, { slug?: string; data: FaqFormData | FormData }>({
			invalidatesTags: ["faq"],

			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `faq/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `faq/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editFaq: builder.mutation<IFaq, { slug?: string; data: FaqFormData | FormData }>({
			invalidatesTags: ["faq"],
			query: ({ slug, data }) => {
				return {
					url: `faq/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteFaq: builder.mutation<IFaq, string>({
			query: (slug) => ({
				url: `faq/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["faq"],
		}),
	}),
});

export const {
	useGetFaqQuery,
	useGetFaqBySlugQuery,
	useEditFaqMutation,
	useLazyGetFaqQuery,
	useAddOrUpdateFaqMutation,
	useDeleteFaqMutation,
	useGetFaqForVisitorsQuery,
} = FaqApi;
