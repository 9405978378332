import React from 'react'

function BannerHeader({title}: {title: string}) {
  return (
    <section className="section-banner-homepage py-5">
          <div className="banner-header-page-container">
            <div className="row row-banner">
              <div className="col-md-12">
                <div className="banner-header-title">{title}</div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default BannerHeader
