import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { ISocialMedia, SocialMediaFormData } from "./config.type";

export const ConfigApi = createApi({
	tagTypes: ["socialMedia"],
	reducerPath: "configurationApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		updateSocialMedia: builder.mutation<
			ISocialMedia,
			{ slug?: string; data: SocialMediaFormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `reseausocial/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `reseausocial/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["socialMedia"],
		}),
		getSocialMedia: builder.query<ISocialMedia, void>({
			query: () => "reseausocial/",
			transformResponse: ({ results }) => results[0],
			providesTags: ["socialMedia"],
		}),
		getSocialMediaVisitor: builder.query<ISocialMedia, void>({
			query: () => "visitor/reseausocial/",
			providesTags: ["socialMedia"],
		}),
	}),
});

export const {
	useGetSocialMediaQuery,
	useUpdateSocialMediaMutation,
	useGetSocialMediaVisitorQuery,
} = ConfigApi;
