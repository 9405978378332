import React from "react";
import { modePaiements } from "../../../../HomePage/SoumettreOuvrages/Modals/ModePaiementModal";
import { FormError } from "../../../../common/Input";
import { BtnSubmit } from "../../../../common/Button";

function CartePaiement({ onSubmit, register, errors, isLoading, navigation }) {
	return (
		<form onSubmit={onSubmit} className="container-panier-component">
			<div className="row row-panier-user">
				<div className="col-md-12 col-panier-user mb-3">
					<div className="user-dash-page-content-container mb-3">
						<div className="yeewuna-page-bibliotheque-titre mb-4">Payer avec</div>
						<div className="col-md-12">
							<div className="carte-mode-paiement-container justify-content-start mb-4">
								{modePaiements.map(({ id, icon, value }) => (
									<div
										className="custom-carte-paiement-check-item mb-2 pt-1"
										key={id}
									>
										<label className="form-check-label" htmlFor={id}>
											<img
												src={icon}
												className="card-img-financer-campagne"
												style={{ marginTop: 0 }}
												alt="img campagne w-100"
											/>
										</label>
										<input
											className="form-check-input m-0 mt-2"
											type="radio"
											id={id}
											value={value}
											{...register("payment_method")}
											data-testid="payment_method"
										/>
									</div>
								))}
								<FormError error={errors?.payment_method} />
							</div>
							<div className="carte-paiement-btn-submit-container d-flex gap-3 my-3">
								<button
									className="auth-submit-annuler"
									type="button"
									onClick={() => navigation.go("inscription")}
								>
									Retour
								</button>
								<BtnSubmit
									label="Payer"
									isLoading={isLoading}
									className="btn details-discussion-form-commentaire-btn-commenter py-1"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

export default CartePaiement;
