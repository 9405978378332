import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
	ForumFormData,
	ForumCommentFormData,
	IForum,
	ICommentForum,
	LikeForumData,
	ILikeForum,
} from "./forum.type";

export const ForumApi = createApi({
	reducerPath: "forumAPi",
	tagTypes: ["forum", "forumComment", "responsesComment", "likeSubject"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getForumSubjects: builder.query<PaginationResults<IForum>, TypeQuery & { statut?: string }>(
			{
				query: (query) => QueryUrl(`messageforum/`, query),
				providesTags: ["forum"],
			}
		),
		getForumComment: builder.query<PaginationResults<ICommentForum>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`message/${slug}/responses/`, query),
			providesTags: ["forumComment"],
		}),
		getResponsesByComment: builder.query<PaginationResults<ICommentForum>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`response/${slug}/responses/`, query),
			providesTags: ["responsesComment"],
		}),
		createOrEditPublication: builder.mutation<IForum, { slug?: string; data: ForumFormData }>({
			invalidatesTags: ["forum"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `messageforum/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `messageforum/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteForum: builder.mutation<IForum, string>({
			query: (slug) => ({
				url: `messageforum/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forum"],
		}),
		incrementSubjectView: builder.mutation<IForum, string>({
			query: (slug) => ({
				url: `messageforum/${slug}/increment`,
				method: "GET",
			}),
			invalidatesTags: ["forum"],
		}),
		addCommentForum: builder.mutation<ICommentForum, ForumCommentFormData>({
			invalidatesTags: ["forumComment", "responsesComment"],
			query: (data) => {
				return {
					url: `responsemessageforum/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCommentForum: builder.mutation<ICommentForum, string>({
			query: (slug) => ({
				url: `responsemessageforum/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["forumComment", "responsesComment"],
		}),
		getLikesSubject: builder.query<ILikeForum[], { slug?: string }>({
			providesTags: ["forum", "likeSubject"],
			query: ({ slug }) => {
				return `message/${slug}/likemessages`;
			},
		}),
		toggleLikeSubject: builder.mutation<IForum, LikeForumData>({
			invalidatesTags: ["forumComment", "likeSubject", "forum"],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `likemessage/${slug}/`,
						method: "DELETE",
						body: data,
					};
				}
				return {
					url: `likemessage/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetForumSubjectsQuery,
	useDeleteForumMutation,
	useGetForumCommentQuery,
	useAddCommentForumMutation,
	useDeleteCommentForumMutation,
	useCreateOrEditPublicationMutation,
	useGetResponsesByCommentQuery,
	useToggleLikeSubjectMutation,
	useGetLikesSubjectQuery,
	useIncrementSubjectViewMutation,
} = ForumApi;
