/* eslint-disable @typescript-eslint/no-explicit-any */
type messageType = {
	message: string | any;
};
function ErrorMessage({ message }: messageType) {
	return (
		<div className="text-danger" role="alert">
			{message}
		</div>
	);
}

export default ErrorMessage;
