import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { usePagination } from "../../../../utils/hooks";
import { useGetDevisQuery } from "../../../../utils/api/contact/contact.api";
import { IDevis } from "../../../../utils/api/contact/contact.type";
import { AiOutlineDownload } from "react-icons/ai";
import { ApiBaseUrl } from "../../../../utils/http";

function DevisTable() {
	const { page, limit, setPage, setPerPage } = usePagination(10);
	const { isLoading, data = { results: [], count: 0 } } = useGetDevisQuery({
		page,
		limit,
	});
	console.log({ data });

	const docFormatter = (cell: string, row: IDevis) => {
		return (
			<a
				href={ApiBaseUrl + row?.document}
				className="btn-dow"
				title="Télécharger"
				target="_blank"
			>
				<AiOutlineDownload fontSize={24} />
			</a>
		);
	};

	const columns = [
		{
			dataField: "first_name",
			text: "Nom",
			formatter: (cell: string, row: IDevis) => row?.first_name + " " + row.last_name,
		},
		{
			dataField: "email",
			text: "Email",
			formatter: (cell: string, row: IDevis) => row?.first_name + " " + row.last_name,
		},
		{
			dataField: "document",
			text: "Document",
			formatter: (cell: string, row: IDevis) => docFormatter(cell, row),
		},
		// {
		// 	dataField: "slug",
		// 	text: "Actions",
		// 	style: { textAlign: "center" },
		// 	formatter: (cell: string, row: Category) => actionFormatter(cell, row),
		// },
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setPerPage(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default DevisTable;
