import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddOrEditConditionMutation } from "../../../../../utils/api/condition/condition.api";
import { ConditionUpdateFormData } from "../../../../../utils/api/condition/condition.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";

function useAddOrEditConditionForm() {
	const [description, setDescription] = React.useState<string>("");
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Titre"),
		description: yup.string().required("Contenu est un champ obligatoire."),
		type: yup.string().required(),
	});

	const { user } = useAppSelector((s) => s?.user);

	const location = useLocation();
	const navigate = useNavigate();

	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		register,
	} = useForm<ConditionUpdateFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: { created_by: user?.id },
	});
	const [editData, { isLoading }] = useAddOrEditConditionMutation();
	const [type, setType] = useState("")

	useEffect(() => {
		if (location) {
			const { content, type } = location?.state;
			console.log({ content, type });
			setDescription(content?.description);
			setType(type)
			setValue("type", type);
			setValue("slug", content?.slug);
			setValue("title", content?.title);
			setValue("description", content?.description);
		}
	}, [location]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleChange = (value) => {
		setDescription(value);
		setValue("description", value);
	};

	const onSubmit = async (data: ConditionUpdateFormData) => {
		console.log(data);
		const res = await editData({ slug: data?.slug, data: data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Enregistré avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				if (type === "cgu") {
					navigate("/admin/parametres?step=cgu")
				} else {
					navigate("/admin/parametres?step=policy")
				}
			})
			// navigate(-1);
		} else if ("error" in res) {
			console.log("error", res.error);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: `${
					err?.data?.message
						? err?.data?.message
						: `Une erreur de status  ${err?.status} a survenu!`
				}`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return {
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		description,
		onchange: handleChange,
		register,
		type,
	};
}

export default useAddOrEditConditionForm;
