import React from "react";
import { animatedComponents, FormError, InputAdmin } from "../../../common/Input";
import UseCrudBookForm from "./requestBook/UseCrudBookForm";
import ReactQuill from "react-quill";
import { useGetCategoriesQuery } from "../../../../utils/api/category/category.api";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { useNavigate } from "react-router-dom";
import { getImage, getInputAvatar, getUrl } from "../../../../utils/Utils";
import { FiBook } from "react-icons/fi";
import PdfViewerModal from "./modal/pdfViewerModal";
import { accept } from "../../../../utils/type";
import { isAdminUser } from "../../../../routes/routerUtils";
import Select from "react-select";

const AjouterLivre = () => {
	let navigate = useNavigate();
	const [item] = useBookFromLocation();
	const {
		register,
		errors,
		isLoading,
		onSubmit,
		setValue,
		handleChangeDescription,
		description,
		loading,
		files,
		handleChangeFiles,
		options,
		option,
		handleSelectAuthor,
		handleSearch,
		Controller,
		control,
		user,
		loadingData,
	} = UseCrudBookForm(item);
	const { data = { results: [] } } = useGetCategoriesQuery({});
	return (
		<div className="add-auteur-component">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Ajouter un livre</h3>
				</div>
			</div>
			<div className="container-form-admin mt-5 container-admin-page-table">
				<form onSubmit={onSubmit}>
					<div className="row row-form-admin">
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="nom"
									label="Nom du livre"
									placeholder="Titre du livre"
									type={"text"}
									{...register("title")}
									error={errors?.title?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="prix"
									min={1}
									step={"any"}
									label="Prix du livre"
									placeholder="Prix du livre"
									type={"number"}
									{...register("price")}
									error={errors?.price?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<div className="mb-3">
									<label
										htmlFor={"categorie"}
										className="form-label form-label-admin"
										aria-labelledby={"categorie"}
									>
										Catégorie
										<span
											className="text-danger custom-required-text"
											style={{ fontSize: 17 }}
										>
											*
										</span>
									</label>
									<select
										id="categorie"
										{...register("category")}
										className="form-control form-control-admin"
									>
										<option value="">Catégorie</option>
										{data?.results?.map((category) => (
											<option value={category?.id} key={category?.id}>
												{category?.name}
											</option>
										))}
									</select>

									<FormError error={errors?.category?.message} />
								</div>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="photo"
									// {...register("couverture")}
									name="couverture"
									onChange={(e) => handleChangeFiles(e)}
									accept="image/*"
									error={errors?.couverture?.message}
									label="Photo couverture "
									type={"file"}
									required
								/>

								<div className="image-item">
									{!!files?.couverture && (
										<img
											src={getInputAvatar(files?.couverture)}
											alt={"couverture"}
											loading="lazy"
											className="img-thumbnail"
											id="overlay"
										/>
									)}
									{!files?.couverture && item?.couverture && (
										<img
											src={getImage(item?.couverture)}
											alt={"couverture"}
											loading="lazy"
											className="img-thumbnail"
											id="overlay"
										/>
									)}
								</div>
							</div>
						</div>
						{!!isAdminUser(user) && (
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									{/* <InputAdmin
										id="langage"
										label="Langue"
										placeholder="Langue du livre"
										type={"text"}
										{...register("langage")}
										error={errors?.langage?.message}
										required
									/> */}
									<div className="mb-3">
										<label
											htmlFor={"author"}
											className="form-label form-label-admin"
											aria-labelledby={"author"}
										>
											Auteur
											<span
												className="text-danger custom-required-text"
												style={{ fontSize: 17 }}
											>
												*
											</span>
										</label>
										<Controller
											name="author"
											control={control}
											render={({ field }) => {
												return (
													<Select
														{...field}
														closeMenuOnSelect={false}
														components={animatedComponents}
														placeholder="Selectionner un auteur"
														isLoading={loadingData}
														options={options}
														classNamePrefix="react-select-custom-admin"
														onInputChange={handleSearch}
														onChange={handleSelectAuthor}
														value={option}
													/>
												);
											}}
										/>
										<FormError error={errors?.author?.message} />
									</div>
								</div>
							</div>
						)}
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="langage"
									label="Langue"
									placeholder="Langue du livre"
									type={"text"}
									{...register("langage")}
									error={errors?.langage?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="date_out"
									min={1}
									step={"any"}
									label="Date de sortie"
									placeholder="Date de sortie"
									type={"date"}
									{...register("date_out")}
									error={errors?.date_out?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="isbn"
									label="ISBN"
									placeholder="ISBN du livre"
									type={"text"}
									{...register("isbn")}
									error={errors?.isbn?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="editeur"
									min={1}
									step={"any"}
									label="Éditeur"
									placeholder="Éditeur"
									type={"text"}
									{...register("editeur")}
									error={errors?.editeur?.message}
									required
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="livre"
									label="Ajouter le livre (format pdf)"
									type={"file"}
									accept="application/pdf"
									// {...register("ebook")}
									name="ebook"
									onChange={(e) => handleChangeFiles(e)}
									error={errors?.ebook?.message}
									required
								/>
								{(files?.ebook || item?.ebook) && (
									<>
										<button
											className="btn yeewuna-description-livre-infos-text-btn"
											data-bs-toggle="modal"
											data-bs-target="#pdfViewerModal"
											type="button"
										>
											<FiBook /> Livre pdf
										</button>
										<PdfViewerModal
											url={
												files?.ebook
													? getInputAvatar(files?.ebook)
													: getUrl(item?.ebook)
											}
										/>
									</>
								)}
							</div>
						</div>
						{!!isAdminUser(user) && (
							<>
								<div className="col-md-6"></div>
								<div className="col-md-6"></div>
							</>
						)}
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="audio"
									label="Ajouter l'ebook(format epub)"
									required
									accept={accept.epub}
									name="epub"
									onChange={(e) => handleChangeFiles(e)}
									error={errors?.epub?.message}
									type={"file"}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="prix_ebook"
									label="Prix ebook"
									min={1}
									step="any"
									type={"number"}
									placeholder="Prix ebook"
									{...register("pdf_price")}
									error={errors?.pdf_price?.message}
									required
								/>
							</div>
						</div>

						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									id="audio"
									label="Audiobook"
									accept="audio/mp3,audio/*,capture=microphone"
									// {...register("audio")}
									name="audio"
									onChange={(e) => handleChangeFiles(e)}
									error={errors?.audio?.message}
									type={"file"}
								/>
							</div>
						</div>
						<div className="col-md-6 col-form-admin mb-3">
							<div className="content-form-admin">
								<InputAdmin
									{...register("audio_price")}
									error={errors?.audio_price?.message}
									id="prix_audio"
									label="Prix audiobook"
									min={1}
									step="any"
									type={"number"}
									placeholder="Prix audiobook"
								/>
							</div>
						</div>

						<div className="col-md-12 col-form-admin mb-3">
							<div className="content-form-admin">
								<div className="mb-3">
									<label
										htmlFor={"description"}
										className="form-label form-label-admin"
										aria-labelledby={"description"}
									>
										Description
										<span
											className="text-danger custom-required-text"
											style={{ fontSize: 17 }}
										>
											*
										</span>
									</label>

									<ReactQuill
										theme="snow"
										value={description}
										onChange={handleChangeDescription}
										className="form-control form-control-admin"
									/>

									<FormError error={errors?.description?.message} />
								</div>
							</div>
						</div>
						<div className="col-md-3 offset-md-6 mb-3 pt-4">
							<div className="content-btn-form-admin">
								{(item && item?.book_status === "published") ||
								isAdminUser(user) ? (
									<button
										className="btn auth-submit-annuler w-100"
										type="button"
										onClick={() => navigate(-1)}
									>
										Annuler
									</button>
								) : (
									<button
										className="btn btn-page-title-admin w-100"
										onClick={() => setValue("book_status", "drafted")}
										disabled={loading || isLoading}
										hidden={
											item && item?.book_status === "published" ? true : false
										}
									>
										{loading ? (
											<>
												<span
													className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
													role="status"
												></span>
												<span>En cours...</span>
											</>
										) : (
											"Ajouter aux brouillons"
										)}
									</button>
								)}
							</div>
						</div>
						<div className="col-md-3 mb-3 pt-4">
							<div className="content-btn-form-admin">
								<button
									className="btn btn-page-title-admin w-100"
									onClick={() => !!item && setValue("book_status", "published")}
									disabled={isLoading}
								>
									{isLoading && !loading ? (
										<>
											<span
												className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
												role="status"
											></span>
											<span>En cours...</span>
										</>
									) : item ? (
										"Modifier"
									) : (
										"Ajouter et publier"
									)}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AjouterLivre;
