import React from "react";
import { FiSearch } from "react-icons/fi";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import BannerHeader from "../BannerHeader";
import "./Faq.css";
import { useGetFaqForVisitorsQuery } from "../../../utils/api/faq/faq.api";
import { AlertInfo } from "../../common/Alert";
import { createMarkup, showModal } from "../../../utils/Utils";
import { useSearch } from "../../../utils/hooks";
import ContactModal from "./modal/ContactModal";

function Faq() {
	const { search, handleSearch } = useSearch();
	const { data, isLoading } = useGetFaqForVisitorsQuery({ sujet: search });
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<BannerHeader title="FAQ" />
				<section className="section-a-propos-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center">
							<div className="content-text-section-apropos-container pb-5">
								<h2 className="faq-page-question-title mb-4">
									Comment pouvons -nous vous aider ?
								</h2>
								<div className="search-form-container">
									<div className="search-form-content">
										<label>
											<FiSearch />
										</label>
										<input
											name="search"
											className="form-control search-input"
											placeholder="Rechercher un sujet"
											onChange={handleSearch}
										/>
									</div>
								</div>
								<div className="page-faq-titre-faq-container">
									<div className="page-faq-titre-faq mb-2">FAQ</div>
									<div className="page-faq-titre-faq-paragraphe mb-5">
										Lorem ipsum dolor sit amet consectetur. Etiam magnis lorem
										vitae nunc.
									</div>
									<div className="yeewouna-accordeon-container">
										<div className="accordion" id="accordionExample">
											{!isLoading ? (
												<>
													{data && data?.length > 0 ? (
														data?.map((item, index) => (
															<div
																className="accordion-item"
																key={item?.slug}
															>
																<h2
																	className="accordion-header"
																	id="headingOne"
																>
																	<button
																		className="accordion-button"
																		type="button"
																		data-bs-toggle="collapse"
																		data-bs-target={`#collapse${index}`}
																		aria-expanded="true"
																		aria-controls="collapseOne"
																	>
																		{item?.question}
																	</button>
																</h2>
																<div
																	id={`collapse${index}`}
																	className="accordion-collapse collapse"
																	aria-labelledby="headingOne"
																	data-bs-parent="#accordionExample"
																>
																	<div
																		className="accordion-body"
																		dangerouslySetInnerHTML={createMarkup(
																			item?.answer
																		)}
																	/>
																</div>
															</div>
														))
													) : (
														<AlertInfo message="Aucun donnée trouvée" />
													)}
												</>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="yeewouna-faq-vous-avez-toujours-des-questions-container mt-4">
								<div className="yeewouna-faq-vous-avez-toujours-des-questions-titre">
									Vous avez toujours des questions ?
								</div>
								<div className="yeewouna-faq-vous-avez-toujours-des-questions-paragraphe mb-4">
									Lorem ipsum dolor sit amet consectetur. At lorem mattis donec
									hac dictum lobortis{" "}
								</div>
								<button
									className="yeewouna-faq-vous-avez-toujours-des-questions-btn"
									onClick={() => showModal("contactModal")}
								>
									Contactez-nous
								</button>
								<ContactModal modalId="contactModal" />
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
}

export default Faq;
