import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import BannerHeader from "../BannerHeader";
import "./Blog.css";
import Pagination from "../../common/Pagination";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import { useGetArticlesForVisitorsQuery } from "../../../utils/api/article/article.api";
import { usePagination } from "../../../utils/hooks";
import { AlertInfo } from "../../common/Alert";
import { createMarkup, getImage, isShowPagination } from "../../../utils/Utils";
import moment from "moment";
import { Link } from "react-router-dom";

export function ArticlesList({ perPage }: { perPage?: number }) {
	const { limit, page, setPage } = usePagination(perPage || 12);
	const { data, isLoading } = useGetArticlesForVisitorsQuery({ limit, page });
	return (
		<div className="row py-5">
			{!isLoading ? (
				data && data?.results?.length > 0 ? (
					<>
						{data?.results?.map((item) => (
							<div className="col-md-6 col-lg-4 mb-4" key={item?.slug}>
								<div className="yeewuna-blog-card-item">
									<Link to={`/blog/${item?.slug}`} state={item}>
										<div className="yeewuna-blog-card-img-container">
											<img
												src={getImage(item?.image)}
												alt="photos de blog"
												className="yeewuna-blog-card-img"
											/>
											<div className="yeewuna-blog-card-date-container">
												<div className="yeewuna-blog-card-date">
													{moment(item?.created_at).format("DD")}
												</div>
												<div className="yeewuna-blog-card-mois text-capitalize">
													{moment(item?.created_at).format("MMM")}
												</div>
											</div>
										</div>
										<div className="yeewuna-blog-card-body-container">
											<div className="yeewuna-blog-card-categorie my-2">
												{item?.category?.name}
											</div>
											<h5 className="card-title yeewuna-blog-card-title mb-3">
												{item?.title}
											</h5>
											<div
												className="card-text yeewuna-blog-card-text"
												dangerouslySetInnerHTML={createMarkup(
													item?.content?.slice(0, 100)
												)}
											/>
										</div>
									</Link>
								</div>
							</div>
						))}
						{!perPage && (
							<div className="flex-c my-5">
								{isShowPagination(data?.count || 0, limit) && (
									<Pagination
										page={page}
										total={data?.count}
										perPage={limit}
										onPageChange={(page: number) => setPage(page)}
									/>
								)}
							</div>
						)}
					</>
				) : (
					<AlertInfo message="Aucune donnée trouvée" />
				)
			) : (
				<></>
			)}
		</div>
	);
}

function Blog() {
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<BannerHeader title="Blog" />
				<section className="section-a-propos-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center mb-4">
							<h2 className="custom-h2-theme position-relative">Blog</h2>
						</div>
						<ArticlesList />
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
}

export default Blog;
