import React from "react";
import { FiZap } from "react-icons/fi";
import { IOffer } from "../../../utils/api/offer/offer.type";
import VolkenoSkeleton from "volkeno-react-skeleton";
import "volkeno-react-skeleton/dist/index.css";
import { createMarkup, formatMontant } from "../../../utils/Utils";
import { NavLink } from "react-router-dom";

const OfferItem = ({
	item,
	handleChoiceOffer,
}: {
	item?: IOffer;
	handleChoiceOffer?: (item?: IOffer) => void;
}) => {
	return (
		<div className="col-md-6 col-xl-3 col-abonnement-home-page mb-3">
			<div className="content-col-abonnement-home-page">
				<div className="content-icon-head flex-c mb-3">
					<div className="icon-head-abonnement">
						<FiZap />
					</div>
				</div>
				<p className="subtitle-abonnement-item">{item?.title}</p>
				<h2 className="title-abonnement-card offer-price">
					{formatMontant(item?.price)}/mois
				</h2>
				<p className="text-describe-abonnement-card">{item?.offer_text}</p>
				<div className="abonnement-list-content">
					<div
						className="list-offer"
						dangerouslySetInnerHTML={createMarkup(item?.description)}
					/>
				</div>
				<div className="content-btn-card-abonnement px-3 mb-3">
					{handleChoiceOffer ? (
						<button
							className="btn btn-card-abonnement"
							onClick={() => handleChoiceOffer(item)}
							type="button"
							data-bs-dismiss="modal"
						>
							Commencer
						</button>
					) : (
						<NavLink
							className="btn btn-card-abonnement"
							to={`/devenir-autheur`}
							state={item}
						>
							Commencer
						</NavLink>
					)}
				</div>
			</div>
		</div>
	);
};
export const OfferItemSkeleton = () => {
	return (
		<div className="col-md-6 col-xl-3 col-abonnement-home-page mb-3">
			<div className="content-col-abonnement-home-page">
				<div className="content-icon-head flex-c mb-3">
					<VolkenoSkeleton
						variant="rect"
						width={30}
						height={30}
						style={{ borderRadius: "50%" }}
					/>
				</div>
				<div className="d-flex flex-column align-items-center">
					<VolkenoSkeleton variant="rect" width={100} height={16} />
					<VolkenoSkeleton variant="rect" width={150} height={10} />
				</div>
				<div className="abonnement-list-content p-3">
					{[...Array(6)]?.map((item, i) => (
						<div className="abonnement-list-tem mb-3" key={i}>
							<div className="div-container-icon-abonnement-item">
								<VolkenoSkeleton
									variant="rect"
									width={20}
									height={20}
									style={{ borderRadius: "50%" }}
								/>
							</div>
							<div className="content-text-abonnement-item">
								<VolkenoSkeleton variant="rect" width={100} height={10} />
							</div>
						</div>
					))}
				</div>
				<div className="content-btn-card-abonnement p-3 mb-3">
					<VolkenoSkeleton variant="rect" width="100%" height={50} />
				</div>
			</div>
		</div>
	);
};
export default OfferItem;
