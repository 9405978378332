import { FiAlertCircle } from "react-icons/fi";
type sizeType = "small" | "medium" | "large";

type PropsType = {
	message: string;
	size?: sizeType;
};
function addSizeValue(size: sizeType) {
	if (size === "large") {
		return "2rem 1rem";
	} else if (size === "medium") {
		return "1rem 1rem";
	} else {
		return ".7rem 1rem";
	}
}
export function AlertInfo({ message, size = "large" }: PropsType) {
	return (
		<div className="px-3 mb-4">
			<div
				className="yeewuna-alert yeewuna-alert-info"
				role="alert"
				style={{
					padding: addSizeValue(size),
				}}
			>
				<FiAlertCircle style={{ fontSize: 24 }} />
				<h4>{message}</h4>
			</div>
		</div>
	);
}
// export function AlertInfo({ message }: PropsType) {
// 	return (
// 		<div className="px-3">
// 			<div className="yeewuna-alert yeewuna-alert-info" role="alert">
// 				<FiAlertCircle style={{ fontSize: 24 }} />
// 				<h4>{message}</h4>
// 			</div>
// 		</div>
// 	);
// }
