import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { DashboardAdminGraphe } from "../../../../../utils/api/admin/admin.type";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
);

function LineChart({ graphData }: { graphData?: DashboardAdminGraphe[] }) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
				display: false,
			},
		},
	};

	const labels = graphData?.map((item) => moment(item?.month)?.format("MMM"));

	const data = {
		labels,
		datasets: [
			{
				label: "Note moyenne",
				data: graphData?.map((item) => item?.vente),
				borderColor: "#E39A34",
				backgroundColor: "#e59b3416",
				fill: true,
				lineTension: 0.3,
			},
			{
				label: "Exams",
				data: graphData?.map((item) => item?.vente),
				borderColor: "#E59B34",
				backgroundColor: "#e59b3430",
				fill: true,
				lineTension: 0.3,
			},
		],
	};

	return (
		<div className="chart-item-container">
			<h2 className="chart-item-admin-title">Aperçu des ventes</h2>
			{/* <p className="text-legend-graph pb-5">
				(+5) de plus en <span style={{ fontSize: 14, color: "#A0AEC0" }}>2022</span>{" "}
			</p> */}
			<Line options={options} data={data} />
		</div>
	);
}

export default LineChart;
