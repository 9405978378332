import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import fmoney from "../../../../../assets/appImages/fmoney.png";
import omoney from "../../../../../assets/appImages/omoney.png";
import wmoney from "../../../../../assets/appImages/wmoney.png";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetPaiementAuthorQuery } from "../../../../../utils/api/user/user.api";
import { usePagination } from "../../../../../utils/hooks";
import { formatMontant, getAvatar, getName } from "../../../../../utils/Utils";
import { IUser, PaiementItem } from "../../../../../utils/api/user/user.type";
import { OrderItem } from "../../../../../utils/api/order/order.type";
import { ServicePayment } from "../../../../../utils/type";
function ListPaymentAuteurTable() {
	const { page, limit } = usePagination(10);
	const { user } = useAppSelector((s) => s?.user);
	const { data = { results: [], count: 0 }, isLoading } = useGetPaiementAuthorQuery({
		slug: user?.slug,
		page,
		limit,
	});

	console.log({ data });
	// const actionFormatter = (cell: any, row: any) => {
	// 	return (
	// 		<>
	// 			<div className="table-actions-btn-container-commandes d-flex gap-3">
	// 				<div className="container-btn-action-icon-modal">
	// 					<button
	// 						className="btn with-tooltip btn-action-icon-delete"
	// 						data-tooltip-content="Supprimer"
	// 						style={{ color: "#E53E3E" }}
	// 					>
	// 						<BsTrashFill />
	// 					</button>
	// 				</div>
	// 			</div>
	// 		</>
	// 	);
	// };
	const nameFormatter = (cell: IUser) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-auteur-admin-table">
					<img
						src={getAvatar(cell?.avatar)}
						alt={cell?.last_name}
						className="photo-profil-auteur-admin-table"
					/>
				</div>{" "}
				<p className="name-auteur-admin-table mb-0">{getName(cell)}</p>
			</div>
		);
	};
	const paymentFormatter = (cell, row: PaiementItem) => {
		return (
			<div className="table-actions-btn-container-img">
				<div className="content-img-pp-livre-admin-table">
					<img
						src={
							row?.payment_method === ServicePayment.OM
								? omoney
								: row?.payment_method === ServicePayment.WAVE
								? wmoney
								: fmoney
						}
						alt={cell}
						className="img_modepaiement"
					/>
				</div>{" "}
			</div>
		);
	};
	const bookFormatter = (cell: OrderItem[]) => {
		return (
			<div className="table-actions-btn-container-img">
				{cell?.map((order) => (
					<span key={order?.slug}>{order?.item?.title},</span>
				))}
			</div>
		);
	};

	// const selectRow = {
	// 	mode: "checkbox",
	// };
	const rowClasses = () => {
		return "custom-row-class-admin";
	};
	const columns = [
		{
			dataField: "user",
			text: "Nom",
			formatter: (cell: IUser) => nameFormatter(cell),
		},
		{
			dataField: "user",
			text: "Email",
			formatter: (cell: IUser) => cell?.email,
		},
		{
			dataField: "order_items",
			text: "Livre acheté",
			formatter: (cell: OrderItem[]) => bookFormatter(cell),
		},
		{
			dataField: "order_total",
			text: "Prix",
			formatter: (cell: string) => formatMontant(cell),
		},
		{
			dataField: "id",
			text: "Mode de paiments",
			formatter: (cell: string, row: PaiementItem) => paymentFormatter(cell, row),
		},
		// {
		// 	dataField: "id",
		// 	text: "Actions",
		// 	style: { textAlign: "center" },
		// 	formatter: (cell: any, row: any) => actionFormatter(cell, row),
		// },
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						// selectRow={selectRow}
						rowClasses={rowClasses}
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
				</>
			)}
		</>
	);
}

export default ListPaymentAuteurTable;
