/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { validatePassword } from "../../../auth/useAuthForm/useRegisterForm";
import { ChangePasswordData } from "../../../../utils/api/auth/auth.type";
import {
	useAddOrEditUserMutation,
	useChangePasswordMutation,
} from "../../../../utils/api/user/user.api";
import { useAppSelector } from "../../../../redux/hooks";
import { Color } from "../../../../utils/theme";

function UseFormChangePassword(closeModalChangePassword) {
	const validationSchema = yup.object().shape({
		old_password: yup.string().required().label("L'ancien mot de passe"),
		new_password: validatePassword(yup),
		confirm_password: yup
			.string()
			.oneOf(
				[yup.ref("new_password"), undefined],
				"veuillez entrer des mots de passe identiques."
			),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
		reset,
		setValue,
	} = useForm<ChangePasswordData>({
		resolver: yupResolver(validationSchema),
	});
	const [sendData] = useAddOrEditUserMutation();
	const { user } = useAppSelector((state) => state.user);
	const [changePassword, { isLoading, isSuccess, error, isError }] = useChangePasswordMutation();
	const [haveMinUppercase, setHaveMinUppercase] = useState(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState(false);
	const [haveMinNumber, setHaveMinNumber] = useState(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

	const countUppercase = (str: any) => {
		if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: any) => {
		if (str.replace(/[^a-z]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: any) => {
		if (str.replace(/[^0-9]/g, "")?.length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: any) => {
		if (str?.length >= 8) {
			return true;
		}
		return false;
	};

	const countSpecial = (str: any) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str?.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		// return count;
		if (count >= 1) {
			return true;
		}
		return false;
	};

	const passwordHandleChange = (e: any) => {
		var password = e.target.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}

		setValue("new_password", e.target.value);
	};
	const passwordHandleReset = () => {
		reset();
		setHaveMinUppercase(false);
		setHaveMinLowercase(false);
		setHaveMinNumber(false);
		setHaveMinCharacter(false);
		setHaveMinSpecialCharacter(false);
	};
	useEffect(() => {
		if (isSuccess) {
			if (user?.first_connexion) {
				sendData({
					slug: user?.slug,
					userType: user?.user_type,
					data: { first_connexion: false },
				});
			}

			closeModalChangePassword();
			Swal.fire({
				icon: "success",
				title: "Mot de passe modifiée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				passwordHandleReset();
				// onHide("ChangePasswordModal");
			});
		}
	}, [isSuccess]);
	useEffect(() => {
		const err = error as any;
		if (isError) {
			if (err) {
				// console.log(err?.data?.old_password);
				if (err?.data?.old_password?.includes("Wrong password.")) {
					setError("old_password", {
						message: "Le mot de passe est incorrect!",
					});
				} else {
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: "Une erreur a survenue lors de la modification",
						showConfirmButton: false,
						timer: 3000,
					});
				}
			}
		}
	}, [isLoading]);
	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 4000);
		}
	}, [errors]);

	const onSubmit = (data: ChangePasswordData) => {
		// console.log(data);
		changePassword(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		passwordHandleChange,
		haveMinCharacter,
		haveMinLowercase,
		haveMinNumber,
		haveMinSpecialCharacter,
		haveMinUppercase,
		passwordHandleReset,
	};
}

export default UseFormChangePassword;
