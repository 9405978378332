import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./QuiSommesNous.css";
import BannerHeader from "../BannerHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import { useGetTeamForVisitorsQuery } from "../../../utils/api/team/team.api";
import { getImage } from "../../../utils/Utils";

function QuiSommesNous() {
	const { data, isLoading } = useGetTeamForVisitorsQuery();
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<BannerHeader title="Qui sommes-nous ?" />
				<section className="section-a-propos-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center">
							<h2 className="h2-theme position-relative">À propos</h2>
							<div className="content-text-section-apropos-container py-5">
								<p className="content-text-section-apropos-paragraphe">
									Les Editions Yeewuna sont une maison d'édition polyvalente,
									proposant une large gamme de supports pour les livres. Que vous
									préfériez les versions papiers, les ebooks ou les audiobooks,
									les Editions Yeewuna ont la solution pour vos envies de lecture.
									En plus de cela, elles offrent une plateforme lecture en ligne
									avec un réseau social intégré pour une meilleure proximité entre
									auteurs et lecteurs. Avec leur approche innovante, les Editions
									Yeewuna sont une maison d'édition à la pointe de la technologie.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="section-notre-equipe-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center">
							<h2 className="h2-theme position-relative">Notre équipe</h2>
							<div className="row py-5">
								{!isLoading ? (
									data &&
									data?.map((item, i) => (
										<div className="col-md-6 col-lg-4 col-xxl-3 mb-4" key={i}>
											<div className="section-notre-equipe-qsn-image-container">
												<img
													src={getImage(item?.image)}
													alt="photos membre de l'équipe"
													className="section-notre-equipe-qsn-image"
												/>
												<div className="section-notre-equipe-qsn-titre-img-container py-3">
													<div className="section-notre-equipe-qsn-titre-img">
														{item?.name}
													</div>
													<div className="section-notre-equipe-qsn-role">
														{item?.function}
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
}

export default QuiSommesNous;
