import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
	ChatApi,
	useChatByUserQuery,
	useMarkReadAllChatMutation,
	useUpdateCHatMutation,
} from "../../../../../utils/api/chat/chat.api";
import { ChatData } from "../../../../../utils/api/chat/chat.type";
import { IUser } from "../../../../../utils/api/user/user.type";
import { useLocationState } from "../../../../../utils/Utils";

export default function useMessagerie() {
	const userId = useAppSelector((s) => s.user.user?.id);
	const userSlug = useAppSelector((s) => s.user.user?.slug);
	const itemState = useLocationState<IUser>(undefined);

	const [read, setRead] = useState<boolean | null>();
	const [search, setSearch] = useState<string>("");

	const { data: chatData = [], isLoading } = useChatByUserQuery({
		nom: search,
		q: read,
	});
	const [updateChat] = useUpdateCHatMutation();
	const [selectedUser, setSelectedUser] = useState<IUser | null>(itemState);

	const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
	const dispatch = useAppDispatch();
	const sUser = useLocationState<IUser>(null);
	const [readAll] = useMarkReadAllChatMutation();

	useEffect(() => {
		if (!selectedUser) {
			if (sUser) {
				setSelectedUser(sUser);
			} else {
				if (chatData.length > 0) {
					setSelectedUser(chatData[0].user);
				}
			}
		}
	}, [sUser, chatData, selectedUser]);

	useEffect(() => {
		if (selectedUser) {
			setSelectedChat(chatData.find((l) => l.user.id === selectedUser.id) || null);
		} else {
			setSelectedChat(null);
		}
	}, [selectedUser, chatData]);

	useEffect(() => {
		dispatch(ChatApi.util.invalidateTags(["chat"]));
	}, []);

	useEffect(() => {
		if (selectedChat) {
			setTimeout(() => {
				const promises = selectedChat?.messages
					.filter((chat) => {
						return !chat.read && chat.sender.id !== userId;
					})
					.map((chat) => updateChat({ slug: chat.slug, read: true }));
				Promise.all(promises).then((res) => console.log("marked as read", res?.length));
			}, 3000);
		}
	}, [selectedChat, userId]);

	const handleReadAll = () => {
		readAll(userSlug);
	};

	const getNotReadedChat = () => {
		setRead(false);
	};

	const resetFilter = () => {
		setRead(null);
	};

	return {
		userId,
		chatData,
		isLoading,
		selectedChat,
		setSelectedUser,
		selectedUser,
		handleReadAll,
		getNotReadedChat,
		resetFilter,
		read,
		setSearch,
	};
}
