import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { IForum, ILikeForum, LikeForumData } from "../../../../../utils/api/forum/forum.type";
import {
	useToggleLikeSubjectMutation,
	useGetLikesSubjectQuery,
	useIncrementSubjectViewMutation,
} from "../../../../../utils/api/forum/forum.api";

export function useToggleLikeSubject(item?: IForum) {
	const [isLike, setIsLike] = useState<ILikeForum | null>(null);
	const { user } = useAppSelector((s) => s?.user);
	const [toggleLike] = useToggleLikeSubjectMutation();
	const { data } = useGetLikesSubjectQuery({ slug: item?.slug });

	useEffect(() => {
		if (data) {
			const isLike = data?.find((item) => item?.user?.id === user?.id);
			setIsLike(isLike || null);
		}
	}, [data]);

	const handleToggleLikeSubject = async () => {
		const data: LikeForumData = {
			slug: isLike?.slug,
			message: item?.id,
			user: user?.id,
		};
		console.log({ data });
		const res = await toggleLike(data);
		if (!isLike) {
			if ("data" in res) {
				console.log("succes");
			} else if ("error" in res) {
				console.log("error");
			}
		}
	};

	return {
		isLike,
		handleToggleLikeSubject,
		user,
		setIsLike,
	};
}

export function useIncrementViewSubject(item?: IForum) {
	const [isIncrement, setIsIncrement] = useState<boolean>(false);

	const [incrementView] = useIncrementSubjectViewMutation();

	const onIncrement = () => {
		console.log({ isIncrement });

		if (!isIncrement && item) {
			setIsIncrement(true);
			incrementView(item?.slug as string);
		}
	};
	return {
		onIncrement,
	};
}
