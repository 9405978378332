import React from "react";
import { BtnSubmit } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import UseContactUsForm from "../request/UseContactUsForm";

function ContactModal({ modalId }: { modalId: string }) {
	const { register, errors, message, handleChangeMessage, onSubmit, isLoading, refInput } =
		UseContactUsForm(modalId);
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby={`${modalId}Label`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg">
				<div className="modal-content text-start">
					<div className="modal-header custom-modal-header px-2 py-1">
						<h5 className="modal-title mb-3" id={`${modalId}Label`}>
							Nous contacter
						</h5>
					</div>
					<div className="modal-body">
						<div className="container-form">
							<form onSubmit={onSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="mb-3">
											<Input
												type="text"
												label="Nom complet"
												id="nom"
												placeholder="Nom"
												required
												{...register("name")}
												error={errors?.name?.message}
											/>
										</div>
									</div>

									<div className="col-md-6">
										<div className="mb-3">
											<Input
												type="email"
												label="Email"
												id="mail"
												placeholder="Email"
												required
												{...register("email")}
												error={errors?.email?.message}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="mb-3">
											<Input
												type="text"
												label="Objet"
												id="object"
												placeholder="Objet"
												required
												{...register("subject")}
												error={errors?.subject?.message}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="mb-3">
											<label
												htmlFor="desc"
												className="form-label form-label-modal-custom"
											>
												Message
												<span
													className="text-danger"
													style={{ fontSize: 17 }}
												>
													*
												</span>
											</label>
											<ReactQuill
												theme="snow"
												value={message}
												ref={refInput}
												onChange={handleChangeMessage}
												className="description-commande"
											/>
											<FormError error={errors?.message} />
										</div>
									</div>
									<div className="col-md-12">
										<div className="flex-r pt-5">
											<BtnSubmit label="Envoyer" isLoading={isLoading} />
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactModal;
