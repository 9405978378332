import React from "react";
import { IOffer } from "../../../../../utils/api/offer/offer.type";
import { FiZap } from "react-icons/fi";
import { createMarkup, formatMontant } from "../../../../../utils/Utils";
import { useGetOfferForVisitorsQuery } from "../../../../../utils/api/offer/offer.api";
import { AlertInfo } from "../../../../common/Alert";
import { OfferItemSkeleton } from "../../../../HomePage/offer/OfferItem";
// import ListOffer from "../../../../HomePage/offer/ListOffer";

const OfferItem = ({
	item,
	handleChoiceOffer,
}: {
	item: IOffer;
	handleChoiceOffer: (item: IOffer) => void;
}) => {
	return (
		<div className="col-md-6 col-xl-3 col-abonnement-home-page mb-3">
			<div className="content-col-abonnement-home-page">
				<div className="content-icon-head flex-c mb-3">
					<div className="icon-head-abonnement">
						<FiZap />
					</div>
				</div>
				<p className="subtitle-abonnement-item">{item?.title}</p>
				<h2 className="title-abonnement-card offer-price">
					{formatMontant(item?.price)}/mois
				</h2>
				<p className="text-describe-abonnement-card">{item?.offer_text}</p>
				<div className="abonnement-list-content">
					<div
						className="list-offer"
						dangerouslySetInnerHTML={createMarkup(item?.description)}
					/>
				</div>
				<div className="content-btn-card-abonnement px-3 mb-3">
					<button
						className="btn btn-card-abonnement"
						onClick={() => handleChoiceOffer(item)}
						type="button"
						data-bs-dismiss="modal"
					>
						Choisir
					</button>
				</div>
			</div>
		</div>
	);
};

const ListOffer = ({ handleChoiceOffer }: { handleChoiceOffer: any }) => {
	const { data, isLoading } = useGetOfferForVisitorsQuery();
	return (
		<div className="row row-abonnement">
			{!isLoading ? (
				<>
					{data && data?.length > 0 ? (
						data?.map((item) => (
							<OfferItem
								item={item}
								key={item?.slug}
								handleChoiceOffer={handleChoiceOffer}
							/>
						))
					) : (
						<AlertInfo message="Aucune offre trouvée" />
					)}
				</>
			) : (
				<>
					{[...Array(4)]?.map((item, i) => (
						<OfferItemSkeleton key={i} />
					))}
				</>
			)}
		</div>
	);
};

function OfferStep({ handleChoiceOffer }: { handleChoiceOffer: any }) {
	return (
		<section className="section-avantage-home-page container-abonnement-home-page">
			<div className="container-app-yeewuna px-3">
				<div className="content-title-section-avantage text-center">
					<h2 className="h2-theme position-relative">Nos offres d’abonnement</h2>
					<p className="text-avantage-list-homepage pt-4">
						Choisissez le forfait qui vous convient
					</p>
				</div>
				<ListOffer handleChoiceOffer={handleChoiceOffer} />
			</div>
		</section>
	);
}

export default OfferStep;
