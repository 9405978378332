import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { HistoriqueGroup } from "./historique.type";

export const HistoriqueApi = createApi({
	reducerPath: "historiqueApi",
	tagTypes: ["historique"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getListHistorique: builder.query<PaginationResults<HistoriqueGroup>, TypeQuery>({
			providesTags: ["historique"],
			query: (query) => QueryUrl("historiques", query),
		}),
	}),
});
export const { useGetListHistoriqueQuery } = HistoriqueApi;
