import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { CiHome, CiMail } from "react-icons/ci";
import { HiOutlineUsers } from "react-icons/hi";
import { GiWhiteBook } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { RiFileTextLine, RiSettings3Fill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import VersionChecker from "../../../../../versionChecker/VersionChecker";
import logoHeader from "../../../../../assets/appImages/logoHeader.png";
import { MdPayments } from "react-icons/md";
import { useAppSelector } from "../../../../../redux/hooks";
import { hasAccess } from "../../../../../routes/routerUtils";
import { BsBodyText } from "react-icons/bs";
import { FaBloggerB, FaQuestion } from "react-icons/fa";
import { BiCategory, BiSupport } from "react-icons/bi";

const SidebarMobile = () => {
	let location = useLocation();
	const { user } = useAppSelector((s) => s?.user);
	return (
		<div>
			<div className="container-nav">
				<input type="checkbox" id="check" />
				<label htmlFor="check">
					<AiOutlineMenuUnfold />
					<i className="fas fa-times" id="cancel"></i>
				</label>
				<div className="sidebar" id="scrollbar-nav-mobile">
					<div className="content-nav-mobile">
						<div className="container-user-connect-info pb-4 d-flex justify-content-center">
							<div className="container-info-user-connect-navmenu">
								<img
									src={logoHeader}
									className="yeewuna-logo-header"
									alt="logo de yeewuna"
								/>
							</div>
						</div>
						<ul className="nav nav-items-custom flex-column">
							{location?.pathname?.startsWith("/admin") && (
								<>
									<li className="nav-item nav-item-vertical-custom" hidden={hasAccess(user, "accueil")}>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/dashboard"
										>
											<CiHome />
											<span className="item-nav-menu drop-header">
												Accueil
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom" hidden={hasAccess(user, "auteur")}>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/auteurs"
                      
										>
											<HiOutlineUsers />
											<span className="item-nav-menu drop-header">
												Auteurs
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom" hidden={hasAccess(user, "utilisateur")}>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/utilisateurs"
                      
										>
											<HiOutlineUsers />
											<span className="item-nav-menu drop-header">
												Utilisateurs
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom" hidden={hasAccess(user, "livre")}>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/livres"
                      
										>
											<GiWhiteBook />
											<span className="item-nav-menu drop-header">
												Livres
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom" hidden={hasAccess(user, "message")}>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/messages"
                      
										>
											<CiMail />
											<span className="item-nav-menu drop-header">
												Messages
											</span>
										</NavLink>
									</li>
                  <li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "blog")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/blog"
										>
											<FaBloggerB />
											<span className="item-nav-menu drop-header">Blog</span>
										</NavLink>
									</li>
                  <li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "categorie")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/categories"
										>
											<BiCategory />
											<span className="item-nav-menu drop-header">Catégories</span>
										</NavLink>
									</li>
                  <li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "support")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/support"
										>
											<BiSupport />
											<span className="item-nav-menu drop-header">Support</span>
										</NavLink>
									</li>
                  <li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "faq")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/faq"
										>
											<FaQuestion />
											<span className="item-nav-menu drop-header">Faq</span>
										</NavLink>
									</li>
									<li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "demande_devis")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/devis"
										>
											<RiFileTextLine />
											<span className="item-nav-menu drop-header">Devis</span>
										</NavLink>
									</li>
									<li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "commande_texte")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/commande-texte"
										>
											<BsBodyText />
											<span className="item-nav-menu drop-header">
												Commande texte
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/profil"
										>
											<CgProfile />
											<span className="item-nav-menu drop-header">
												Mon profil
											</span>
										</NavLink>
									</li>
									<li
										className="nav-item nav-item-vertical-custom"
										hidden={hasAccess(user, "parameter")}
									>
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/admin/parametres"
										>
											<RiSettings3Fill />
											<span className="item-nav-menu drop-header">
												Paramètres
											</span>
										</NavLink>
									</li>
								</>
							)}
							{location?.pathname?.startsWith("/auteur") && (
								<>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/auteur/accueil"
										>
											<CiHome />
											<span className="item-nav-menu drop-header">
												Accueil
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/auteur/livres"
										>
											<GiWhiteBook />
											<span className="item-nav-menu drop-header">
												Livres
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/auteur/messages"
										>
											<CiMail />
											<span className="item-nav-menu drop-header">
												Messages
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/auteur/profil"
										>
											<CgProfile />
											<span className="item-nav-menu drop-header">
												Mon profil
											</span>
										</NavLink>
									</li>
									<li className="nav-item nav-item-vertical-custom">
										<NavLink
											className="nav-link nav-link-vertival-custom-admin"
											to="/auteur/paiements"
										>
											<MdPayments />
											<span className="item-nav-menu drop-header">
												Paiements
											</span>
										</NavLink>
									</li>
								</>
							)}
						</ul>
						<div className="container-btn-logout">
							<div className="version-view-container ps-3">
								{"v" + VersionChecker()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SidebarMobile;
