import moment from "moment";
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetResponsesContactQuery } from "../../../../../utils/api/contact/contact.api";
import { Contact } from "../../../../../utils/api/contact/contact.type";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import ErrorMessage from "../../../../common/ErrorMessage";
import { createMarkup, getAvatarFromUser } from "../../../../../utils/Utils";
import useResponseMessageForm from "../request/useResponseMessageForm";
import { BtnSubmit } from "../../../../common/Button";
import { BtnCloseModal } from "../../../../common/Button";

function DetailsMessageModal({ message }: { message?: Contact }) {
	const { user } = useAppSelector((s) => s.user);
	const { data = { results: [] } } = useGetResponsesContactQuery({ slug: message?.slug });
	const { setValue, onSubmit, isLoading, response, handleChange, errors, refInput } =
		useResponseMessageForm();

	useEffect(() => {
		if (message) {
			let msgId: any = message?.id;
			setValue("message", msgId);
			setValue("user", user?.id);
		}
	}, [message]);
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content py-2">
				<div className="wayf-modal-header d-flex justify-content-between align-items-center px-3">
					<h1 className="wayf-modal-title">
						Réponse à <span>({message?.name})</span>
					</h1>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<form onSubmit={onSubmit}>
						<div className="row my-4 border-bottom">
							<div className="col-md-12 form-group">
								<span> {message?.subject} </span>
								<p
									className="admin-form-label fs-15 mt-3"
									dangerouslySetInnerHTML={createMarkup(message?.message)}
								/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-12 form-group">
								<label htmlFor="desc-mision" className="admin-form-label">
									Réponse
								</label>
								<ReactQuill
									ref={refInput}
									defaultValue={response || ""}
									onChange={(value) => handleChange(value)}
								/>
								{errors?.response && (
									<ErrorMessage message={errors?.response?.message} />
								)}
							</div>
						</div>
						<div className="btn-container gap-3 py-3">
							<BtnCloseModal label="Annuler" />
							<BtnSubmit label="Envoyer" isLoading={isLoading} />
						</div>
					</form>
					{data?.results?.length
						? data?.results.map((resp) => (
								<div className="row my-3">
									<div className="col-12 itm-center d-flex">
										<img
											loading="lazy"
											className="avatar-auteur-response-card mx-1"
											src={getAvatarFromUser(resp?.user)}
											alt="Avatar"
											width={"50"}
											height={"50"}
										/>
										<p className="text-response-card fs-12 fw-300 sender-name">
											Par&nbsp;
											<strong>
												{resp?.user?.first_name +
													" " +
													resp?.user?.last_name}
											</strong>
											<br />
											<span className="cm-sender-email">
												{resp?.user?.email}{" "}
											</span>
											<br />
											<span className="cm-sender-email">
												{moment(resp?.created_at).format(
													"DD/MM/YYYY à HH:mm"
												)}{" "}
											</span>
										</p>
									</div>
									<div className="col-12 itm-center mx-1">
										<p
											className="text-response-card fs-12 fw-300"
											style={style.resp_style}
											dangerouslySetInnerHTML={{ __html: resp?.response }}
										/>
									</div>
								</div>
						  ))
						: null}
				</div>
			</div>
		</div>
	);
}

export default DetailsMessageModal;

const style = {
	resp_style: {
		margin: "1em 0px 0em 0em",
		fontSize: "20px",
		fontWeight: "500",
	},
	select_style: {
		display: "flex",
		alignItems: "self-start",
		flexDirection: "row-reverse",
	},
};
