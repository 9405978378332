import React from "react";
import Modal from "react-modal";
import { Book } from "../../../../utils/api/book/book.type";
import { formatMontant, getImage } from "../../../../utils/Utils";
import { BsMicFill, BsPhone } from "react-icons/bs";
import { FiBook } from "react-icons/fi";
import useAddBookToCart from "./hooks/useAddBookToCart";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";

Modal.setAppElement("#root");
type Props = {
	isOpen: boolean;
	closeModal: () => void;
	item: Book;
};
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		zIndex: 99,
		overflowY: "auto",
		border: "2px dashed #E59B34",
	},
};
const BuyBookModal: React.FC<Props> = ({ isOpen, closeModal, item }) => {
	const { addToCart } = useAddBookToCart(item);
	const navigate = useNavigate();
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="buyBookModal"
		>
			<div>
				<div className="flex-r">
					<button
						onClick={() => {
							closeModal();
							navigate("/utilisateur/accueil");
						}}
						style={{ background: "none", border: "none" }}
					>
						<MdClose fontSize={20} />
					</button>
				</div>
				<div className="row d-flex flex-column flex-lg-row align-items-center">
					<div className="col-lg-4">
						<img
							src={getImage(item?.couverture)}
							alt={item?.title}
							className="w-100"
							height="300px"
						/>
						<h1 className="buy-book-modal-title mt-1">{item?.title}</h1>
					</div>
					<div className="col-lg-8">
						<h1 className="buy-modal-title">
							Achetez le livre pour poursuivre la lecture
						</h1>
						<p className="buy-modal-text mb-5">
							Vous avez atteint la fin de votre aperçu gratuit, mais il reste encore
							beaucoup à lire. Le livre est disponible sous trois formats. Choisissez
							celui qui vous convient
						</p>
						<div className="yeewuna-description-livre-infos-text-btn-container">
							<button
								className="btn yeewuna-description-livre-infos-text-btn"
								onClick={() => addToCart("ebook", parseFloat(item?.pdf_price))}
							>
								<BsPhone /> E-book <span>{formatMontant(item?.pdf_price)}</span>
							</button>
							<button
								className="btn yeewuna-description-livre-infos-text-btn"
								onClick={() => addToCart("livre", parseFloat(item?.price))}
							>
								<FiBook /> Livre <span>{formatMontant(item?.price)}</span>
							</button>
							{!!item?.audio && (
								<button
									className="btn yeewuna-description-livre-infos-text-btn"
									onClick={() =>
										addToCart("audiobook", parseFloat(item?.audio_price))
									}
								>
									<BsMicFill /> Audiobook{" "}
									<span>{formatMontant(item?.audio_price)}</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default BuyBookModal;
