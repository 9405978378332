import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
	CommandeText,
	CommandeTextFormData,
} from "../../../../utils/api/commandeText/commandeText.type";
import { useAddOrEditCommandeTextMutation } from "../../../../utils/api/commandeText/commandeText.api";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { useGetCategoriesVisitorsQuery } from "../../../../utils/api/category/category.api";
import { Color } from "../../../../utils/theme";
import { QueryError } from "../../../../utils/type";

function useSendOrderTextForm(modalId: string, item?: CommandeText) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Le nom"),
		prenom: yup.string().required().label("Le prénom"),
		email: yup.string().required().label("L'email"),
		type_oeuvre: yup.string().required().label("Le type d’oeuvre"),
		description: yup.string().required().label("La description"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<CommandeTextFormData>({
		resolver: yupResolver(validationSchema),
	});
	const { data = [] } = useGetCategoriesVisitorsQuery();
	const [options, setOptions] = useState<{ label: string; value: any }[]>([]);
	const [description, setDescription] = useState("");
	const [addOrUpdate, { isLoading }] = useAddOrEditCommandeTextMutation();

	useEffect(() => {
		if (data?.length) {
			setOptions(
				data?.map((el) => {
					return {
						label: el?.name,
						value: el?.id,
					};
				})
			);
		}
	}, [data]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.slug) {
			const fields: (keyof CommandeTextFormData)[] = [
				"slug",
				"description",
				"email",
				"nom",
				"prenom",
				"status",
				"type_oeuvre",
			];
			for (let field of fields) {
				register(field);
				if (field !== "type_oeuvre") {
					setValue(field, item[field]);
				} else {
					setValue(field, item[field]?.id);
				}
			}
			setDescription(item?.description);
		}
	}, [item]);

	const onChange = (val) => {
		setValue("description", val);
		setDescription(val);
	};

	const handleReset = () => {
		if (!item) {
			reset();
			setDescription("");
		}
	};

	const onSubmit = async (data: CommandeTextFormData) => {
		data["status"] = "new"
		const res = await addOrUpdate(data);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: item ? "Commande modifiée avec succès!" : "Le statut de la commande a été envoyé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				closeModal(modalId);
				handleReset();
			});
		}
		if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		options,
		description,
		onChange,
		handleReset,
	};
}

export default useSendOrderTextForm;
