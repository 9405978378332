import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hooks";
import { ArticleFormData, IArticle } from "../../../../../utils/api/article/article.type";
import { useAddOrUpdateArticleMutation } from "../../../../../utils/api/article/article.api";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryError } from "../../../../../utils/type";
import { cleannerError, useLocationState } from "../../../../../utils/Utils";
import { useGetCategoriesVisitorsQuery } from "../../../../../utils/api/category/category.api";

export function useCrudArticle<T extends Yup.AnyObjectSchema>(schema: T) {
	const item = useLocationState<IArticle>(undefined);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ArticleFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			title: item?.title,
		},
	});

	const [addOrUpdateNews, { isLoading }] = useAddOrUpdateArticleMutation();
	const navigate = useNavigate();
	let location = useLocation();
	const { data } = useGetCategoriesVisitorsQuery();

	const [description, setDescription] = useState("");
	const [imageCouverture, setImageCouverture] = useState<File>();

	const { user: authUser } = useAppSelector((s) => s?.user);

	const onChangeDescription = (value: string) => {
		setDescription(value);
		setValue("content", value);
	};

	const handleChangeImageCouverture = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
		if (file) {
			setImageCouverture(file);
			setValue("image", file);
		}
	}, []);

	useEffect(() => {
		if (item) {
			setValue("content", item?.content);
			setDescription(item?.content);
		}
	}, [item]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: ArticleFormData) => {
		const newData: ArticleFormData = { ...data, author: authUser?.id };
		const fd = new FormData();
		for (let key of Object.keys(newData)) {
			let value = newData[key as keyof ArticleFormData];
			fd.append(key, value as keyof ArticleFormData);
		}
		const res = await addOrUpdateNews({ slug: item?.slug, data: fd });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Article ${item ? "modifié" : "ajouté"} avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				reset();
				if (location?.pathname?.startsWith("/admin")) {
					navigate("/admin/blog");
				} else {
					navigate("/auteur/articles");
				}
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
		description,
		onChangeDescription,
		handleChangeImageCouverture,
		imageCouverture,
		item,
		categories: data?.map((category) => {
			return {
				label: category?.name,
				value: category?.id,
			};
		}),
	};
}
