import React from "react";
import ListAdminsTable from "../ListAdminsTable/ListAdminsTable";

const ListAdmins = () => {
	return (
		<div className="admin-tabs">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Liste des sous-admins</h3>
				</div>
			</div>
			<div className="admin-table">
				<ListAdminsTable />
			</div>
		</div>
	);
};

export default ListAdmins;
