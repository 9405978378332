import React from "react";
import { CgMenuGridO } from "react-icons/cg";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../../../Admin/navBar/HeaderAdmin/HeaderAdmin";
import SidebarUser from "../SidebarUser/SidebarUser";
import "./UserLayout.css";

function UserLayout() {
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <input type="checkbox" name="nav" id="nav_button"></input>
        <label htmlFor="nav_button" className="on no-view-mobile">
          <CgMenuGridO className="icon-side-menu" />
        </label>
        <div className="nav-container no-view-mobile">
          <SidebarUser />
        </div>
        <div className="container-header">
          <HeaderAdmin />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container-layout admin-page-container-height">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLayout;
