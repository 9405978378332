import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { Category, CategoryFormData } from "./category.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const CategoryApi = createApi({
	reducerPath: "categoryApi",
	tagTypes: ["category"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCategories: builder.query<PaginationResults<Category>, TypeQuery>({
			providesTags: ["category"],
			query: (query) => {
				return QueryUrl("category/", query);
			},
		}),
		getCategoriesVisitors: builder.query<Category[], void>({
			providesTags: ["category"],
			query: () => "categorybyvisitor",
			transformResponse: ({ results }) => results,
		}),
		getCategoriesWithBooks: builder.query<Category[], void>({
			providesTags: ["category"],
			query: () => "categorie_with_books",
		}),
		addOrEditCategory: builder.mutation<Category, { slug?: string; data: CategoryFormData }>({
			invalidatesTags: ["category"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `category/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `category/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCategory: builder.mutation<Category, string>({
			query: (slug) => ({
				url: `category/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["category"],
		}),
	}),
});

export const {
	useAddOrEditCategoryMutation,
	useGetCategoriesQuery,
	useDeleteCategoryMutation,
	useGetCategoriesVisitorsQuery,
	useGetCategoriesWithBooksQuery,
} = CategoryApi;
