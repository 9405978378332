import React, { useState } from "react";
import { AlertInfo } from "../../../../common/Alert";
import { useAppSelector } from "../../../../../redux/hooks";
import { createMarkup, formatDate, formatMontant, isAutheur, isValid } from "../../../../../utils/Utils";
import moment from "moment";
import { IOffer } from "../../../../../utils/api/offer/offer.type";
import { FiZap } from "react-icons/fi";
import { IUser } from "../../../../../utils/api/user/user.type";
import SubscribtionModal from "../../modals/SubscribtionModal";

const OfferInfos = ({ item }: { item: IOffer }) => {
	return (
		<div className="content-col-abonnement-home-page">
			{/* <div className="content-icon-head flex-c mb-3">
				<div className="icon-head-abonnement">
					<FiZap />
				</div>
			</div>
			<p className="subtitle-abonnement-item">{item?.title}</p> */}
			<h2 className="title-abonnement-card offer-price">
				{formatMontant(item?.price)}/ mois{" "}
			</h2>
			<p className="text-describe-abonnement-card">{item?.offer_text}</p>
			<div className="abonnement-list-content">
				<div
					className="list-offer"
					dangerouslySetInnerHTML={createMarkup(item?.description)}
				/>
			</div>
		</div>
	);
};

function SubscriptionUser({ user }: { user: IUser }) {
	// const { user: userInfos } = useAppSelector((s) => s?.user);
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	return (
		<div className="about-me-component">
			{!!user?.last_subscription && (
				<div className="row row-about-me">
					<div className="col-md-4 col-about-me mb-3">
						<div className="content-col-about-me mb-3">
							<h3 className="title-col-left-about-me py-3">Abonnement</h3>
							<div className="d-block px-2">
								<div className="user-offer-infos">
									<span>Offre:</span>
									<p> {user?.last_subscription?.offer?.title} </p>
								</div>
								<div className="user-offer-infos">
									<span>Date de début:</span>
									<p> {formatDate(user?.last_subscription?.date_debut)} </p>
								</div>
							</div>

							<div className="content-footer-col-left-about-me b-t-1 p-2 mb-2 mt-5 user-offer-expired-date">
								<p className="text-footer-col-left-about-me">
									Expire le{" "}
									{moment(user?.last_subscription?.date_fin).format(
										"DD MMMM YYYY"
									)}
								</p>
								{isAutheur(user) && !isValid(user) && (
								<div className="text-end">
									<button
										className="btn btn-page-title-admin"
										onClick={() => handleShow()}
									>
										Réabonner
									</button>
								</div>
							)}
							</div>
						</div>
					</div>
					<div className="col-md-8 col-about-me mb-3">
						<div className="content-col-about-me p-3">
							<h2 className="title-col-right-about-me">Informations sur mon offre</h2>
							<div className="content-oeuvres-about-me my-3">
								<OfferInfos item={user?.last_subscription?.offer} />
							</div>
						</div>
					</div>
				</div>
			)}
			{!user?.last_subscription && (
				<div className="row">
					<div className="col-md-12">
						<AlertInfo message="Aucun abonnement disponible" />
					</div>
				</div>
			)}
			<SubscribtionModal {...{ show, setShow }} />
		</div>
	);
}

export default SubscriptionUser;
