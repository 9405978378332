import React, { useEffect } from "react";
import { BtnSubmit } from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import { cleannerError, closeModal } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import {
	CommandeText,
	CommandeTextFormData,
} from "../../../../../utils/api/commandeText/commandeText.type";
import { useAddOrEditCommandeTextMutation } from "../../../../../utils/api/commandeText/commandeText.api";
import { useForm } from "react-hook-form";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import { Status } from "../../Support/modal/ChangeStatusMessageModal";

function ChangeStatusCommandeText({ item, modalId }: { item: CommandeText; modalId: string }) {
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("Status"),
	});
	const [changeStatus, { isSuccess, isError, error, isLoading }] =
		useAddOrEditCommandeTextMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<CommandeTextFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
            setValue("type_oeuvre",item?.type_oeuvre?.id)
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Le statut de la commande a été envoyé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				// onHide("ChangeStatusMessageModal");
				closeModal(modalId);
			});
		}
		const err = error as any;
		if (isError) {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: `${
					err
						? err?.data?.message
						: `Une erreur de statut ${err?.status || "inconnue"} est survenue`
				}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: CommandeTextFormData) => {
		console.log(data);
		changeStatus(data);
	};
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby={modalId + "Label"}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title " id="ChangeStatusMessageModal">
							Changer Status
						</h5>
						<CloseModalBtn onClick={() => closeModal(modalId)} />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut
											<span className="text-danger">*</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("status")}
										>
											<option value="">Choisir un statut</option>
											{Status?.map((option) => (
												<option value={option?.value} key={option.value}>
													{option?.label}
												</option>
											))}
										</select>
										{errors?.status && (
											<ErrorMessage message={errors?.status?.message} />
										)}
									</div>
								</div>
							</div>
							<div className="text-center mb-4">
								<BtnSubmit label="Changer" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusCommandeText;
