export enum conditionType {
	cgu = "cgu",
	cgv = "cgv",
	pc = "pc",
}
export interface ICondition {
	slug: string;
	title: string;
	description: string;
	type: conditionType;
	created_by: number;
}

export type ConditionFormData = {
	title: string;
	description: string;
	type: conditionType;
	created_by: number;
};

export type ConditionUpdateFormData = ConditionFormData & {
	slug: ICondition["slug"];
};
