import React from "react";
import ReactQuill from "react-quill";
import { IOffer, OfferOptionType } from "../../../../../utils/api/offer/offer.type";
import { useLocationState } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import { FormError, InputAdmin, RequiredText } from "../../../../common/Input";
import { SelectInput } from "../../../../common/SelectInput";
import useCrudOffer from "../hooks/useCrudOffer";

const options = [
	{
		label: "Mensuel",
		value: OfferOptionType.mensuel,
	},
	{
		label: "Annuel",
		value: OfferOptionType.annuel,
	},
];

const AddOffer = () => {
	const item = useLocationState<IOffer>(undefined);
	const { register, onSubmit, errors, isLoading, advantages, handleChange } = useCrudOffer(item);
	return (
		<div className="offers-tabs">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">{item ? "Modifier l'" : "Ajouter une"} offre</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<div className="container-form-admin mt-5">
					<form onSubmit={onSubmit}>
						<div className="row row-form-admin">
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									<InputAdmin
										id="titre"
										label="Titre"
										placeholder="Titre"
										type="text"
										required
										{...register("title")}
										error={errors?.title}
									/>
								</div>
							</div>
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									<InputAdmin
										id="price"
										label="Prix"
										placeholder="Prix"
										type="number"
										required
										{...register("price")}
										error={errors?.price}
									/>
								</div>
							</div>
							<div className="col-md-6  col-form-admin">
								<div className="mb-3">
									<SelectInput
										label="Format offre"
										options={options}
										error={errors?.frequence}
										{...register("frequence")}
									/>
								</div>
							</div>
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									<InputAdmin
										label="Durée"
										type="number"
										required
										placeholder="Durée"
										{...register("duration")}
										error={errors?.duration}
									/>
								</div>
							</div>
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									<InputAdmin
										label="Position"
										type="number"
										required
										placeholder="Position"
										{...register("position")}
										error={errors?.position}
									/>
								</div>
							</div>
							<div className="col-md-6 col-form-admin mb-3">
								<div className="content-form-admin">
									<InputAdmin
										label="Description"
										type="text"
										required
										placeholder="Description"
										{...register("offer_text")}
										error={errors?.offer_text}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<label htmlFor="advantages" className="form-label form-label-admin">
									Lister les avantages
									<RequiredText />
								</label>
								<ReactQuill
									theme="snow"
									value={advantages}
									onChange={handleChange}
								/>
								<FormError error={errors?.description} />
							</div>
							<div className="col-md-12 d-flex justify-content-end mb-3 pt-4">
								<div className="content-btn-form-admin">
									<BtnSubmit label="Enregistrer" isLoading={isLoading} />
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddOffer;
