import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetUserFollowersQuery } from "../../../../../utils/api/user/user.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { useItem, usePagination } from "../../../../../utils/hooks";
import { formatFollowerNumber, getAvatar, getBanner, getName } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { useFollowAuthor } from "../../../Utilisateur/Autheurs/hooks/useAuthor";
import { Toaster } from "react-hot-toast";

const Abonnement = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { limit, page } = usePagination(12);
	const { data, isLoading } = useGetUserFollowersQuery({ slug: user?.slug, limit, page });
	const { item, getItem } = useItem<IUser>();
	const { handleToggleFollow } = useFollowAuthor(item);
	const handleUnfollow = (item: IUser) => {
		getItem(item);
		if (item) {
			handleToggleFollow();
		}
	};
	return (
		<div className="abonnement-component p-3">
			<div className="row row-abonnement-tabs">
				{!isLoading ? (
					data && data?.results?.length > 0 ? (
						<>
							{data?.results.map((item) => (
								<div
									className="col-xl-3 col-lg-4 col-md-6 col-abonnement-tabs d-flex mb-3"
									key={item?.slug}
								>
									<div className="card content-col-abonnement-tabs w-100 position-relative">
										<img
											src={getBanner(item?.banniere)}
											className="card-img-top card-img-top-abonnement-top"
											alt="Abonnement"
										/>
										<div className="content-img-pp-abonnement-tabs">
											<img
												src={getAvatar(item?.avatar)}
												alt="Profil"
												className="img-pp-abonnement-tabs border-avatar"
											/>
										</div>
										<div className="card-body">
											<h5 className="card-title card-title-abonnement">
												{getName(item)}
											</h5>
											<div className="text-center content-btn-desabonnement py-3">
												<button
													className="btn btn-desabonnement"
													onClick={() => handleUnfollow(item)}
												>
													Se désabonner
												</button>
											</div>
											<div className="container-info-oeuvres-about-me mb-2 flex-sb">
												<div className="info-oeuvres-about-me-item">
													<p className="text-info-oeuvres-about-me-item mb-0">
														{formatFollowerNumber(
															item?.published_books_count
														)}
														<span style={{ fontSize: 8 }}>oeuvres</span>
													</p>
												</div>
												<div className="info-oeuvres-about-me-item">
													<p className="text-info-oeuvres-about-me-item mb-0">
														{formatFollowerNumber(
															item?.subscribers?.length
														)}
														<span style={{ fontSize: 8 }}>abonnés</span>
													</p>
												</div>
												<div className="info-oeuvres-about-me-item">
													<p className="text-info-oeuvres-about-me-item mb-0">
														14k
														<span className="ps-1">
															<AiFillHeart />
														</span>{" "}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</>
					) : (
						<AlertInfo message="Aucun abonnement trouvé" />
					)
				) : (
					<></>
				)}
			</div>
			<Toaster />
		</div>
	);
};

export default Abonnement;
