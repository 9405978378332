export enum OfferOptionType {
	mensuel = "mensuel",
	annuel = "annuel",
}
export interface IOffer {
	id: number;
	slug: string;
	title: string;
	price: number;
	description: string;
	duration: number;
	created_at: string;
	position: number;
	frequence: OfferOptionType;
	offer_text: string;
}

export type OfferFormData = Partial<IOffer>;
