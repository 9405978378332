/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import "./SupportManager.css";
import { AlertInfo } from "../../../common/Alert";
import { useGetContactMessagesQuery } from "../../../../utils/api/contact/contact.api";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { createMarkup, showModal } from "../../../../utils/Utils";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { Contact } from "../../../../utils/api/contact/contact.type";
import DetailsMessageModal from "./modal/DetailsMessageModal";
import ChangeStatusMessageModal from "./modal/ChangeStatusMessageModal";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { hasAccess } from "../../../../routes/routerUtils";

const SupportItem = ({ item }: { item: Contact }) => {
	return (
		<>
			<div className="mb-3" key={item?.slug}>
				<div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
					<div className="row w-100 align-items-center">
						<div className="col-md-7">
							<div
								className="admin-message-contact-card-description d-flex btn p-0"
								data-bs-toggle="modal"
								data-bs-target={`#DetailsMessageModal${item?.slug}`}
								role="button"
								dangerouslySetInnerHTML={createMarkup(item?.subject)}
								// onClick={() => setItem(item)}
							/>
							<p className="admin-message-contact-card-frequence fz-14 mb-0">
								{moment(item?.created_at).format("DD/MM/YYYY, h:mm:ss")}
							</p>
						</div>
						<div className="col-md-2">
							<button
								className={`btn message-btn ${
									item?.status === "new"
										? "bg-status"
										: item?.status === "en_cours"
										? "bg-status-secondary"
										: "bg-success"
								}`}
								aria-expanded="false"
								onClick={() => showModal(`changeStatus${item?.slug}`)}
							>
								{item?.status === "new"
									? "Nouveau"
									: item?.status === "traité"
									? "Traité"
									: "En cours"}
							</button>
						</div>
						<div className="col-md-3 flex-r">
							<div className="text-center">
								<p className="admin-message-contact-card-description mb-0">
									{item?.name}
								</p>
								<p className="admin-message-contact-card-frequence fz-14 mb-0">
									{item?.email}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id={`DetailsMessageModal${item?.slug}`}
				aria-labelledby={`DetailsMessageModal${item?.slug}Label`}
				aria-hidden="true"
			>
				<DetailsMessageModal message={item} />
			</div>
			<ChangeStatusMessageModal item={item} modalId={`changeStatus${item?.slug}`} />
		</>
	);
};

function SupportManager() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetContactMessagesQuery({
		page,
		limit: perPage,
	});
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "support")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="dashboard-admin-component">
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="goana-dash-header">
						<h4 className="goana-dash-header-titre">Demandes de support</h4>
					</div>
					<div className="bg-white p-3 mt-4 filter-and-table-container">
						<div className="container-coolapse-questions mt-5">
							{/* Contenu */}
							<div className="mx-2 mt-5">
								{!isLoading &&
									(data?.results?.length ? (
										data?.results?.map((item) => (
											<SupportItem item={item} key={item?.slug} />
										))
									) : (
										<AlertInfo message="Aucun message disponible pour le moment" />
									))}
								{!!isLoading &&
									[...Array(5)]?.map((item, i) => (
										<MessageContactItemSkeleton key={i} />
									))}
							</div>
							<CustomPagination
								nbPages={data?.count}
								page={page}
								perPage={perPage}
								onChange={setPage}
							/>
						</div>

						{/* <ChangeStatusMessageModal item={item} /> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SupportManager;
function MessageContactItemSkeleton() {
	return (
		<div className="mb-3">
			<div className="px-2 py-4 admin-message-contact-card mb-2 flex-m gap-1">
				<div className="row w-100 align-items-center">
					<div className="col-md-7">
						<Skeleton width={`100%`} height={25} />
						<Skeleton width={`50%`} height={15} />
					</div>
					<div className="col-md-2 text-center">
						<Skeleton width={`80%`} height={25} />
					</div>
					<div className="col-md-3 text-end">
						<Skeleton width={`90%`} height={25} />
					</div>
				</div>
			</div>
		</div>
	);
}
