import React from "react";
import { NavLink } from "react-router-dom";
import "./FrontHeader.css";
import { useAppSelector } from "../../../redux/hooks";
import { BsChevronDown } from "react-icons/bs";
import Logo from "./Logo";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function HeaderButton({ user }: { user: any }) {
	return (
		<>
			<div className="login-btn-container">
				<NavLink to="/connexion" className="btn btn-default btn-login-connect">
					{!!user ? user?.first_name + " " + user?.last_name : "Se connecter"}
				</NavLink>
			</div>
			{user?.user_type !== "author" && (
				<div className="login-btn-container">
					<NavLink
						to={"/devenir-autheur"}
						className="btn btn-default btn-theme-secondary btn-orange"
					>
						Soumettre un ouvrage
					</NavLink>
				</div>
			)}
		</>
	);
}

const FrontHeader = () => {
	const { user } = useAppSelector((s) => s?.user);
	return (
		<div className="frontheader-component">
			<nav className="navbar navbar-expand-lg navbar-light headering nav-header-custom fixed-top py-1">
				<div className="container-fluid header-container-fluid pb-0">
					<div className="logo-and-version-container">
						<div className="logo-container">
							<NavLink className="navbar-brand content-logo-app" to="/">
								<Logo />
							</NavLink>
						</div>
					</div>
					<button
						className="navbar-toggler content-icon-menu-mobile"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse header-navbar-collapse" id="navbarNav">
						<div className="w-100 header-items-container">
							<div className="w-100 header-navbar-contents-container">
								<ul className="navbar-nav navbar-nav-header me-auto">
									<li className="nav-item">
										<NavLink
											className="nav-link menu-link"
											aria-current="page"
											to="/qui-sommes-nous"
										>
											Qui sommes-nous
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="nav-link menu-link" to="/faq">
											FAQ
										</NavLink>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle menu-link"
											href="#"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Services
											<BsChevronDown
												color="#141b2d"
												fontWeight={600}
												fontSize={18}
												className="ms-2"
											/>
										</a>
										<ul className="menu-dropdown dropdown-menu">
											<li>
												<NavLink
													className="dropdown-item menu_dropdown-item"
													to="/services#orderText"
												>
													Commander un texte
												</NavLink>
											</li>
											<li>
												<NavLink
													className="dropdown-item menu_dropdown-item"
													to="/services#getDevis"
												>
													Demander un devis
												</NavLink>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										<NavLink className="nav-link menu-link" to="/blog">
											Blog
										</NavLink>
									</li>

									<li className="nav-item no-view-desktop">
										<div className="d-flex flex-column gap-2">
											<HeaderButton user={user} />
										</div>
									</li>
								</ul>
								<div className="bloc-flex-info-user-header-content">
									<div className="bloc-flex-info-user-header no-view-mobile">
										<HeaderButton user={user} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default FrontHeader;
