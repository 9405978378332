import React from "react";
import { BsArrowReturnRight, BsThreeDotsVertical } from "react-icons/bs";
import { RxDoubleArrowDown } from "react-icons/rx";
import { FiMessageSquare } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useCrudComment, { useCrudResponseComment } from "./hooks/useCrudComment";
import { FormError } from "../../../common/Input";
import { CircleSpinner } from "../../../common/Button";
import { ICommentForum, IForum } from "../../../../utils/api/forum/forum.type";
import { getAvatar, getName } from "../../../../utils/Utils";
import moment from "moment";
import { useGetResponsesByCommentQuery } from "../../../../utils/api/forum/forum.api";

export function ResponseCommentItem({ item }: { item: ICommentForum }) {
	return (
		<div className="response-comment-item">
			<hr />
			<div className="communaite-article-item-avatar-icone-container mb-2">
				<div className="communaite-article-item-avatar-container">
					<div className="communaite-article-item-container-avatar">
						<img
							src={getAvatar(item?.created_by?.avatar)}
							alt="photos profile de l' utilisateur"
							className="communaite-article-item-avatar-small"
						/>
					</div>
					<div className="communaite-article-item-username-container">
						<div className="communaite-article-item-username">
							<NavLink to={"/utilisateur/user-profil"} className="no-link">
								{getName(item?.created_by)}
							</NavLink>
						</div>
						<div className="communaite-article-item-time">
							{moment(item.created_at).format("DD MMMM YYYY HH:MM")}
						</div>
					</div>
				</div>
				<div className="communaite-article-item-icone-container">
					<BsThreeDotsVertical className="communaite-article-item-icone" />
				</div>
			</div>
			<div className="communaite-article-item-titre-container">
				<p className="communaite-article-item-paragraphe">{item.response}</p>
			</div>
		</div>
	);
}

export function FormResponseComment({ item }: { item: ICommentForum }) {
	const { register, onSubmit, errors, isLoading } = useCrudResponseComment(item);
	return (
		<div className="details-discussion-form-commentaire-container mb-3 p-3">
			<form onSubmit={onSubmit}>
				<div className="mb-3">
					<textarea
						className="form-control form-control-utilisateur"
						rows={1}
						placeholder="Réponse"
						{...register("response")}
					/>
					<FormError error={errors?.response} />
				</div>
				<div className="details-discussion-form-commentaire-btn-container">
					{!isLoading ? (
						<button className="btn details-discussion-form-commentaire-btn-commenter">
							<FiMessageSquare /> Répondre
						</button>
					) : (
						<button
							type="button"
							className="btn details-discussion-form-commentaire-btn-commenter"
						>
							<CircleSpinner />
							<span>Chargement...</span>
						</button>
					)}
				</div>
			</form>
		</div>
	);
}

export function FormCommentaire({ item }: { item: IForum }) {
	const { register, onSubmit, errors, isLoading } = useCrudComment(item);
	return (
		<div className="details-discussion-form-commentaire-container mb-3">
			<form onSubmit={onSubmit}>
				<div className="mb-3">
					<textarea
						className="form-control form-control-utilisateur"
						rows={1}
						placeholder="Commentaire"
						{...register("response")}
					/>
					<FormError error={errors?.response} />
				</div>
				<div className="details-discussion-form-commentaire-btn-container">
					<button
						className="btn details-discussion-form-commentaire-btn-annuler"
						type="reset"
					>
						Annuler
					</button>
					{!isLoading ? (
						<button className="btn details-discussion-form-commentaire-btn-commenter">
							<FiMessageSquare /> Commenter
						</button>
					) : (
						<button
							type="button"
							className="btn details-discussion-form-commentaire-btn-commenter"
						>
							<CircleSpinner />
							<span>Chargement...</span>
						</button>
					)}
				</div>
			</form>
		</div>
	);
}

export function CommentaireItem({ item }: { item: ICommentForum }) {
	const { data, isLoading } = useGetResponsesByCommentQuery({ slug: item?.slug });
	return (
		<div className="communaite-article-item-container custom-side-color mb-3">
			<div className="communaite-article-item-avatar-icone-container mb-3">
				<div className="communaite-article-item-avatar-container">
					<div className="communaite-article-item-container-avatar">
						<img
							src={getAvatar(item?.created_by?.avatar)}
							alt="photos profile de l' utilisateur"
							className="communaite-article-item-avatar-small"
						/>
					</div>
					<div className="communaite-article-item-username-container">
						<div className="communaite-article-item-username">
							<NavLink to={"/utilisateur/user-profil"} className="no-link">
								{getName(item?.created_by)}
							</NavLink>
						</div>
						<div className="communaite-article-item-time">
							{moment(item.created_at).format("DD MMMM YYYY HH:MM")}
						</div>
					</div>
				</div>
				<div className="communaite-article-item-icone-container">
					<BsThreeDotsVertical className="communaite-article-item-icone" />
				</div>
			</div>
			<div className="communaite-article-item-titre-container">
				<p className="communaite-article-item-paragraphe">{item.response}</p>
			</div>
			<hr />
			<div className="communaite-article-item-tags-stats-container">
				{/* <div className="communaite-article-item-tags-container">
					<div className="communaite-article-item-stats">
						<FiThumbsUp /> {item.likes?.length}
					</div>
				</div> */}
				<div className="communaite-article-item-stats-container">
					<button
						className="details-commentaire-btn-voir-toute"
						data-bs-toggle="collapse"
						type="button"
						data-bs-target={`#collapseListResponseComment${item?.slug}`}
						aria-expanded="false"
						aria-controls="collapseListResponseComment"
					>
						<RxDoubleArrowDown /> Voir toutes les réponses ({data?.count})
					</button>
					<button
						className="details-commentaire-btn-voir-toute"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target={`#collapseResponseComment${item?.slug}`}
						aria-expanded="false"
						aria-controls="collapseResponseComment"
					>
						<BsArrowReturnRight /> Répondre
					</button>
				</div>
			</div>
			<div className="collapse" id={`collapseResponseComment${item?.slug}`}>
				<FormResponseComment item={item} />
			</div>
			<div className="collapse" id={`collapseListResponseComment${item?.slug}`}>
				{!isLoading &&
					data &&
					data?.results?.length > 0 &&
					data?.results?.map((item) => (
						<ResponseCommentItem key={item.slug} item={item} />
					))}
			</div>
		</div>
	);
}
