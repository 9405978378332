import moment from "moment";
import { Chat } from "../../../../utils/api/chat/chat.type";

export function MessageReceiveItem({ item }: { item: Chat }) {
	return (
		<div className="position-relative received-msg-item">
			<div className="bloc-message-recu p-3">
				<span className="text-message-recu">{item?.message}</span>
			</div>
			<div className="d-flex align-items-center pb-2">
				<span className="time-detail-message ps-3">
					{moment(item?.created_at).calendar()}
				</span>
			</div>
		</div>
	);
}

export function MessageSenderItem({ item }: { item: Chat }) {
	return (
		<div className="bloc-reponse">
			<div className="position-relative sending-msg-item">
				<div className="bloc-message-envoyer p-3">
					<span className="text-message-envoyer">{item?.message}</span>
				</div>
				<div className="">
					<div className="d-flex justify-content-end align-items-center">
						<span className="time-detail-message px-2">
							{moment(item?.created_at).calendar()}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
