import React from "react";
import Logo from "../../assets/appImages/logo.png";
import ImgLogin from "../../assets/appImages/login-img.png";
import ErrorMessage from "../common/ErrorMessage";
import { UseResetPasswordForm } from "./useAuthForm/useResetPasswordForm";

const ResetPassword = () => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		passwordHandleChange,
		haveMinCharacter,
		haveMinLowercase,
		haveMinNumber,
		haveMinSpecialCharacter,
		haveMinUppercase,
		PasswordInput,
	} = UseResetPasswordForm();
	return (
		<div>
			<div className="container-page-login-yeewuna">
				<div className="auth-row row">
					<div className="col-md-6 auth-col auth-left-side-col d-flex">
						<div className="auth-left-side-container w-100">
							<img src={ImgLogin} alt="connexion" className="img-login" />
						</div>
					</div>
					<div className="col-md-6 auth-col auth-right-side-col d-flex">
						<div className="auth-right-side-container w-100">
							<div className="auth-form-container">
								<div className="container-img-logo pb-4 text-center">
									<img src={Logo} alt="Logo" className="img-logo-login" />
								</div>
								<h1 className="auth-form-title">Changer le mot de passe</h1>
								<p className="text-message-login">Crée un nouveau mot de passe</p>
								<div className="mt-4">
									<form id="auth-form" onSubmit={onSubmit}>
										<div className="row auth-form-row">
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label
														className="form-label form-label-auth-login"
														htmlFor="code"
													>
														Code reçu
													</label>
													<input
														type={`text`}
														className="form-control auth-form-control"
														id="code"
														placeholder="Entrez le code reçu par email"
														{...register("code")}
													/>
													{errors?.code && (
														<ErrorMessage
															message={errors?.code?.message}
														/>
													)}
												</div>
											</div>
											<div className="col-md-12 auth-input-col mb-3">
												<div className="auth-form-group">
													<label
														className="form-label form-label-auth-login"
														htmlFor="password"
													>
														Mot de passe
													</label>
													<input
														type={`password`}
														className="form-control auth-form-control"
														id="password"
														placeholder="Entrez un nouveau mot de passe"
														onChange={passwordHandleChange}
													/>
													{errors?.new_password && (
														<ErrorMessage
															message={errors?.new_password?.message}
														/>
													)}
												</div>
											</div>
											{PasswordInput && (
												<div className="col-md-12">
													<div className="input-group mb-2 password-validation-terms-row">
														<div className="password-validation-terms-container">
															<h4 className="password-validation-terms-title">
																Votre mot de passe doit contenir :{" "}
															</h4>
															<ul className="password-validation-terms-ul p-l-14">
																<li
																	className={
																		"password-rule-item " +
																		(haveMinCharacter &&
																			"text-success")
																	}
																>
																	Au minimum 8 caractères
																</li>
																<li
																	className={
																		"password-rule-item " +
																		(haveMinUppercase &&
																			"text-success")
																	}
																>
																	Au minimum 1 caractère en
																	majuscule
																</li>
																<li
																	className={
																		"password-rule-item " +
																		(haveMinLowercase &&
																			"text-success")
																	}
																>
																	Au minimum 1 caractère en
																	minuscule
																</li>
																<li
																	className={
																		"password-rule-item " +
																		(haveMinNumber &&
																			"text-success")
																	}
																>
																	Au minimum 1 nombre
																</li>
																<li
																	className={
																		"password-rule-item " +
																		(haveMinSpecialCharacter &&
																			"text-success")
																	}
																>
																	Au minimum 1 caractère spéciale
																</li>
															</ul>
														</div>
													</div>
												</div>
											)}
											<div className="col-md-12 auth-input-col">
												<div className="auth-form-group">
													<label
														htmlFor="confirm-new-password"
														aria-labelledby="confirm-password"
														className="form-label form-label-auth-login"
													>
														Confirmez le mot de passe
													</label>
													<input
														type="password"
														className="form-control auth-form-control"
														id="confirm-new-password"
														placeholder="Confirmez le mot de passe"
														{...register("new_password_confirm")}
													/>
													{errors?.new_password_confirm && (
														<ErrorMessage
															message={
																errors?.new_password_confirm
																	?.message
															}
														/>
													)}
												</div>
											</div>
											<div className="col-md-4 offset-md-4 auth-submit-btn-container mt-4">
												{!isLoading ? (
													<button
														className="btn auth-submit-btn trans-0-2 w-100"
														type="submit"
														disabled={isLoading}
													>
														<span className="pe-2">réinitialiser</span>
													</button>
												) : (
													<button
														type="submit"
														className="btn auth-submit-btn trans-0-2 w-100"
													>
														<span
															className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
															role="status"
														></span>
														<span>Chargement...</span>
													</button>
												)}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
