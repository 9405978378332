import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Contact, TEditContactForm } from "../../../../../utils/api/contact/contact.type";
import { useUpdateContactMutation } from "../../../../../utils/api/contact/contact.api";
import { cleannerError, closeModal } from "../../../../../utils/Utils";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import ErrorMessage from "../../../../common/ErrorMessage";
import { BtnSubmit } from "../../../../common/Button";
import { Color } from "../../../../../utils/theme";

export const Status = [
	{
		value: "new",
		label: "Nouveau",
	},
	{
		value: "en_cours",
		label: "En cours",
	},
	{
		value: "traité",
		label: "Traiter",
	},
];
function ChangeStatusMessageModal({ item, modalId }: { item: Contact; modalId: string }) {
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("Status"),
	});
	const [changeStatus, { isSuccess, isError, error, isLoading }] = useUpdateContactMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<TEditContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
			setValue("message", item?.message);
			setValue("subject", item?.subject);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Statut du message changé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				// onHide("ChangeStatusMessageModal");
				closeModal(modalId);
			});
		}
		const err = error as any;
		if (isError) {
			console.log(err);
			Swal.fire({
				icon: "error",
				title: `${
					err
						? err?.data?.message
						: `Une erreur de statut ${err?.status || "inconnue"} est survenue`
				}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: TEditContactForm) => {
		console.log(data);
		changeStatus(data);
	};
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby={modalId + "Label"}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title "
							id="ChangeStatusMessageModal"
						>
							Changer Status
						</h5>
						<CloseModalBtn onClick={() => closeModal(modalId)} />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut
											<span className="text-danger">*</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("status")}
										>
											<option value="">Choisir un statut</option>
											{Status?.map((option) => (
												<option value={option?.value} key={option.value}>
													{option?.label}
												</option>
											))}
										</select>
										{errors?.status && (
											<ErrorMessage message={errors?.status?.message} />
										)}
									</div>
								</div>
							</div>
							<div className="text-center mb-4">
								<BtnSubmit label="Changer" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusMessageModal;
