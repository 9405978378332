import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";

import { QueryUrl, useLocationState } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Order, OrderFormData } from "./order.type";

const tags = {
	orderByUser: "orderByUser",
	orders: "orders",
	orderBySlug: "orderBySlug",
	orderByCommerce: "orderByCommerce",
};
export const OrderApi = createApi({
	reducerPath: "orderAPi",
	tagTypes: Object.values(tags),
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getOrders: builder.query<PaginationResults<Order>, TypeQuery>({
			query: (query) => QueryUrl("/order/", query),
			providesTags: [tags.orders],
		}),
		OrderBySlug: builder.query<Order, string>({
			query: (slug) => `order/${slug}/`,
			providesTags: [tags.orderBySlug],
		}),
		createOrder: builder.mutation<Order, OrderFormData>({
			invalidatesTags: ["orders"],
			query: (data) => {
				return {
					url: `create/order/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const { useLazyOrderBySlugQuery, useCreateOrderMutation } = OrderApi;

export function useOrderFromLocation(): [Order, boolean, string, (slug: string) => void] {
	const itemState = useLocationState<Order | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyOrderBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		} else {
			console.log("slug", itemState);
		}
	}, [itemState]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			console.log("slug", data);
			setItem(data);
		}
	}, [data]);

	return [item as Order, isLoading, slug as string, findBySlug];
}
