import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useGetCategoriesVisitorsQuery } from "../../../../utils/api/category/category.api";
import { IBecomeTraderProps } from "../../../../utils/api/user/user.type";
import { accept } from "../../../../utils/type";
import { GoBackButton, BtnSubmit } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import { PreviewImage } from "../../../common/PreviewImage";
import { SelectInput } from "../../../common/SelectInput";
import VideoModal from "../Modals/VideoModal";

const AjoutOuvrage = (props: IBecomeTraderProps) => {
	const {
		register,
		errors,
		onSubmit,
		description,
		handleChangeDescription,
		bookCover,
		handleSelectFiles,
	} = props;
	const { data } = useGetCategoriesVisitorsQuery();
	const [show, setShow] = useState(false);

	const openModal = () => {
		setShow(!show);
	};

	return (
		<div className="yeewuna-devenir-autheur-col-right-form-content">
			<div className="yeewuna-devenir-autheur-col-right-form-titre mb-4">
				Ajouter votre ouvrage
			</div>
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="text"
								label="Nom du livre"
								id="nom"
								placeholder="Nom du livre"
								required
								{...register("book_name")}
								error={errors?.book_name}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="text"
								label="ISBN"
								id="nom"
								placeholder="ISBN"
								required
								{...register("isbn")}
								error={errors?.isbn}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="text"
								label="Prix"
								id="prix"
								placeholder="Prix"
								required
								{...register("book_price")}
								error={errors?.book_price}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<SelectInput
								label="Catégories"
								options={data?.map((item) => {
									return {
										label: item?.name,
										value: item?.id,
									};
								})}
								required
								{...register("book_category")}
								error={errors?.book_category}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="file"
								label="Ajouter le livre (format pdf)"
								id="livre"
								placeholder="Livre"
								required
								customClass="custom-input-file-author"
								error={errors?.book_pdf}
								name="book_pdf"
								onChange={handleSelectFiles}
								accept={accept.pdf}
							/>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="file"
								label="Ajouter l'ebook (format epub)"
								id="epub"
								placeholder="Ebook"
								required
								customClass="custom-input-file-author"
								error={errors?.epub}
								name="epub"
								onChange={handleSelectFiles}
								accept={accept.epub}
							/>
							<small className="tuto-text" onClick={() => openModal()}>
								Comment convertir un fichier <span>DOC</span> en <span>EPUB</span>{" "}
								avec <span>Google Docs</span>?
							</small>
						</div>
					</div>
					<div className="col-md-12">
						<div className="mb-4">
							<Input
								type="file"
								label="Photo couverture"
								id="couverture"
								placeholder="Photo couverture"
								required
								customClass="custom-input-file-author"
								error={errors?.book_image}
								name="book_image"
								onChange={handleSelectFiles}
								accept={accept.image}
							/>
							<PreviewImage image={bookCover} />
						</div>
					</div>
					<div className="col-12 mb-4">
						<label htmlFor="desc" className="form-label form-label-modal-custom">
							Description
						</label>
						<ReactQuill
							theme="snow"
							value={description}
							onChange={handleChangeDescription}
							className="description-book"
						/>
						<FormError error={errors?.description} />
					</div>
					<div className="col-md-12">
						<div className="yeewuna-devenir-autheur-btn-action-container flex-r">
							<GoBackButton label="Retour" />
							<BtnSubmit label="Suivant" isLoading={false} />
						</div>
					</div>
				</div>
			</form>
			<VideoModal show={show} setShow={setShow} />
		</div>
	);
};

export default AjoutOuvrage;
