import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ConditionFormData, ICondition } from "./condition.type";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";

const tags = {
	cnds: "configList",
	cgu: "cgu",
	pc: "pc",
};

export const ConditionApi = createApi({
	reducerPath: "configApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	tagTypes: [tags.cnds],
	endpoints: (build) => ({
		getConditions: build.query<ICondition[], void>({
			query: () => `config/`,
			transformResponse: ({ results }) => results,
			providesTags: [tags.cnds,tags.cgu,tags.pc],
		}),
		addOrEditCondition: build.mutation<ICondition, { slug?: string; data: ConditionFormData }>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `config/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `config/`,
						method: "POST",
						body: data,
					};
				}
			},
			invalidatesTags: [tags.cnds],
		}),
		getPolicy: build.query<ICondition[], void>({
			query: () => `pc/`,
			providesTags: [tags.pc],
		}),
		getCgu: build.query<ICondition[], void>({
			query: () => `cgu/`,
			providesTags: [tags.cgu]
		}),
		getAide: build.query<ICondition, void>({
			query: () => `aides/`,
			providesTags: [tags.cnds, tags.cgu],
			transformResponse: ({ results }) => results[0],
		}),
	}),
});

export const {
	useAddOrEditConditionMutation,
	useGetConditionsQuery,
	useGetCguQuery,
	useGetPolicyQuery,
	useGetAideQuery,
} = ConditionApi;
