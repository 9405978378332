import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { NewsletterForm } from "./newsletter.type";

export const NewsletterApi = createApi({
	tagTypes: ["newsletter"],
	reducerPath: "newsletterApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		subscribeNewsletter: builder.mutation<NewsletterForm, NewsletterForm>({
			query: (data) => ({
				url: "visitor/create_newsletter/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["newsletter"],
		}),
	}),
});

export const { useSubscribeNewsletterMutation } = NewsletterApi;
