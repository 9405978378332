import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../common/Button";
import { useDelete, useModal, usePagination } from "../../../../utils/hooks";
import { Category } from "../../../../utils/api/category/category.type";
import {
	useDeleteCategoryMutation,
	useGetCategoriesQuery,
} from "../../../../utils/api/category/category.api";
import AddCategoryModal from "./AddCategoryModal";

export function DeleteOffer({ item }: { item: Category }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCategoryMutation();
	const onDelete = useDelete<Category>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette catégorie?",
		successMessage: "Catégorie supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

function CategorieTable() {
	const { page, limit, setPage, setPerPage } = usePagination(10);
	const { isLoading, data = { results: [], count: 0 } } = useGetCategoriesQuery({
		page,
		limit,
	});

	const { isShowModal, closeModal, openModal, item, openEditModal } = useModal<Category>();

	const actionFormatter = (cell: string, row: Category) => {
		return (
			<div className="d-flex gap-3">
				<ButtonEdit onClick={() => openEditModal(row)} />
				<DeleteOffer item={row} />
			</div>
		);
	};

	const rowClasses = () => {
		return "custom-row-class-student";
	};
	const columns = [
		{
			dataField: "name",
			text: "Nom Catégorie",
			// formatter: (cell: string, row: Category) => nameFormatter(cell, row),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: Category) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<div className="content-btn-action-title-page-admin flex-r">
						<ButtonAdd label="Ajouter une catégorie" onClick={openModal} />
					</div>
					<div className="container-admin-page-table admin-table mt-4">
						<BootstrapTable
							keyField="id"
							data={data?.results}
							columns={columns}
							bordered={true}
							condensed={false}
							responsive={true}
							wrapperClasses="table-responsive admin-table"
							rowClasses={rowClasses}
							noDataIndication={() => "Aucune donnée trouvée"}
						/>
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perpage) => {
								setPage(page);
								setPerPage(perpage);
							}}
							perPage={limit}
						/>
					</div>
					<AddCategoryModal item={item} isOpen={isShowModal} closeModal={closeModal} />
				</>
			)}
		</>
	);
}

export default CategorieTable;
