import React from "react";
import ReactQuill from "react-quill";
import { Modal, ModalProps } from "reactstrap";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useFaqForm from "./hooks/useFaqForm";
import { IModalProps } from "../../../../utils/type";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import { FormError, Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type AddFaqModalProps = ModalProps & IModalProps<IFaq>;

const AddEditFaqModal: React.FC<AddFaqModalProps> = ({ isOpen, closeModal, item }) => {
	const { register, onSubmit, errors, onChangeResponse, response, isLoading } = useFaqForm(
		closeModal,
		item
	);
	return (
		<Modal isOpen={isOpen} size="lg">
			<div className="modal-content fas__modal  px-3 py-4">
				<div className="container-fluid mx-auto">
					<div className="flex-sb">
						<h4 className="fas-modal-title mb-4">Question/Réponse</h4>
						<CloseModalBtn onClick={closeModal} />
					</div>
					<form onSubmit={onSubmit}>
						<div className="col-12 mb-3">
							<Input
								label="Question"
								id="titre"
								type="text"
								placeholder="Ajouter une question"
								{...register("question")}
								error={errors?.question}
								data-testid="questionId"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="reponse" className="form-label form-label-auth-login">
								Reponse
							</label>
							<ReactQuill
								className="editor-cgu"
								value={response}
								onChange={onChangeResponse}
							/>
							<FormError error={errors?.answer} />
						</div>
						<div className="flex-r mt-5 gap-4 col-md-6 offset-md-6">
							<BtnSubmit label="Enregistrer" isLoading={isLoading} />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddEditFaqModal;
