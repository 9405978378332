import React, { useEffect } from "react";
import { useGetSocialMediaQuery } from "../../../../../utils/api/config/config.api";
import useSocialMediaForm from "../hooks/useSocialMediaForm";
import { Input } from "../../../../common/Input";
import { BtnSubmit } from "../../../../common/Button";

const SocialMedia = () => {
	const { data } = useGetSocialMediaQuery();
	const { register, onSubmit, errors, isLoading, setValue } = useSocialMediaForm();
	useEffect(() => {
		if (data) {
			setValue("slug", data?.slug);
			setValue("facebook", data?.facebook);
			setValue("linkedin", data?.linkedin);
			setValue("instagram", data?.instagram);
			setValue("email", data?.email);
			setValue("telephone", data?.telephone);
			setValue("twitter", data?.twitter);
		}
	}, [data]);
	return (
		<div className="container-title-page-admin">
			<div className="tab-content-admin px-3 py-3">
				<form onSubmit={onSubmit}>
					<div className="mt-3 row">
						<div className="col-md-6 mb-3">
							<Input
								label="Email"
								id="email"
								type="email"
								placeholder="Email"
								{...register("email")}
								error={errors?.email}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								label="Téléphone"
								id="telephone"
								type="tel"
								placeholder="Téléphone"
								{...register("telephone")}
								error={errors?.telephone}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								label="Facebook"
								id="facebook"
								type="url"
								placeholder="Link"
								{...register("facebook")}
								error={errors?.facebook}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								label="Instagram"
								id="instagram"
								type="url"
								placeholder="Link"
								{...register("instagram")}
								error={errors?.instagram}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								label="Linkedin"
								id="linkedin"
								type="url"
								placeholder="Link"
								{...register("linkedin")}
								error={errors?.linkedin}
							/>
						</div>
						<div className="col-md-6 mb-3">
							<Input
								label="Twitter"
								id="twitter"
								type="url"
								placeholder="Link"
								{...register("twitter")}
								error={errors?.twitter}
							/>
						</div>
					</div>
					<div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
						<BtnSubmit label="Modifier" isLoading={isLoading} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default SocialMedia;
