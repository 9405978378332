import React from "react";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import { closeModal, createMarkup } from "../../../../../utils/Utils";
import { CommandeText } from "../../../../../utils/api/commandeText/commandeText.type";
import { FaUser } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsBook } from "react-icons/bs";

function ComandeTextDetails({ modalId, item }: { modalId: string; item: CommandeText }) {
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby={modalId + "Label"}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title " id="ChangeStatusMessageModal">
							Commande de texte
						</h5>
						<CloseModalBtn onClick={() => closeModal(modalId)} />
					</div>
					<div className="modal-body justify-content-start w-100">
						<div className="d-flex mt-5 mb-4" style={{ gap: "10%" }}>
							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Prénom & Nom</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<FaUser />
									</a>

									<p className="text-contact-profil-admin">
										{item?.prenom + " " + item?.nom}
									</p>
								</div>
							</div>

							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Email</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<AiOutlineMail />
									</a>
									<p className="text-contact-profil-admin">{item?.email}</p>
								</div>
							</div>

							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Type d’oeuvre</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<BsBook />
									</a>
									<p className="text-contact-profil-admin">
										{item?.type_oeuvre?.name}
									</p>
								</div>
							</div>
						</div>

						<div className="text-start">
							<p className="libelle-text-contact-profil-admin">Description</p>
							<p
								className="text-pp-user-profil-admin"
								dangerouslySetInnerHTML={createMarkup(item?.description)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ComandeTextDetails;
