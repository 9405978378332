import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./GestionLivreAuteur.css";
import ListLivresAuteurTable from "./ListLivresAuteurTable/ListLivresAuteurTable";

const GestionLivreAuteur = () => {
  return (
    <div className="list-admin-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Mes livres</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/auteur/ajouter-livre"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Ajouter un livre</span>
          </NavLink>
        </div>
      </div>
      <div className="container-admin-page-table admin-table mt-4">
        <ListLivresAuteurTable />
      </div>
    </div>
  );
};

export default GestionLivreAuteur;
