/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { cleannerError, validatePhone } from "../../../utils/Utils";

export function validatePassword(Yup: any) {
	return Yup.string()
		.required()
		.matches(/[a-z]+/, "Au moins une minuscule.")
		.matches(/[A-Z]+/, "Au moins une majuscule.")
		.matches(/[!@#$%^&*(),;-_+*/.?":{}|<>]+/, "Au moins un caractère spécial")
		.matches(/\d+/, "Au moins un chiffre.")
		.min(8)
		.label("Mot de passe");
}

export type RegisterStepPropsType = {
	navigation: any;
};

function useRegisterForm() {
	const [code, setCode] = useState<string>("");
	const [phone, setPhone] = useState<string>();
	const validationSchema = yup.object().shape({
		last_name: yup.string().required().label("Nom"),
		first_name: yup.string().required().label("Prénom"),
		civilite: yup.string().label("Genre"),
		email: yup.string().email().required().label("Email"),
		phone: validatePhone(yup, code).required(),
		user_type: yup.string().label("Type d'utilisateur"),
		password: validatePassword(yup),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), undefined], "Les mots de passe ne correspond pas"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<RegisterFormData>({
		resolver: yupResolver(validationSchema),
	});

	const navigate = useNavigate();

	const [registerUser, { isLoading, isSuccess, error, isError }] = useRegisterUserMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Inscription reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/connexion", { replace: true });
			});
		}
		const err = error as any;
		if (isError) {
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur est survenue lors de l'inscription",
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isLoading]);
	const handleChangePhone = (phone: string,country: any) => {
		setValue("phone", phone);
		setCode(country?.countryCode);
		setPhone(phone);
	};
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: any) => {
		if (data?.email) {
			data["email"] = (data?.email).toLowerCase();
		}
		data["user_type"] = "reader";

		console.log("data submitted", data);
		registerUser(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		handleChangePhone,
		phone
	};
}

export const usePasswordRule = (setValue: any, field?: string) => {
	const [haveMinUppercase, setHaveMinUppercase] = useState<boolean>(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState<boolean>(false);
	const [haveMinNumber, setHaveMinNumber] = useState<boolean>(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState<boolean>(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState<boolean>(false);
	const [isShowPasswordRules, setIsShowPasswordRules] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState(false);

	const countUppercase = (str: string) => {
		if (str.replace(/[^A-Z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: string) => {
		if (str.replace(/[^a-z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: string) => {
		if (str.replace(/[^0-9]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: string) => {
		if (str.length >= 8) {
			return true;
		}
		return false;
	};
	const countSpecial = (str: string) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		if (count >= 1) {
			return true;
		}
		return false;
	};
	const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
		var password = e.currentTarget.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}
		setValue(field ? field : "password", e.currentTarget.value);
	};

	const showPasswordRule = () => {
		setIsShowPasswordRules((isShowPasswordRules) => !isShowPasswordRules);
	};
	const toggleShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};
	return {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
		showPassword,
		toggleShowPassword,
	};
};

export default useRegisterForm;
