import moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useGetArticlesQuery } from "../../../../../utils/api/article/article.api";
import { IArticle } from "../../../../../utils/api/article/article.type";
import { usePagination } from "../../../../../utils/hooks";
import { createMarkup, getImage, getName, isShowPagination } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";

export function ActuItem({ item }: { item: IArticle }) {
	return (
		<Link
			to={`/utilisateur/actualite/${item?.slug}`}
			state={item}
			className="row custom-actu-container mb-3 no-link"
		>
			<div className="col-md-4 col-xl-3">
				<div className="yeewuna-utilisateur-actualite-image-item-container">
					<img
						className="yeewuna-utilisateur-actualite-image-item"
						alt="photos actualite"
						src={getImage(item?.image)}
					/>
				</div>
			</div>
			<div className="col-md-8 col-xl-9">
				<div className="yeewuna-utilisateur-actualite-item-content-container ">
					<div>
						<h5 className="yeewuna-utilisateur-actualite-item-content-titre">
							{item?.title?.slice(0)}
						</h5>
						<div
							className="yeewuna-utilisateur-actualite-item-content-paragraphe"
							dangerouslySetInnerHTML={createMarkup(item?.content?.slice(0, 330))}
						/>
					</div>

					<div className="yeewuna-utilisateur-actualite-item-content-auteur-infos my-3">
						<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-container">
							<img
								src={getImage(item?.author?.avatar)}
								alt="photos profile de l'autheur"
								className="yeewuna-utilisateur-actualite-item-content-auteur-profile-image"
							/>
							<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile">
								<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-username">
									{getName(item?.author)}
								</div>
								<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-fonction">
									Auteur
								</div>
							</div>
						</div>
						<div className="yeewuna-utilisateur-actualite-item-content-auteur-date-publication">
							<AiOutlineClockCircle className="yeewuna-utilisateur-actualite-item-content-auteur-date-publication-icone" />
							<span>{moment(item?.created_at)?.fromNow()}</span>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

function FilActualite({ search }: { search?: string }) {
	const { limit, page, setPage, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetArticlesQuery({ limit, page, q: search });
	return (
		<div className="yeewuna-parcours-utilisateur-liste-auteur-container my-3">
			{!isLoading ? (
				data && data?.results?.length > 0 ? (
					<>
						{data?.results?.map((item) => (
							<ActuItem item={item} key={item?.slug} />
						))}
						<div className="d-flex justify-content-center">
							{isShowPagination(data?.count || 0, limit) && (
								<CustomPagination
									nbPages={data?.count}
									page={page}
									onChange={(page, perPage) => {
										setPerPage(perPage);
										setPage(page);
									}}
									perPage={limit}
								/>
							)}
						</div>
					</>
				) : (
					<AlertInfo message="Aucune donnée trouvée" />
				)
			) : (
				<></>
			)}
			<div className="d-flex justify-content-center">
				{isShowPagination(data?.count || 0, limit) && (
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={limit}
					/>
				)}
			</div>
		</div>
	);
}

export default FilActualite;
