/* eslint-disable @typescript-eslint/no-explicit-any */
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export interface useStepType {
	step: any;
	navigation: any;
	index: number;
	go?: any;
}

export type CustomPaginationProps = {
	nbPages: number;
	page: number;
	onChange: (page: number, perPage: number) => void;
	perPage: number;
};

export interface IQuery<T> {
	item: T | any;
	deleteItem: (slug: string) => void;
	isSuccess: boolean;
	isError: boolean;
	error: FetchBaseQueryError | SerializedError | undefined;
	question: string;
	successMessage: string;
	redirectUrl?: string;
}
export interface QueryError {
	data: {
		message: string;
	};
	status: number;
}

export interface IImage {
	id: number;
	slug: string;
	picture: string;
	created_at: string;
}

export const accept = {
	image: "image/*",
	pdf: "application/pdf",
	epub: ".epub, .mobi",
};

export interface IModalProps<T> {
	isOpen: boolean;
	closeModal: () => void;
	item?: T;
	setItem?: any;
}

export const ServicePaymentCode = {
	OM: "ORANGE_SN_API_CASH_OUT",
	WAVE: "WAVE_SN_API_CASH_OUT",
	FREE: "FREE_SN_WALLET_CASH_OUT",
	CARTE: "BANK_CARD_API_CASH_OUT",
} as const;

export enum ServicePayment {
	OM = "ORANGE_SN_API_CASH_OUT",
	WAVE = "WAVE_SN_API_CASH_OUT",
	FREE = "FREE_SN_WALLET_CASH_OUT",
	CARTE = "BANK_CARD_API_CASH_OUT",
}
export enum ApiMoyenPaiement {
	OM = "ORANGE_MONEY",
	WAVE = "WAVE",
	FREE = "FREE_MONEY",
	CARTE = "VIREMENT_BANCAIRE",
	EMONEY = "E_MONEY",
}
