import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { CategoryFormData, Category } from "../../../../../utils/api/category/category.type";
import { useAddOrEditCategoryMutation } from "../../../../../utils/api/category/category.api";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";

function useCategoryForm(closeModal: () => void, item?: Category) {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nom"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<CategoryFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading }] = useAddOrEditCategoryMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	useEffect(() => {
		console.log({ item });
		if (item) {
			setValue("name", item?.name);
		} else {
			setValue("name", "");
		}
	}, [item]);

	const onSubmit = async (data: CategoryFormData) => {
		const res = await addOrUpdate({ slug: item?.slug, data: data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: item ? "Catégorie modifiée avec succès!" : "Catégorie ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
			});
		}
		if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useCategoryForm;
