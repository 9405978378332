import { useCallback, useState } from "react";
// import { FiPaperclip } from "react-icons/fi";
import { RiSendPlaneFill } from "react-icons/ri";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddChatMutation } from "../../../../utils/api/chat/chat.api";
import { Color } from "../../../../utils/theme";

export function Form({ userId }: { userId?: number }) {
	const user = useAppSelector((s) => s.user.user);
	const [addChat] = useAddChatMutation();
	const [message, setMessage] = useState<string>("");
	const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		console.log(e.currentTarget.value);
		setMessage(e.currentTarget.value);
	};
	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			console.log({ message });
			if (message.trim()?.length > 1) {
				const res = await addChat({
					message: message,
					recever: userId,
					sender: user.id,
				});
				if ("data" in res) {
					console.log("success");
					setMessage("");
				} else if ("error" in res) {
					Swal.fire({
						icon: "error",
						confirmButtonColor: Color.success,
						title: "Message non envoyé",
					});
				}
			}
		},
		[message, user, userId]
	);
	return (
		<form onSubmit={onSubmit}>
			<div className="left-footer">
				<div className="left-footer-container">
					<div className="input-group">
						<div className="input-container">
							<div className="container-display-input-message">
								{/* <div className="share">
									<FiPaperclip className="img-icon-chat" />
								</div> */}
								<div className="inp w-100">
									<textarea
										className="textarrea-message-custom form-control"
										rows={1}
										onChange={handleChangeMessage}
										placeholder="Ecrivez votre message ici..."
										value={message}
									/>
								</div>
							</div>
							<div className="d-flex gap-2">
								{/* <div className="emoji">
									<BsEmojiWink className="img-icon-chat" />
								</div>
								<div className="camera">
									<HiOutlineCamera className="img-icon-chat" />
								</div> */}
							</div>
						</div>
					</div>
					{/* <div className="btn-container">
						<button>
							<BsFillMicFill className="img-icon-chat" />
						</button>
					</div> */}
					<button type="submit" className="btn btn-send-message-tabs">
						<RiSendPlaneFill />
					</button>
				</div>
			</div>
		</form>
	);
}
