import React, { Dispatch, SetStateAction } from "react";
import { useStep } from "react-hooks-helper";
import Modal from "react-bootstrap/Modal";
import OfferStep from "./steps/OfferStep";
import PaymentModeStep from "./steps/PaymentModeStep";
import CheckStatusStep from "./steps/CheckStatusStep";
import { useStepType } from "../../../../utils/type";
import "./SubscribtionModal.css"
import UseSubscriberForm from "../hooks/UseSubscriberForm";

const steps = [
	{ id: "offers", Component: OfferStep },
	{ id: "payment-mode", Component: PaymentModeStep },
	{ id: "check-transaction-status", Component: CheckStatusStep },
];

const tabs = ["Offres", "Mode de paiement", "Vérification"];

function SubscribtionModal({
	show,
	setShow,
}: {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}) {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const { Component } = step;
    const method = UseSubscriberForm(navigation)
    const {onSubmit} = method
	const handleClose = () => {
		setShow(false);
	};

	const props = {
		index,
		step,
        ...method
	};
	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="xl"
			fullscreen
			backdrop="static"
			keyboard={false}
		>
			{/* <Modal.Header closeButton={false}>
				<Modal.Title className="modal-title-custom">
					Souscription à un abonnement
				</Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="modal-body-completed-register pricing_plans">
				<form className="pt-0" onSubmit={onSubmit}>
					<Component {...props} />
				</form>
			</Modal.Body>
		</Modal>
	);
}

export default SubscribtionModal;
