import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./ListLivresTable.css";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useGetBooksQuery } from "../../../../../utils/api/book/book.api";
import {
	CustomPagination,
	DeleteBookItemBtn,
} from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { Category } from "../../../../../utils/api/category/category.type";
import { IUser } from "../../../../../utils/api/user/user.type";
import { getImage } from "../../../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { Book } from "../../../../../utils/api/book/book.type";
import { RiEdit2Fill } from "react-icons/ri";
function ListLivresTable() {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetBooksQuery({
		page,
		limit,
	});

	const actionFormatter = (cell: string, row: Book) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/livres/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Voir"
						>
							<AiFillEye />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/livres/modifier/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Modifier"
						>
							<RiEdit2Fill />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<DeleteBookItemBtn slug={cell} />
					</div>
				</div>
			</>
		);
	};
	const imgFormatter = (cell, row) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-livre-admin-table">
					<img
						src={getImage(cell)}
						alt={row?.id}
						className="photo-profil-livre-admin-table"
					/>
				</div>{" "}
			</div>
		);
	};
	const priceFormatter = (cell) => {
		return (
			<div className="table-actions-btn-container-img">
				<p className="price-admin-table mb-0">{cell} FCFA</p>
			</div>
		);
	};
	// const rowEvents = {
	//   onClick: (e, row, rowIndex) => {
	//     navigate("/admin/livre/:slug");
	//   },
	// };
	// const selectRow = {
	//   mode: "checkbox",
	// };
	// const rowClasses = (row, rowIndex) => {
	//   return "custom-row-class-admin";
	// };
	const columns = [
		{
			dataField: "couverture",
			text: "Image",
			formatter: (cell: string, row: Book) => imgFormatter(cell, row),
		},
		{
			dataField: "title",
			text: "Nom",
		},
		{
			dataField: "author",
			text: "Auteur",
			formatter: (cell: IUser) => cell?.first_name + " " + cell?.last_name,
		},
		{
			dataField: "category",
			text: "Catégorie",
			formatter: (cell: Category) => cell?.name,
		},
		{
			dataField: "price",
			text: "Prix",
			formatter: (cell: string) => priceFormatter(cell),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: Book) => actionFormatter(cell, row),
		},
	];

	//   console.log({ data });
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setLimit(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default ListLivresTable;
