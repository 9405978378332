import React, { useEffect } from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import BannerHeader from "../BannerHeader";
import "./Services.css";
import Illustration1 from "../../../assets/appImages/Illustration1.png";
import Illustration2 from "../../../assets/appImages/Illustration2.png";
import Illustration3 from "../../../assets/appImages/Illustration3.png";
import Illustration4 from "../../../assets/appImages/Illustration4.png";
// import DevenirAutheurModal from "./Modals/DevenirAutheurModal";
import CommanderTextModal from "./Modals/CommanderTextModal";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FormDemandeService from "./FormDemandeService";
import { NavLink, useLocation } from "react-router-dom";
import { showModal } from "../../../utils/Utils";

function Services() {
	const { hash } = useLocation();

	useEffect(() => {
		// const { pathname } = location;
		console.log(hash);
		if (hash?.includes("#getDevis")) window.scrollTo(0, 1700);
		if (hash?.includes("#orderText")) showModal("CommanderTextModal");
	}, [hash]);

	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<BannerHeader title="Services" />
				<section className="section-a-propos-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="content-title-section-avantage text-center">
							<h2 className="h2-theme position-relative">Comment ça marche?</h2>
						</div>
						<div className="row py-5">
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-titre-secondaire-container">
									<div className="yeewuna-page-service-titre-secondaire pb-4">
										Comment devenir auteur?
									</div>
									<div className="yeewuna-page-service-titre-secondaire-paragraphe">
										Pour bénéficier du statut de « Auteur » dans notre
										plateforme de ventes de livre papier et électronique,
										commencez par vous identifier en ajoutant vos références
										(nom d’auteur, Bio) ensuite remplir les détails du livre
										(titre, description, couverture, catégorie, prix etc.).
										Après validation de la première étape, vous souscrivez à un
										abonnement, publiez le livre dans notre plateforme et
										assurez la promotion du livre. Il est important de bien
										comprendre les conditions de la plateforme avant de
										souscrire à un abonnement.
									</div>
									<div className="yeewuna-page-service-btn-action-container pt-5">
										<NavLink
											className="btn yeewuna-page-service-btn-action"
											to={"/devenir-autheur"}
										>
											Devenir auteur
										</NavLink>

										{/* <div
											className="modal fade"
											id="DevenirAutheurModal"
											aria-labelledby="DevenirAutheurModalLabel"
											aria-hidden="true"
										>
											<DevenirAutheurModal />
										</div> */}
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-img-container">
									<img
										src={Illustration1}
										alt="photos illustration"
										className="yeewuna-page-service-img"
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-img-container">
									<img
										src={Illustration2}
										alt="photos illustration"
										className="yeewuna-page-service-img"
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-titre-secondaire-container">
									<div className="yeewuna-page-service-titre-secondaire pb-4">
										Comment vendre sur la plateforme?
									</div>
									<div className="yeewuna-page-service-titre-secondaire-paragraphe">
										Découvrez comment partager vos écrits sur YeeBook !
										Inscrivez-vous à nos abonnements, téléchargez vos œuvres en
										formats papier, ebook et audiobook, personnalisez les
										détails et choisissez un prix compétitif. Partagez vos
										livres avec le monde en les promouvant sur notre plateforme
										et auprès de vos fans. Suivez vos ventes et performances
										grâce à votre compte. Lancez-vous sur YeeBook et faites
										rayonner vos créations littéraires.{" "}
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-titre-secondaire-container">
									<div className="yeewuna-page-service-titre-secondaire pb-4">
										Commander un texte
									</div>
									<div className="yeewuna-page-service-titre-secondaire-paragraphe">
										Les Editions Yeewuna a compris que chaque projet d'écriture
										est unique, c'est pourquoi notre fonctionnalité de commande
										de texte est entièrement personnalisable et dédiée à vous
										être utile. Faites appel à notre équipe de rédacteurs
										professionnels pour créer un texte de qualité supérieure qui
										vous aidera à marquer votre différence. C'est maintenant !
									</div>
									<div className="yeewuna-page-service-btn-action-container pt-5">
										<button
											className="btn yeewuna-page-service-btn-action"
											// data-bs-toggle="modal"
											// data-bs-target="#CommanderTextModal"
											onClick={() => showModal("CommanderTextModal")}
										>
											Commander
										</button>
										<div
											className="modal fade"
											id="CommanderTextModal"
											aria-labelledby="CommanderTextModalLabel"
											aria-hidden="true"
										>
											<CommanderTextModal modalId="CommanderTextModal" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-3 mb-md-5 pb-md-5">
								<div className="yeewuna-page-service-img-container">
									<img
										src={Illustration3}
										alt="photos illustration"
										className="yeewuna-page-service-img"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="yeewuna-page-service-obtenir-devis-container">
						<div className="yeewuna-page-service-obtenir-devis-col-container">
							<div className="yeewuna-page-service-obtenir-devis-col-left no-view-mobile">
								<div className="yeewuna-page-service-obtenir-devis-col-left-bg-yellow">
									<div className="yeewuna-page-service-obtenir-devis-col-left-image-container">
										<img
											src={Illustration4}
											alt="photos illustration"
											className="yeewuna-page-service-obtenir-devis-col-left-image"
										/>
									</div>
								</div>
							</div>
							<div className="yeewuna-page-service-obtenir-devis-col-right pb-5">
								<FormDemandeService />
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
}

export default Services;
