import React from "react";

type Props = {
	children: React.ReactNode;
	total: number;
};

const MapSkeleton: React.FC<Props> = ({ children, total }) => {
	return (
		<>
			{[...Array(total)]?.map((item, i) => (
				<React.Fragment key={i}>{children}</React.Fragment>
			))}
		</>
	);
};

export default MapSkeleton;
