import React from "react";
import { ChatData } from "../../../../utils/api/chat/chat.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { getAvatar, getName } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { MessageReceiveItem, MessageSenderItem } from "./MessagesItem";
import { Form } from "./SendMessageForm";

type Props = {
	chat: ChatData | null;
	user: IUser | null;
};

function DetailMessageTabsAdmin({ chat, user }: Props) {
	return (
		<div className="dtails-messages-tabs-component">
			{user?.id ? (
				<>
					<div className="row align-items-center border-bottom p-3">
						<div className="col-xl-1 col-lg-2 col-sm-1 col-md-3 px-0">
							<img
								src={getAvatar(user?.avatar)}
								className="image-profil-entete pe-0 image_responsive"
								alt="profil detail message"
								style={{ width: "60", height: "60" }}
							/>
						</div>
						<div className="col-xl-9 col-lg-8 col-md-9 col-sm-10 ps-0">
							<div className="msg-user-infos-container">
								<div className="d-flex align-items-center msg-user-name">
									<p className="profil-detail-message mb-0">{getName(user)}</p>
								</div>
								{/* <div className="bloc-user-disconnect-time msg-user-lastonline">
									<p className="text-disconnect-time mb-0">
										En line - Aujourd'hui, 14:27
									</p>
								</div> */}
							</div>
						</div>
					</div>
					<div className="bloc-details pb-5">
						{chat?.messages?.map((message) => {
							if (message.sender.id !== user.id) {
								return <MessageSenderItem item={message} key={message.id} />;
							}
							return <MessageReceiveItem item={message} key={message.id} />;
						})}
					</div>
					<div className="p-3 border-top">
						<Form userId={user?.id} />
					</div>
				</>
			) : (
				<AlertInfo message="Aucune discussion ouverte" />
			)}
		</div>
	);
}

export default DetailMessageTabsAdmin;
