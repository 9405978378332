import React, { useState } from "react";
import "./FrontFooter.css";
// import Logo from "../../../assets/appImages/logo.png";
import { BsArrowUpCircle } from "react-icons/bs";
import { useGetSocialMediaVisitorQuery } from "../../../utils/api/config/config.api";
import Logo from "../FrontHeader/Logo";
import { NavLink } from "react-router-dom";

const FrontFooter = () => {
	const [visible, setVisible] = useState(false);
	const { data } = useGetSocialMediaVisitorQuery();

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
         in place of 'smooth' */
		});
	};

	window.addEventListener("scroll", toggleVisible);
	const isViewSocialBlock = data?.facebook || data?.linkedin || data?.instagram || data?.twitter;
	const isViewContactBlock = data?.email || data?.telephone;
	return (
		<footer className="footer-component">
			<div className="container-app-yeewuna">
				<div className="row">
					<div className="col-lg-1 col-md-2 mb-3 mb-md-5 footer-grid">
						{/* <img src={Logo} alt="logo" className="logo-app-footer" /> */}
						<div className="d-flex justify-content-center">
							<Logo />
						</div>
					</div>
					{isViewContactBlock && (
						<div className="mb-3 mb-md-5 col-lg-3 col-md-4 footer-grid">
							<div className="d-flex flex-column align-items-center">
								<p className="link-footer trans-0-2 d-block mb-0">Nous contacter</p>
								{data?.telephone && (
									<a
										className="link-footer trans-0-2 d-block"
										href={`tel:${data?.telephone}`}
									>
										{data?.telephone}
									</a>
								)}
								{data?.email && (
									<a
										className="link-footer trans-0-2 d-block"
										href={`mailto:${data?.email}`}
										target="_blank"
									>
										{data?.email}
									</a>
								)}
							</div>
						</div>
					)}
					{isViewSocialBlock && (
						<div className="mb-md-5 mb-3 col-md-4 footer-grid">
							<p className="titlsociamedia">Nous suivre</p>
							<div className="container-image-sociaux pt-3">
								<div className="wrapper-social-media">
									{data?.facebook && (
										<a href={data.facebook} className="button">
											<div className="icon">
												<i className="fab fa-facebook"></i>
											</div>
											<span>Facebook</span>
										</a>
									)}
									{data?.linkedin && (
										<a className="button" href={data?.linkedin}>
											<div className="icon">
												<i className="fab fa-linkedin"></i>
											</div>
											<span>Linkedin</span>
										</a>
									)}

									{data?.instagram && (
										<a className="button" href={data?.instagram}>
											<div className="icon">
												<i className="fab fa-instagram"></i>
											</div>
											<span>Instagram</span>
										</a>
									)}

									{data?.twitter && (
										<a className="button" href={data?.twitter}>
											<div className="icon">
												<i className="fab fa-twitter"></i>
											</div>
											<span>Twitter</span>
										</a>
									)}
								</div>
							</div>
						</div>
					)}
					<div className="col-md-3 mb-3 mb-md-5 footer-grid">
						<div className="content-btn-scroll-to-top d-flex justify-content-end">
							<button
								className="btn btn-scroll-to-top"
								style={{ display: visible ? "block" : "none" }}
							>
								<BsArrowUpCircle onClick={scrollToTop} />
							</button>
						</div>
					</div>
				</div>
				<div className="text-center container-copyright">
					
					<p className="copyright">©2021 All Rights Reserved. Volkeno Inc</p>
					<NavLink
						className="link-footer trans-0-2 d-block"
						to={`/conditions-generales-utilisation`}
					>
						Conditions générales d'utilisation
					</NavLink>
					<NavLink
						className="link-footer trans-0-2 d-block"
						to={`/politiques-confidentialites`}
					>
						Politique de confidentialité
					</NavLink>
				</div>
			</div>
		</footer>
	);
};

export default FrontFooter;
