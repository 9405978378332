import React, { ButtonHTMLAttributes } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { FiArrowLeft } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineAdd } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { Link, LinkProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export enum ButtonState {
	Primary = "Primary",
	Loading = "Loading",
}

type BtnProps = {
	label?: string;
	isLoading?: boolean;
	full?: boolean;
};

type BtnLoadingType = {
	isLoading: boolean;
};

type ButtonProps = React.HtmlHTMLAttributes<HTMLButtonElement>;

type BtnPropsType = BtnProps & ButtonProps;
export function CircleSpinner() {
	return (
		<span
			className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
			role="status"
		></span>
	);
}
export function BtnSubmit(props: BtnPropsType & BtnLoadingType & ButtonProps) {
	const { isLoading, label, full, ...rest } = props;
	return !isLoading ? (
		<button
			type="submit"
			className={`btn btn-page-title-admin ${full ? " w-100" : ""}`}
			{...rest}
			disabled={isLoading}
		>
			{label}
		</button>
	) : (
		<button
			type="button"
			className={`btn btn-page-title-admin ${full ? " w-100" : ""}`}
			{...rest}
		>
			<CircleSpinner />
			<span>Chargement...</span>
		</button>
	);
}

export function GoBackButton({ label = "Annuler" }: { label?: string }) {
	const navigate = useNavigate();
	return (
		<button className="auth-submit-annuler" type="button" onClick={() => navigate(-1)}>
			{label}
		</button>
	);
}

export function BtnCloseModal({ label, ...rest }: BtnPropsType) {
	return (
		<button
			className="close-modal auth-submit-annuler"
			data-bs-dismiss="modal"
			type="button"
			{...rest}
		>
			{label ? label : <IoClose />}
		</button>
	);
}

export function ButtonAdd({
	label,
	...rest
}: BtnPropsType & React.HtmlHTMLAttributes<HTMLButtonElement>) {
	return (
		<button className="btn btn-page-title-admin" {...rest}>
			<MdOutlineAdd />
			<span className="ps-2">{label}</span>
		</button>
	);
}

export const ButtonDelete: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content="Supprimer"
			style={{ color: "#E53E3E" }}
			{...rest}
			type="button"
		>
			<BsTrashFill />
		</button>
	);
};

export const ButtonEdit: React.FC<ButtonProps> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-icon-edit with-tooltip"
			data-tooltip-content="Modifier"
			type="button"
			{...rest}
		>
			<RiEdit2Fill />
		</button>
	);
};
export const ButtonView: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...rest }) => {
	return (
		<button
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Voir"
			type="button"
			{...rest}
		>
			<AiFillEye />
		</button>
	);
};

export const ButtonEditLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			{...rest}
			className="btn btn-action-icon-edit with-tooltip"
			data-tooltip-content="Modifier"
			style={{ color: "#9B51E0" }}
		>
			<RiEdit2Fill />
		</Link>
	);
};
export const ButtonViewLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link
			className="btn btn-action-modal-icon with-tooltip"
			data-tooltip-content="Voir"
			{...rest}
		>
			<AiFillEye />
		</Link>
	);
};

export function GoBackBtn() {
	const navigate = useNavigate();
	return (
		<button
			style={{ background: "none", border: "none" }}
			type="button"
			onClick={() => navigate(-1)}
		>
			<FiArrowLeft fontSize={24} />
		</button>
	);
}
