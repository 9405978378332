/* eslint-disable @typescript-eslint/no-explicit-any */
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsChatDots, BsMicFill, BsPhone } from "react-icons/bs";
import { FiBook } from "react-icons/fi";
import StarRatingComponent from "react-star-rating-component";
import { Book } from "../../../../utils/api/book/book.type";
import {
	createMarkup,
	formatDate,
	formatMontant,
	getAvatar,
	getBanner,
	getName,
} from "../../../../utils/Utils";
import { IUser } from "../../../../utils/api/user/user.type";
import { Link, useNavigate } from "react-router-dom";
import { useRateBook, useToggleFavoriBook } from "./hooks/useBook";
import { Toaster } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addCartBook, calculateTax, getCartCount, getSubTotal, getTotalAmount } from "../../../../redux/slice/Cart.slice";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

type Props = { item: Book };
export const LivredescriptionItem = ({ item }: Props) => {
	const { handleToggleFavoris, isFavoris } = useToggleFavoriBook(item);
	const { rate, totalRates } = useRateBook(item);
	const { user } = useAppSelector((s) => s?.user);
	const dispatch = useAppDispatch();
	const navigate  = useNavigate()

	const onConfirm = async (type: "livre" | "ebook" | "audiobook") => {
		await Swal.fire({
			// title: `Êtes-vous sûr de vouloir ?`,
			icon: "success",
			html: `<div class="sweet-container"> ${
				type !== "livre" ? "L'" : "Le "
			}${type} <strong>a été ajouté au panier</strong> </div>`,
			showCancelButton: true,
			confirmButtonText: "Finaliser ma commande ",
			cancelButtonText: "Fermer",
			showLoaderOnConfirm: true,
			iconColor: Color.bleu,
			confirmButtonColor: Color.bleu,
			customClass: {
				confirmButton: "btn btn-sweet-confirm",
				cancelButton: "btn btn-sweet-cancel",
			},
			reverseButtons: true,
		}).then((result: any) => {
			if (result?.isConfirmed) {
				navigate("/utilisateur/panier");
			}
		});
	};
	
	const addToCart = (type: "livre" | "ebook" | "audiobook", price: number) => {
		dispatch(
			addCartBook({
				item: item,
				qty: 1,
				item_type: type,
				price: price,
				user: user?.id,
			})
		);
		dispatch(getCartCount(user?.id));
		dispatch(getSubTotal(user?.id));
		dispatch(calculateTax());
		dispatch(getTotalAmount());
		onConfirm(type);
	};

	return (
		<div className="yeewuna-description-livre-infos-container">
			<div className="yeewuna-description-livre-infos-image-container">
				<img
					src={getBanner(item?.couverture)}
					alt="photos couverture du livre"
					className="yeewuna-description-livre-infos-image"
				/>
			</div>
			<div className="yeewuna-description-livre-infos-text-container">
				<div className="yeewuna-description-livre-infos-text-titre mb-1">
					{item?.title}{" "}
					<button onClick={handleToggleFavoris} className="bookmark-btn">
						{isFavoris ? (
							<AiFillHeart className="yeewuna-description-livre-infos-text-titre-icone" />
						) : (
							<AiOutlineHeart className="yeewuna-description-livre-infos-text-titre-icone" />
						)}
					</button>
				</div>
				<div className="yeewuna-description-livre-infos-text-autheur mb-2">
					Par <span>{getName(item?.author)}</span>
				</div>
				<div className="yeewuna-description-livre-infos-text-rate-container mb-3">
					<div className="yeewuna-rate-star-container">
						<StarRatingComponent
							starCount={5}
							name="rate__book"
							editing={false}
							value={rate}
							starColor="#F8BF5C"
							emptyStarColor="#BDBDBD"
						/>
					</div>
					<div className="yeewuna-description-livre-infos-text-rate">
						{rate}/5 ({totalRates} notations)
					</div>
				</div>
				<div className="yeewuna-description-livre-infos-text-titre-apropos">
					À propos de ce livre{" "}
				</div>
				<div
					className="yeewuna-description-livre-infos-text-paragraphe-apropos"
					dangerouslySetInnerHTML={createMarkup(item?.description)}
				/>
				<div className="yeewuna-description-livre-infos-text-caracteristique-container mt-3 mb-4">
					<div className="row">
						<div className="col-lg-3">
							<div className="yeewuna-description-livre-infos-text-caracteristique">
								<div className="yeewuna-description-livre-infos-text-caracteristique-label">
									Langue
								</div>
								<div className="yeewuna-description-livre-infos-text-caracteristique-value">
									{item?.langage}
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="yeewuna-description-livre-infos-text-caracteristique">
								<div className="yeewuna-description-livre-infos-text-caracteristique-label">
									Éditeur
								</div>
								<div className="yeewuna-description-livre-infos-text-caracteristique-value">
									{item?.editeur}
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="yeewuna-description-livre-infos-text-caracteristique">
								<div className="yeewuna-description-livre-infos-text-caracteristique-label">
									Date de sortie
								</div>
								<div className="yeewuna-description-livre-infos-text-caracteristique-value">
									{formatDate(item?.date_out)}
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="yeewuna-description-livre-infos-text-caracteristique">
								<div className="yeewuna-description-livre-infos-text-caracteristique-label">
									ISBN
								</div>
								<div className="yeewuna-description-livre-infos-text-caracteristique-value">
									{item?.isbn}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="yeewuna-description-livre-infos-text-btn-container">
					<button
						className="btn yeewuna-description-livre-infos-text-btn"
						onClick={() => addToCart("ebook", parseFloat(item?.pdf_price))}
					>
						<BsPhone /> E-book <span>{formatMontant(item?.pdf_price)}</span>
					</button>
					<button
						className="btn yeewuna-description-livre-infos-text-btn"
						onClick={() => addToCart("livre", parseFloat(item?.price))}
					>
						<FiBook /> Livre <span>{formatMontant(item?.price)}</span>
					</button>
					{!!item?.audio && (
						<button
							className="btn yeewuna-description-livre-infos-text-btn"
							onClick={() => addToCart("audiobook", parseFloat(item?.audio_price))}
						>
							<BsMicFill /> Audiobook <span>{formatMontant(item?.audio_price)}</span>
						</button>
					)}
				</div>
			</div>
			<Toaster />
		</div>
	);
};

export const AutheurDescriptionItem = ({ author }: { author: IUser }) => {
	return (
		<div className="yeewuna-description-livre-item-content-auteur-infos-container">
			<div className="yeewuna-description-livre-item-content-auteur-infos mt-3 mb-4">
				<div className="yeewuna-description-livre-item-content-auteur-profile-container">
					<img
						src={getAvatar(author?.avatar)}
						alt="photos profile de l'autheur"
						className="yeewuna-description-livre-item-content-auteur-profile-image"
					/>
					<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile">
						<div className="yeewuna-description-livre-item-content-auteur-profile-username">
							{getName(author)}
						</div>
						<div className="yeewuna-description-livre-item-content-auteur-profile-fonction">
							Auteur
						</div>
					</div>
				</div>
				<div className="yeewuna-description-livre-item-content-auteur-date-publication">
					<Link
						to={"/utilisateur/messages"}
						className="btn yeewuna-description-livre-item-content-btn"
						state={author}
					>
						<BsChatDots /> Envoyer un message
					</Link>
				</div>
			</div>
			<div
				className="yeewuna-description-livre-item-content-paragraphe mb-4"
				dangerouslySetInnerHTML={createMarkup(author?.bio)}
			/>
			{/* {author?.bio && (
				<div className="yeewuna-description-livre-item-content-btn-en-savoir-plus position-relative">
					En savoir plus
				</div>
			)} */}
		</div>
	);
};
