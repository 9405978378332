import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useGetDraftedBooksByAuthorQuery } from "../../../../../utils/api/book/book.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { AlertInfo } from "../../../../common/Alert";
import { createMarkup, getImage } from "../../../../../utils/Utils";
import { Link } from "react-router-dom";

const Brouillon = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { data, isLoading } = useGetDraftedBooksByAuthorQuery({ slug: user?.slug });
	console.log({ data });
	return (
		<div className="brouillon-component">
			<div className="content-col-about-me p-3">
				<h2 className="title-col-right-about-me">Mon brouillon</h2>
				<div className="info-oeuvres-brouillon pb-3">
					<p className="text-info-oeuvres-brouillon mb-0">
						{/* 24 oeuvres publiées <span style={{ fontSize: 18, fontWeight: 700 }}>.</span>{" "} */}
						{data?.count} oeuvres non publiées (visibles uiquement pour vous)
					</p>
				</div>
				<div className="container-brouillion-tabs">
					{!isLoading ? (
						data && data?.results?.length > 0 ? (
							<>
								{data?.results?.map((item) => (
									<div className="row row-oeuvres-about-me mb-3" key={item?.slug}>
										<div className="col-lg-2 col-md-3 col-left-oeuvres-about-me mb-3">
											<div className="content-col-left-oeuvres-about-me">
												<img
													src={getImage(item?.couverture)}
													alt={item?.title}
													className="img-oeuvres-about-me"
													height="200px"
													style={{ objectFit: "cover" }}
												/>
											</div>
										</div>
										<div className="col-lg-10 col-md-9 col-right-oeuvres-about-me mb-3">
											<div className="content-col-right-oeuvres-about-me">
												<div className="container-header-brouillon-item">
													<h3 className="title-col-left-about-me pb-2 d-flex mb-0">
														{item?.title}
													</h3>
													<div className="dropdown">
														<span
															role="button"
															data-bs-toggle="dropdown"
															aria-expanded="false"
														>
															<FiMoreHorizontal />
														</span>

														<ul className="dropdown-menu">
															<li></li>
															<li>
																<Link
																	className="dropdown-item"
																	to={`/auteur/modifier-livre/${item?.slug}`}
																	state={item}
																>
																	Modifier
																</Link>
															</li>
														</ul>
													</div>
												</div>

												<div
													className="content-text-describe-oeuvres-about-me"
													dangerouslySetInnerHTML={createMarkup(
														item?.description?.slice(0, 500)
													)}
												/>
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default Brouillon;
