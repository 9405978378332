import { ConditionForm } from "./AddCguAdmin";

function AddPolicyAdmin() {
	return (
		<div className="setting-admin">
			<ConditionForm />
		</div>
	);
}

export default AddPolicyAdmin;
