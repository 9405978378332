type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};
export type FieldType =
	| "accueil"
	| "auteur"
	| "utilisateur"
	| "livre"
	| "message"
	| "blog"
	| "categorie"
	| "support"
	| "faq"
	| "demande_devis"
	| "commande_texte"
	| "profil"
	| "parameter";

export const Authorisations: Authorization[] = [
	{
		field: "accueil",
		label: "Accueil",
		path: "/admin/dashboard",
	},
	{
		field: "auteur",
		label: "Auteurs",
		path: "/admin/auteurs",
	},
	{
		field: "utilisateur",
		label: "Utilisateurs",
		path: "/admin/utilisateurs",
	},
	{
		field: "livre",
		label: "Livres",
		path: "/admin/livres",
	},
	{
		field: "message",
		label: "Messages",
		path: "/admin/messages",
	},
	{
		field: "blog",
		label: "Blog",
		path: "/admin/blog",
	},
    {
		field: "categorie",
		label: "Catégories",
		path: "/admin/categories",
	},
	{
		field: "support",
		label: "Support",
		path: "/admin/support",
	},
	{
		field: "faq",
		label: "Faq",
		path: "/admin/faq",
	},
	{
		field: "demande_devis",
		label: "Devis",
		path: "/admin/devis",
	},
	{
		field: "commande_texte",
		label: "Commande texte",
		path: "/admin/commande-texte",
	},
	{
		field: "parameter",
		label: "Paramètres",
		path: "/admin/parametres",
	},
];
