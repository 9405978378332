import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useResponseContactMutation } from "../../../../../utils/api/contact/contact.api";
import { ResponseContact } from "../../../../../utils/api/contact/contact.type";
import { cleannerError } from "../../../../../utils/Utils";
import { Color } from "../../../../../utils/theme";


function useResponseMessageForm() {
	const [response, setResponse] = useState<any>(null);
	const refInput = useRef<any>();
	const validationSchema = yup.object().shape({
		response: yup.string().required().label("La réponse"),
	});
	const [responseContact, { isSuccess, isError, error, isLoading }] =
		useResponseContactMutation();
	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		setError,
	} = useForm<ResponseContact>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			refInput.current.editor.setText("");
			Swal.fire({
				icon: "success",
				title: "Réponse envoyée avec suucès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setResponse(null);
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: `${
					err?.message
						? err?.data?.message
						: `Une erreur de statut ${err?.status || "inconnue"} est survenue`
				}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {}, []);

	const handleChange = (value: any) => {
		setResponse(value);
		setValue("response", value);
	};

	const onSubmit = (data: ResponseContact) => {
		console.log(data, refInput);
		if (data["response"] === "<p><br></p>") {
			return setError("response", { message: "La réponse est obligatoire" });
		} else {
			responseContact(data);
		}
	};

	return {
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading: isLoading,
		response,
		handleChange,
		refInput,
	};
}

export default useResponseMessageForm;
