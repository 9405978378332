import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { IAdmin } from "../utils/api/admin/admin.type";
import { IUser, UserType } from "../utils/api/user/user.type";
import { isAutheur, isVisiteur } from "../utils/Utils";
import { FieldType } from "../utils/constante";

export function isAdminUser(user?: IUser | IAdmin) {
	return user && (user.user_type === "admin" || user.user_type === "superadmin");
}

export function isSuperAdmin(user?: IUser | IAdmin) {
	return user && user.user_type === "superadmin";
}

export const ProtectedRoutes = ({
	children,
	userType,
}: {
	children: ReactElement;
	userType: UserType[];
}) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	// const is_AdminAgent = isAdminAgent(user);
	const isAccess = userType?.includes(user?.user_type);
	const auth = user?.id;
	return isAccess && isAccess !== undefined ? (
		children
	) : auth && auth !== undefined ? (
		<Navigate to="/" replace />
	) : (
		<Navigate to="/connexion" state={{ from: location }} replace />
	);
};

export const AdminRoutes = ({ children }: { children: ReactElement }) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	if (!user?.id) return <Navigate to="/connexion" state={{ from: location }} replace />;
	if (isVisiteur(user)) return <Navigate to="/utilisateur/accueil" />;
	if (isAutheur(user)) return <Navigate to="/auteur/accueil" />;

	if (isAdminUser(user)) return children;
	return <Navigate to="/" />;
};

export const VisiteurRoutes = ({ children }: { children: ReactElement }) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	if (!user?.id) return <Navigate to="/connexion" state={{ from: location }} replace />;

	if (isVisiteur(user)) return children;
	if (isAutheur(user)) return <Navigate to="/auteur/accueil" />;
	if (isAdminUser(user)) return <Navigate to="/admin/dashboard" />;
	return <Navigate to="/" />;
};

export const AutheurRoutes = ({ children }: { children: ReactElement }) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	if (!user?.id) return <Navigate to="/connexion" state={{ from: location }} replace />;

	if (isAutheur(user)) return children;
	if (isVisiteur(user)) return <Navigate to="/utilisateur/accueil" />;
	if (isAdminUser(user)) return <Navigate to="/admin/dashboard" />;
	return <Navigate to="/" />;
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
	const { token, user } = useAppSelector((state) => state.user);
	let path = "/";
	console.log("redirect", user);
	if (isVisiteur(user)) {
		path = "/utilisateur/accueil";
	} else if (isAutheur(user)) {
		path = "/auteur/accueil";
	} else if (user?.user_type === UserType.superAdmin || user?.user_type === UserType.admin) {
		path = "/admin/dashboard";
	}
	return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export const hasAccess = (user: IUser, field: FieldType) => {
	let noAccess = false;
	if (isAdminUser(user) && user[field] !== true && !isSuperAdmin(user)) {
	  noAccess = true;
	}
  
	return noAccess;
  };