import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useGetCommandeTextQuery } from "../../../../../utils/api/commandeText/commandeText.api";
import { CommandeText } from "../../../../../utils/api/commandeText/commandeText.type";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import TableSkeleton from "../../../../common/TableSkeleton";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import { Category } from "../../../../../utils/api/category/category.type";
import ComandeTextDetails from "../modal/ComandeTextDetails";
import { showModal } from "../../../../../utils/Utils";
import ChangeStatusCommandeText from "../modal/ChangeStatusCommandeText";

function CommandeTextTable() {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetCommandeTextQuery({
		page,
		limit,
	});

	const actionFormatter = (cell: string, row: CommandeText) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<button
							onClick={() => showModal(`detailsCommande${cell}`)}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Voir"
						>
							<AiFillEye />
						</button>
					</div>
				</div>
				<ComandeTextDetails item={row} modalId={`detailsCommande${cell}`} />
			</>
		);
	};

	const statutFormatter = (cell: string, row: CommandeText) => {
		return (
			<>
				<button
					className={`btn message-btn ${
						cell === "new"
							? "bg-status"
							: cell === "en_cours"
							? "bg-status-secondary"
							: "bg-success"
					}`}
					aria-expanded="false"
					onClick={() => showModal(`changeStatus${row?.slug}`)}
				>
					{cell === "new" ? "Nouveau" : cell === "traité" ? "Traité" : "En cours"}
				</button>
				<ChangeStatusCommandeText modalId={`changeStatus${row?.slug}`} item={row} />
			</>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Nom",
		},
		{
			dataField: "prenom",
			text: "Prénom",
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "type_oeuvre",
			text: "Type d’oeuvre",
			formatter: (cell: Category) => cell?.name,
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: (cell: string, row: CommandeText) => statutFormatter(cell, row),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: CommandeText) => actionFormatter(cell, row),
		},
	];

	//   console.log({ data });
	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setLimit(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default CommandeTextTable;
