import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import ImgLogin from "../../assets/appImages/login-img.png";
import UseForgetPasswordForm from "./useAuthForm/useForgotPassword";
import ErrorMessage from "../common/ErrorMessage";

const ForgetPassword = () => {
  const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
  return (
    <div>
      <div className="container-page-login-yeewuna">
        <div className="auth-row row">
          <div className="col-md-6 auth-col auth-left-side-col d-flex">
            <div className="auth-left-side-container w-100">
              <img src={ImgLogin} alt="connexion" className="img-login" />
            </div>
          </div>
          <div className="col-md-6 auth-col auth-right-side-col d-flex">
            <div className="auth-right-side-container w-100">
              <div className="auth-form-container">
                <div className="container-img-logo pb-4 text-center">
                  <img src={Logo} alt="Logo" className="img-logo-login" />
                </div>
                <h1 className="auth-form-title">Mot de passe oublié?</h1>
                <p className="text-message-login">
                  Entrez votre mail pour réinitialiser votre mot de passe
                </p>
                <div className="mt-4">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Email
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email"
                            placeholder="Identifiant"
                            aria-label="Email"
                            {...register("email")}
                          />
                          {errors?.email && (
                            <ErrorMessage message={errors?.email?.message} />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-4">
                        {!isLoading ? (
                          <button
                            className="btn auth-submit-btn trans-0-2 w-100"
                            type="submit"
                            disabled={isLoading}
                          >
                            <span className="pe-2">Envoyer</span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn auth-submit-btn trans-0-2 w-100"
                          >
                            <span
                              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
                              role="status"
                            ></span>
                            <span>Chargement...</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="content-text-message-login mt-3 text-center">
                      <NavLink
                        to="/connexion"
                        className="btn-redirect-to-login"
                      >
                        Retour à la page de connexion
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
