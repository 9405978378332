import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../redux/hooks";
import { Color } from "../../../utils/theme";
import { QueryError } from "../../../utils/type";
import { DevisForm } from "../../../utils/api/contact/contact.type";
import { cleannerError } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useSendDemandeDevisMutation } from "../../../utils/api/contact/contact.api";

const useSendDevis = () => {
	const validationSchema = yup.object().shape({
		first_name: yup.string().required().label("Prénom"),
		last_name: yup.string().required().label("Nom"),
		email: yup.string().email().required().label("Email"),
		type_oeuvre: yup
			.number()
			.required()
			.label("Type de l'oeuvre")
			.typeError("Type de l'oeuvre"),
		document: yup.mixed().required().label("Document"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);

	const [sendDevis, { isLoading }] = useSendDemandeDevisMutation();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<DevisForm>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (authUser) {
			setValue("first_name", authUser?.first_name);
			setValue("last_name", authUser?.last_name);
			setValue("email", authUser?.email);
		}
	}, [authUser]);

	const handleSelectDocument = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			setValue("document", e.currentTarget.files[0]);
		}
	};

	const onsubmit = async (data: DevisForm) => {
		console.log({ data });

		if (authUser?.id) {
			data["created_by"] = authUser?.id;
		}

		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}

		const res = await sendDevis(fd);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Demande envoyée avec succés !",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				navigate("/");
			});
		} else if ("error" in res) {
			console.log("error", res.error);
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		authUser,
		handleSelectDocument,
	};
};

export default useSendDevis;
