import moment from "moment";
import { AiFillLike, AiOutlineEye, AiOutlineLike } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiMessageSquare } from "react-icons/fi";
import { Link } from "react-router-dom";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { IForum } from "../../../../utils/api/forum/forum.type";
import { getAvatar, getName } from "../../../../utils/Utils";
import { useIncrementViewSubject, useToggleLikeSubject } from "./hooks/useForum";
import { Color } from "../../../../utils/theme";

function ForumSubjectItem({ item }: { item: IForum }) {
	const { handleToggleLikeSubject, isLike } = useToggleLikeSubject(item);
	const { onIncrement } = useIncrementViewSubject(item);
	console.log({ item });
	return (
		<Link
			to={`/utilisateur/communaute/discussion/${item?.slug}`}
			className="no-link"
			state={item}
			onClick={onIncrement}
		>
			<div className="communaite-article-item-container mb-3">
				<div className="communaite-article-item-avatar-icone-container mb-3">
					<div className="communaite-article-item-avatar-container">
						<div className="communaite-article-item-container-avatar">
							<img
								src={getAvatar(item?.created_by?.avatar)}
								alt="photos profile de l' utilisateur"
								className="communaite-article-item-avatar"
							/>
						</div>
						<div className="communaite-article-item-username-container">
							<div className="communaite-article-item-username">
								<h5 className="no-link">{getName(item?.created_by)}</h5>
							</div>
							<div className="communaite-article-item-time">
								{moment(item?.created_at).fromNow()}
							</div>
						</div>
					</div>
					<div className="communaite-article-item-icone-container">
						<BsThreeDotsVertical className="communaite-article-item-icone" />
					</div>
				</div>
				<div className="communaite-article-item-titre-container">
					<h5 className="communaite-article-item-titre mb-2">{item?.subject}</h5>
					<p className="communaite-article-item-paragraphe">{item?.text}</p>
				</div>
				<div className="communaite-article-item-tags-stats-container">
					<div className="communaite-article-item-tags-container">
						{item?.categories.map((tag) => (
							<div className="communaite-article-item-tag" key={tag?.slug}>
								{tag?.name}
							</div>
						))}
					</div>
					<div className="communaite-article-item-stats-container">
						<div className="communaite-article-item-stats">
							{isLike ? (
								<AiFillLike
									role="button"
									onClick={handleToggleLikeSubject}
									color={Color.blueDark}
								/>
							) : (
								<AiOutlineLike role="button" onClick={handleToggleLikeSubject} />
							)}{" "}
							{item?.message_likes?.length ?? 0}
						</div>
						<div className="communaite-article-item-stats">
							<AiOutlineEye /> {item?.nb_views ?? 0}
						</div>
						<div className="communaite-article-item-stats">
							<FiMessageSquare /> {item?.response_messages}
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}
export function ForumSubjectItemSkeleton() {
	return (
		<div className="communaite-article-item-container mb-3">
			<div className="communaite-article-item-avatar-icone-container mb-2">
				<div className="communaite-article-item-avatar-container">
					<div className="communaite-article-item-container-avatar">
						<VolkenoSkeleton
							variant="rect"
							borderRadius="50%"
							width="40px"
							height="40px"
						/>
					</div>
					<div className="communaite-article-item-username-container">
						<div className="communaite-article-item-username">
							<VolkenoSkeleton variant="rect" width={100} height={8} />
						</div>
						<div className="communaite-article-item-time">
							<VolkenoSkeleton variant="rect" width={50} height={8} />
						</div>
					</div>
				</div>
				<div className="communaite-article-item-icone-container">
					<BsThreeDotsVertical className="communaite-article-item-icone" />
				</div>
			</div>
			<div className="communaite-article-item-titre-container">
				<VolkenoSkeleton variant="rect" width="100%" height={8} />
				<VolkenoSkeleton variant="rect" width="100%" height={8} />
			</div>
			<div className="communaite-article-item-tags-stats-container">
				<div className="communaite-article-item-stats-container">
					<div className="communaite-article-item-stats">
						<VolkenoSkeleton variant="rect" width={8} height={8} />
					</div>
					<div className="communaite-article-item-stats">
						<VolkenoSkeleton variant="rect" width={8} height={8} />
					</div>
					<div className="communaite-article-item-stats">
						<VolkenoSkeleton variant="rect" width={8} height={8} />
					</div>
				</div>
			</div>
		</div>
	);
}
export default ForumSubjectItem;
