/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { ChangePasswordData, LoginResult } from "../auth/auth.type";
import { Book, IFavoris } from "../book/book.type";
import {
	AuthState,
	BecomeAuthorData,
	BecomeAuthorResponse,
	DashboardAuthorData,
	FollowData,
	IUser,
	PaginationResults,
	PaiementItem,
	TypeQuery,
	UserFormData,
	UserType,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }: any) => {
	const token =
		(getState() as { user: LoginResult }).user.token ??
		AppStorage.getItem<AuthState>("user")?.token;
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}
	return headers;
};

export const UserApi = createApi({
	reducerPath: "userApi",
	tagTypes: [
		"user",
		"userList",
		"authorList",
		"userBySlug",
		"readerList",
		"authorBySlug",
		"favorisUser",
		"reputedAuthor",
		"dashboardAuthor",
		"buyBookReader",
		"paiement",
		"dashboardAuthor",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		me: builder.query<IUser, void>({
			providesTags: ["user"],
			transformResponse: ({ data }) => data,
			query: () => {
				return "me/";
			},
		}),
		getUsers: builder.query<PaginationResults<IUser>, TypeQuery>({
			providesTags: ["user"],
			query: (query) => {
				return QueryUrl(`author/`, query);
			},
		}),
		getAllUsersExceptMe: builder.query<PaginationResults<IUser>, TypeQuery & { q?: string }>({
			providesTags: ["user"],
			query: (query) => {
				return QueryUrl(`contacts/`, query);
			},
		}),
		getAuthors: builder.query<PaginationResults<IUser>, TypeQuery>({
			providesTags: ["authorList"],
			query: (query) => {
				return QueryUrl(`author/`, query);
			},
		}),
		getReputedAuthors: builder.query<IUser[], TypeQuery>({
			providesTags: ["reputedAuthor"],
			query: (query) => {
				return QueryUrl(`follower_order/`, query);
			},
			transformResponse: ({ results }) => results,
		}),
		getReaders: builder.query<PaginationResults<IUser>, TypeQuery>({
			providesTags: ["readerList"],
			query: (query) => {
				return QueryUrl(`reader/`, query);
			},
		}),
		addOrEditUser: builder.mutation<
			IUser,
			{ slug?: string; userType: UserType; data: UserFormData | FormData }
		>({
			invalidatesTags: ["user", "userList", "authorList", "userBySlug", "readerList"],
			query: ({ slug, userType, data }) => {
				if (slug) {
					return {
						url: `${userType === "admin" ? "adminuser" : "user"}/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `${userType === "admin" ? "adminuser" : "user"}/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteUser: builder.mutation<IUser, string>({
			query: (slug) => ({
				url: `user/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["userList", "authorList", "userBySlug", "readerList"],
		}),
		changePassword: builder.mutation<IUser, ChangePasswordData>({
			query: (data) => ({
				url: `changepassword/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		editUser: builder.mutation<IUser, { slug: string; data: UserFormData | FormData }>({
			query: ({ slug, data }) => ({
				url: `user/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["user", "authorList", "userBySlug", "readerList"],
		}),
		getUserBySlug: builder.query<IUser, string>({
			query: (slug) => `user/${slug}`,
			providesTags: ["userBySlug"],
		}),
		getAuthorBySlug: builder.query<IUser, string>({
			query: (slug) => `author/${slug}`,
			providesTags: ["authorBySlug"],
		}),
		becomeAuthor: builder.mutation<BecomeAuthorResponse, BecomeAuthorData | FormData>({
			query: (data) => ({
				url: `book_soumission/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		followAuthor: builder.mutation<IUser, FollowData>({
			query: (data) => ({
				url: `follower/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["authorList", "authorBySlug", "userBySlug"],
		}),
		unFollowAuthor: builder.mutation<IUser, string>({
			query: (slug) => ({
				url: `follower/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["authorList", "authorBySlug", "userBySlug"],
		}),
		getUserFollowers: builder.query<PaginationResults<IUser>, TypeQuery>({
			providesTags: ["userBySlug", "authorBySlug"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`user/${slug}/followed`, query);
			},
		}),
		getUserFollow: builder.query<PaginationResults<IUser>, TypeQuery>({
			providesTags: ["userBySlug", "authorBySlug"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`author/${slug}/followers/`, query);
			},
		}),
		getUserFavorisBook: builder.query<PaginationResults<IFavoris>, TypeQuery>({
			providesTags: ["favorisUser"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`user/${slug}/favoris`, query);
			},
		}),
		getReaderBuyBook: builder.query<PaginationResults<Book>, TypeQuery>({
			providesTags: ["buyBookReader"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`reader/${slug}/bought_books/`, query);
			},
		}),
		getPaiementAuthor: builder.query<PaginationResults<PaiementItem>, TypeQuery>({
			providesTags: ["paiement"],
			query: ({ slug, ...query }) => {
				return QueryUrl(`author/${slug}/sold_books/`, query);
			},
		}),
		getAuthorDashboard: builder.query<DashboardAuthorData, string>({
			query: (slug) => `author/${slug}/dashboard`,
			providesTags: ["dashboardAuthor"],
		}),
		sendAccess: builder.query<IUser, string>({
			query: (slug) => `user/${slug}/send_access/`,
		})
	}),
});

export const {
	useMeQuery,
	useAddOrEditUserMutation,
	useChangePasswordMutation,
	useEditUserMutation,
	useGetAuthorsQuery,
	useLazyGetAuthorsQuery,
	useLazyGetUserBySlugQuery,
	useGetReadersQuery,
	useBecomeAuthorMutation,
	useFollowAuthorMutation,
	useUnFollowAuthorMutation,
	useGetAuthorBySlugQuery,
	useLazyGetAuthorBySlugQuery,
	useGetUsersQuery,
	useGetAllUsersExceptMeQuery,
	useGetUserFollowersQuery,
	useGetUserFollowQuery,
	useGetUserFavorisBookQuery,
	useGetReputedAuthorsQuery,
	useGetReaderBuyBookQuery,
	useGetPaiementAuthorQuery,
	useGetAuthorDashboardQuery,
	useDeleteUserMutation,
	useLazySendAccessQuery
} = UserApi;

export function useUserFromLocation(): [IUser, boolean, string, (id: string) => any] {
	const itemState = useLocationState<IUser | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyGetAuthorBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as IUser, isLoading, slug as string, findBySlug as any];
}

export function useAuthorFromLocation(): [IUser, boolean] {
	const itemState = useLocationState<IUser | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyGetUserBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [findBySlug, itemState, slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate(-1);
		}
	}, [isError, itemState, navigate]);
	useEffect(() => {
		if (data) {
			// console.log("data",data)
			setItem(data);
		}
	}, [data]);

	return [item as IUser, isLoading];
}
