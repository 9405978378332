import React from "react";
import {
	useDeleteNotificationMutation,
	useNotification,
} from "../../../../utils/api/notification/notification.api";
import { AlertInfo } from "../../../common/Alert";
import { ButtonDelete } from "../../../common/Button";
import toast, { Toaster } from "react-hot-toast";
import { INotification } from "../../../../utils/api/notification/notification.type";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { isAutheur } from "../../../../utils/Utils";
import { isAdminUser } from "../../../../routes/routerUtils";

const Notifications = () => {
	const { user } = useAppSelector((s) => s?.user);
	let type: "admin" | "recever" = isAdminUser(user) ? "admin" : "recever";
	const { data, isLoading } = useNotification(type);
	const navigate = useNavigate();

	const [deleteItem] = useDeleteNotificationMutation();
	const onDelete = async (slug) => {
		const res = await deleteItem(slug);
		if ("data" in res) {
			toast.success("Supprimé avec succès");
		} else if ("error" in res) {
			toast.error("Une erreur a survenue");
		}
	};

	const getUserLink = () => {
		if (isAdminUser(user)) {
			return "/admin";
		} else if (isAutheur(user)) {
			return "/auteur";
		} else {
			return "/utilisateur";
		}
	};

	const handleClick = (notif: INotification) => {
		if (notif?.type === "rappel_abonnement") {
			if (isAutheur(user)) {
				navigate(`/auteur/profil`);
			} else if (isAdminUser(user)) {
				navigate(`/admin/auteurs/${notif?.data?.author?.slug}`, {
					state: notif?.data?.author,
				});
			}
		} else if (notif.type === "message") {
			navigate(`${getUserLink()}/messages`);
		}
	};
	return (
		<div className="dashboard-admin-component">
			<div className="goana-dash-header">
				<h4 className="goana-dash-header-titre">Notifications</h4>
			</div>
			<div className="container-dashboard-page">
				<div className="col-left-dashboard col-left-dashboard-responsive mb-3">
					<div className="bg-white p-3 mt-4 filter-and-table-container">
						<div className="container-head-admin mb-4"></div>
						<div className="container-coolapse-questions mt-5">
							{!isLoading ? (
								data && data?.length > 0 ? (
									<>
										{data?.map((item) => (
											<details
												className="card card-collapse-questioncard mb-3"
												key={item?.slug}
												onClick={() => handleClick(item)}
											>
												<summary className="card__header d-flex justify-content-between pt-1 px-2">
													<h6 className="title-collapse-question mb-0 fw-300">
														{item?.message}
													</h6>
													<div className="d-flex align-items-center gap-2">
														<ButtonDelete
															onClick={() => onDelete(item?.slug)}
														/>
													</div>
												</summary>
											</details>
										))}
									</>
								) : (
									<AlertInfo message="Aucune donnée trouvée" />
								)
							) : (
								<></>
							)}
						</div>
					</div>
					<Toaster position="top-right" />
				</div>
			</div>
		</div>
	);
};
export default Notifications;
