import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./Blog.css";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import { createMarkup, getImage, useLocationState } from "../../../utils/Utils";
import { IArticle } from "../../../utils/api/article/article.type";
import moment from "moment";
import { Link } from "react-router-dom";
import { ArticlesList } from "./Blog";

function DetailBlog() {
	const item = useLocationState<IArticle>(undefined);
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<div className="container-app-yeewuna">
					<div className="detail-blog pb-5">
						<div className="position-relative mb-5">
							<div className="img-container">
								<img
									src={getImage(item?.image)}
									alt={item?.title}
									className="w-100  blog-img"
								/>
							</div>
							<div className="blog-infos-container">
								<span className="blog-infos published-date">
									{moment(item?.created_at).format("DD MMMM YYYY")}
								</span>
								<span className="blog-infos categorie">
									<svg
										width="13"
										height="14"
										viewBox="0 0 13 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.6227 0.88303C12.5024 0.761343 12.3591 0.664809 12.2012 0.59905C12.0432 0.533291 11.8737 0.499622 11.7026 0.500003H8.13548C7.90149 0.500439 7.67711 0.593132 7.51103 0.757966L0.379983 7.88748C0.136657 8.1313 0 8.4617 0 8.80616C0 9.15063 0.136657 9.48102 0.379983 9.72484L3.77503 13.1199C4.01892 13.3633 4.34942 13.5 4.69401 13.5C5.0386 13.5 5.36911 13.3633 5.613 13.1199L12.7411 5.99324C12.9063 5.82743 12.9993 5.60311 13 5.36908V1.79997C13.001 1.62976 12.9682 1.46103 12.9035 1.30361C12.8387 1.14619 12.7433 1.00323 12.6227 0.88303ZM10.2143 4.2142C10.0306 4.2142 9.85112 4.15974 9.69842 4.05771C9.54572 3.95568 9.4267 3.81066 9.35642 3.64099C9.28614 3.47132 9.26775 3.28462 9.30358 3.1045C9.33941 2.92438 9.42785 2.75893 9.55771 2.62907C9.68757 2.49921 9.85302 2.41077 10.0331 2.37494C10.2133 2.33911 10.4 2.3575 10.5696 2.42778C10.7393 2.49806 10.8843 2.61708 10.9864 2.76978C11.0884 2.92247 11.1429 3.102 11.1429 3.28565C11.1429 3.53192 11.045 3.7681 10.8709 3.94223C10.6967 4.11637 10.4606 4.2142 10.2143 4.2142Z"
											fill="white"
										/>
									</svg>
									{item?.category?.name}
								</span>
							</div>
						</div>
						<h1 className="article-title mb-5">{item?.title}</h1>
						<div
							className="article-content"
							dangerouslySetInnerHTML={createMarkup(item?.content)}
						/>
					</div>
					<div className="others-articles">
						<div className="d-flex align-items-center justify-content-between">
							<h4>Autres</h4>
							<Link to="/blog" className="btn-view-more">
								Voir Plus
							</Link>
						</div>
						<ArticlesList perPage={3} />
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
}

export default DetailBlog;
