/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../../redux/hooks";
import { cleannerError } from "../../../../../utils/Utils";
import {
	Book,
	FavorisBookFormData,
	IFavoris,
	ILike,
	INotice,
	LikeData,
	NoticeFormData,
} from "../../../../../utils/api/book/book.type";
import {
	useAddFavorisMutation,
	useAddOrEditNoticeMutation,
	useDeleteFavorisMutation,
	useGetFavorisByBookQuery,
	useGetLikesNoticeQuery,
	useGetNoticeByBookQuery,
	useToggleDislikeNoticeMutation,
	useToggleLikeNoticeMutation,
} from "../../../../../utils/api/book/book.api";
import { toast } from "react-hot-toast";

export function useSendNotice(book: Book) {
	const validationSchema = yup.object().shape({
		message: yup.string().required("Ce champ est obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<NoticeFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [sendNotice, { isLoading }] = useAddOrEditNoticeMutation();
	const { user: authUser } = useAppSelector((s) => s.user);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: NoticeFormData) => {
		const newData: NoticeFormData = {
			...data,
			user: authUser?.id,
			book: book?.id,
			type: "avis",
		};
		console.log("data", newData);

		const res = await sendNotice({
			data: newData,
		});

		if ("data" in res) {
			toast.success("Avis envoyé avec succès!");
			reset();
		}
		if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}
export function useRateBook(book: Book) {
	const { user } = useAppSelector((s) => s?.user);
	const { data } = useGetNoticeByBookQuery({ slug: book?.slug, type: "note" });
	// console.log({ data });
	const [rateBook] = useAddOrEditNoticeMutation();
	const { user: authUser } = useAppSelector((s) => s.user);
	const [rate, setRate] = useState<number>(0);
	// console.log({ rate });
	const [isRate, setIsRate] = useState<INotice | null>(null);
	useEffect(() => {
		if (data && data?.length > 0) {
			const rate = data?.reduce((acc, item) => acc + item?.evaluation, 0) / data?.length;
			setRate(rate);
		}
	}, [data]);
	useEffect(() => {
		if (data) {
			const isRate = data?.find((item) => item?.user?.id === user?.id);
			setIsRate(isRate || null);
		}
	}, [data]);

	const onRateBook = async (evaluation: number) => {
		const newData: NoticeFormData = {
			evaluation,
			user: authUser?.id,
			book: book?.id,
			type: "note",
		};
		console.log("data", newData);

		const res = await rateBook({
			slug: isRate?.slug,
			data: newData,
		});

		if ("data" in res) {
			toast.success("Noté avec succès!");
		}
		if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		onRateBook,
		rate,
		setRate,
		totalRates: data?.length,
	};
}
export function useToggleFavoriBook(book?: Book) {
	const [isFavoris, setIsFavoris] = useState<IFavoris | null>(null);
	const { user } = useAppSelector((s) => s?.user);
	const [addFavoris] = useAddFavorisMutation();
	const [DeleteFavoris] = useDeleteFavorisMutation();
	const { data } = useGetFavorisByBookQuery({ slug: book?.slug });
	// console.log({ isFavoris });

	useEffect(() => {
		if (data) {
			const isFavoris = data?.find((item) => item?.user?.id === user?.id);
			setIsFavoris(isFavoris || null);
		}
	}, [data]);

	const handleToggleFavoris = async () => {
		const data: FavorisBookFormData = {
			book: book?.id as number,
			user: user?.id,
		};
		console.log({ data });
		if (!isFavoris) {
			const res = await addFavoris(data);
			console.log("res",res)
			if ("data" in res) {
				toast.success("Ajouté aux favoris avec succès!");
			} else if ("error" in res) {
				toast.error("Une erreur a survenu!");
			}
		} else {
			const res = await DeleteFavoris(isFavoris?.slug);
			if ("data" in res) {
				toast.success("Retiré avec succès!");
			} else if ("error" in res) {
				toast.error("Une erreur a survenu!");
			}
		}
	};

	return {
		isFavoris,
		handleToggleFavoris,
		user,
		setIsFavoris,
		DeleteFavoris,
	};
}

export function useToggleLikeNotice(notice?: INotice) {
	const [isLike, setIsLike] = useState<ILike | null>(null);
	const { user } = useAppSelector((s) => s?.user);
	const [toggleLike] = useToggleLikeNoticeMutation();
	const { data } = useGetLikesNoticeQuery({ slug: notice?.slug });
	console.log({ data });

	useEffect(() => {
		if (data) {
			const isLike = data?.find((item) => item?.user === user?.id);
			setIsLike(isLike || null);
		}
	}, [data]);

	const handleToggleLikeNotice = async () => {
		const data: LikeData = {
			notice: notice?.id as number,
			user: user?.id,
		};
		console.log({ data });
		const res = await toggleLike(data);
		if ("data" in res) {
			console.log("succès!");
		} else if ("error" in res) {
			console.log("Une erreur a survenu!");
		}
	};

	return {
		isLike,
		handleToggleLikeNotice,
		user,
		setIsLike,
	};
}

export function useToggleDisLikeNotice(notice?: INotice) {
	const [isDislike, setIsDislike] = useState<IFavoris | null>(null);
	const { user } = useAppSelector((s) => s?.user);
	const [toggleDislike] = useToggleDislikeNoticeMutation();
	const { data } = useGetFavorisByBookQuery({ slug: notice?.slug });
	// console.log({ isDislike });

	useEffect(() => {
		if (data) {
			const isDislike = data?.find((item) => item?.user?.id === user?.id);
			setIsDislike(isDislike || null);
		}
	}, [data]);

	const handleToggleDislikeNotice = async () => {
		const data: LikeData = {
			notice: notice?.id as number,
			user: user?.id,
		};
		console.log({ data });
		const res = await toggleDislike(data);
		if ("data" in res) {
			console.log("succès!");
		} else if ("error" in res) {
			console.log("Une erreur a survenu!");
		}
	};

	return {
		isDislike,
		handleToggleDislikeNotice,
		user,
		setIsDislike,
	};
}
