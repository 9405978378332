import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { useDeleteFaqMutation } from "../../../../utils/api/faq/faq.api";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import { Color } from "../../../../utils/theme";
import { ButtonDelete, ButtonEdit } from "../../../common/Button";
import { createMarkup } from "../../../../utils/Utils";
import { useDelete, useToggle } from "../../../../utils/hooks";
import VolkenoSkeleton from "volkeno-react-skeleton";

export function DeleteFaq({ item }: { item?: IFaq }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteFaqMutation();
	const onDelete = useDelete<IFaq>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette question ?",
		successMessage: "Question supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

type FaqProps = {
	item?: IFaq;
	openEditModal: (item?: IFaq) => void;
};
export const FaqItem = ({ item, openEditModal }: FaqProps) => {
	const { isActive, toggleActive } = useToggle();
	return (
		<details className="card card-collapse-questioncard mb-3 px-2 py-3">
			<summary
				className="card__header d-flex justify-content-between align-items-center"
				onClick={toggleActive}
			>
				<h6 className="title-collapse-question mb-0">{item?.question}</h6>
				<div className="d-flex align-items-center gap-2" onClick={toggleActive}>
					<ButtonEdit onClick={() => openEditModal(item)} />
					<DeleteFaq item={item} />
					<div
						onClick={toggleActive}
						role="button"
						style={{ color: Color.themeColor, fontSize: "20px" }}
					>
						{isActive ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
					</div>
				</div>
			</summary>
			<div className="card__body">
				<div
					className="mb-0 faq-text"
					dangerouslySetInnerHTML={createMarkup(item?.answer)}
				/>
			</div>
		</details>
	);
};

export const FaqItemSkeleton = () => {
	return (
		<div className="card card-collapse-questioncard mb-3">
			<div className="card__header d-flex justify-content-between align-items-center gap-3">
				<div className="flex-fill">
					<VolkenoSkeleton width="100%" height={15} variant="rect" />
				</div>
				<VolkenoSkeleton width={100} height={15} variant="rect" />
			</div>
		</div>
	);
};
