import React from "react";
import { RiCheckDoubleFill } from "react-icons/ri";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { ChatData } from "../../../../utils/api/chat/chat.type";
import { getAvatar, getName } from "../../../../utils/Utils";
import moment from "moment";
import { Color } from "../../../../utils/theme";

type Props = {
	item: ChatData;
	onClick: () => void;
	active: boolean;
};
const SommaireItem = ({ item, active, onClick }: Props) => {
	const send = item.lastMessage.sender.id !== item.user.id;
	return (
		<li className="list-group-item list-group-item-meessage linkable" onClick={onClick}>
			<div>
				<div className="bloc-profil-contact">
					<div className="d-flex align-items-center w-50">
						<img
							src={getAvatar(item.user?.avatar)}
							className="img-messge-contact"
							alt={getName(item?.user)}
						/>
						<p
							className="nom-contact ps-3 mb-0"
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
								color: active ? Color.success : "",
							}}
						>
							{getName(item?.user)}
						</p>
					</div>
					<div>
						<p className="time-message-contact mb-0">
							{moment(item?.lastMessage?.created_at).format("DD MMMM YYYY")}
						</p>
					</div>
				</div>
				<div className="bloc-message-contact">
					<div className="w-85 ps-5">
						{/* {item.count > 0 && (
							<div className="">
								<span>
									<span className="statut-message-tabs-non-lu">{item.count}</span>
								</span>
							</div>
						)} */}
					</div>
					{send && (
						<div>
							<span>
								<span className="statut-message-tabs-traite">
									<RiCheckDoubleFill />
								</span>
							</span>
						</div>
					)}
				</div>
			</div>
		</li>
	);
};
export function DiscussionItemSkeleton() {
	return (
		<li className="list-group-item list-group-item-meessage linkable">
			<div>
				<div className="d-flex align-items-center gap-3">
					<VolkenoSkeleton
						variant="rect"
						width={40}
						height={40}
						style={{ borderRadius: "50%" }}
					/>
					<div className="flex-fill">
						<VolkenoSkeleton
							variant="rect"
							width="80%"
							height={10}
							style={{ borderRadius: "2%" }}
						/>
					</div>
				</div>
			</div>
			<div className="bloc-message-contact">
				<div className="w-85">
					<VolkenoSkeleton
						variant="rect"
						width="100%"
						height={10}
						style={{ borderRadius: "2%" }}
					/>
				</div>
			</div>
		</li>
	);
}

export default SommaireItem;
