import React from 'react'
import CommandeTextTable from './table/CommandeTextTable';
import { useAppSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { hasAccess } from '../../../../routes/routerUtils';

function CommandeTextManager() {
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "commande_texte")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
  return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Commande de texte</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<CommandeTextTable />
			</div>
		</div>
  );
}

export default CommandeTextManager