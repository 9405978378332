import ReactQuill from "react-quill";
import { FormError, InputAdmin } from "../../../common/Input";
import useAddOrEditConditionForm from "./hooks/useAddOrEditConditionForm";
import { BtnSubmit } from "../../../common/Button";
import { conditionType } from "../../../../utils/api/condition/condition.type";

export function ConditionForm() {
	const { onSubmit, errors, isLoading, description, onchange, register, type } =
		useAddOrEditConditionForm();
	return (
		<>
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">
						{type == conditionType.cgu
							? "Conditions générales d’utilisation"
							: "Politiques de confidentialités"}
					</h3>
				</div>
			</div>
			<div className="container-info-user-profil">
				<form className="form-add-student" onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-12">
							<InputAdmin
								type="description"
								label="Titre"
								id="title"
								placeholder="Titre"
								{...register("title")}
								error={errors.title}
							/>
						</div>
						<div className="col-md-12">
							<ReactQuill theme="snow" value={description} onChange={onchange} />
							<FormError error={errors?.description} />
						</div>
					</div>

					<div className="row row-content-btn-action mt-5">
						<div className="flex-r">
							<BtnSubmit label="Enregistrer" isLoading={isLoading} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
function AddCguAdmin() {
	return (
		<div className="setting-admin">
			<ConditionForm />
		</div>
	);
}

export default AddCguAdmin;
