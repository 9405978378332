import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetArticlesByAuthorQuery } from "../../../../../utils/api/article/article.api";
import { useGetPublishedBooksByAuthorQuery } from "../../../../../utils/api/book/book.api";
import {
	useAuthorFromLocation,
	useFollowAuthorMutation,
	useUnFollowAuthorMutation,
} from "../../../../../utils/api/user/user.api";
import { FollowData, IFollow, IUser } from "../../../../../utils/api/user/user.type";
import { usePagination } from "../../../../../utils/hooks";

export function useFollowAuthor(author?: IUser) {
	const [isFollow, setIsFollow] = useState<IFollow | null>(null);
	const { user } = useAppSelector((s) => s?.user);
	const [follow] = useFollowAuthorMutation();
	const [unFollow] = useUnFollowAuthorMutation();
	const navigate = useNavigate();

	useEffect(() => {
		if (author?.subscribers) {
			const isFollow = author?.subscribers?.find((item) => item?.subscriber?.id === user?.id);
			setIsFollow(isFollow || null);
		}
	}, [author]);

	const handleToggleFollow = async () => {
		const data: FollowData = {
			subscriber: user?.id,
			subscribed_to: author?.id as number,
		};
		console.log({ data });
		if (!isFollow) {
			const res = await follow(data);
			if ("data" in res) {
				toast.success("Abonné avec succès!");
			} else if ("error" in res) {
				toast.error("Une erreur a survenu!");
			}
		} else {
			const res = await unFollow(isFollow?.slug);
			if ("data" in res) {
				toast.success("Desabonné avec succès!");
			} else if ("error" in res) {
				toast.error("Une erreur a survenu!");
			}
		}
	};

	return {
		isFollow,
		handleToggleFollow,
		navigate,
		user,
	};
}

export function useAuthor() {
	const [item] = useAuthorFromLocation();
	const { isFollow, handleToggleFollow } = useFollowAuthor(item);
	const { data } = useGetArticlesByAuthorQuery({ slug: item?.slug });

	return {
		item,
		handleToggleFollow,
		isFollow,
		articles: data?.results,
	};
}

export function useGetPublishedBooksByAuthor() {
	const { limit, page } = usePagination(10);
	const { user } = useAppSelector((s) => s?.user);

	const { data = { results: [], count: 0 }, isLoading } = useGetPublishedBooksByAuthorQuery({
		slug: user?.slug,
		page,
		limit,
	});

	return {
		data,
		isLoading,
		books: data?.results,
	};
}
