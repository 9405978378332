import React from "react";
import "./GestionLivre.css";
import ListLivresTable from "./ListLivresTable/ListLivresTable";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { hasAccess } from "../../../../routes/routerUtils";
import { useAppSelector } from "../../../../redux/hooks";

const GestionLivre = () => {
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "livre")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Livres</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<NavLink to="/admin/livres/ajouter" className="btn btn-page-title-admin">
						<MdOutlineAdd />
						<span className="ps-2">Ajouter un livre</span>
					</NavLink>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<ListLivresTable />
			</div>
		</div>
	);
};

export default GestionLivre;
