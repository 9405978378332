/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser, UserFormData, UserType } from "../../../../../utils/api/user/user.type";
import { useAppSelector } from "../../../../../redux/hooks";
import {
	useAddOrEditUserMutation,
	useDeleteUserMutation,
	useLazySendAccessQuery,
} from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { cleannerError, getInTechTel } from "../../../../../utils/Utils";

function UseCrudAuthorForm(author?: IUser) {
	const [phone, setPhone] = useState<string | null>(null);
	const [code, setCode] = useState<string>("");
	const validationSchema = yup.object().shape({
		first_name: yup.string().required().label("Le prénom"),
		last_name: yup.string().required().label("Le nom"),
		adress: yup.string().label("L'adresse").nullable(),
		phone: yup.string().required().label("Le téléphone"),
		email: yup.string().email().required().label("L'email"),
		bio: yup.string().transform((val) => val === "<p><br></p>" ? "" : val).label("La description").nullable(),
		avatar: author?.slug
			? yup.mixed().label("Photo de profil").typeError("Photo de profil est obligatoire")
			: yup
					.mixed()
					.required()
					.label("Photo de profil")
					.typeError("Photo de profil est obligatoire"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
	});
	const navigate = useNavigate();

	const [sendData, { isLoading, isSuccess, error, isError, data }] = useAddOrEditUserMutation();
	const [avatar, setAvatar] = useState<any>(null);
	// const [phone, setPhone] = useState<string | null>(null);
	const [bio, setBio] = useState<string>("");
	const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files?.length) {
			setAvatar(e?.target?.files[0]);
			setValue("avatar", e?.target?.files[0]);
		}
	};
	const handleChangeBio = (val) => {
		setBio(val);
		setValue("bio", val);
	};
	const handleChangePhone = (phone: string,country: any) => {
		setValue("phone", phone);
		setPhone(phone);
		setCode(country?.countryCode);
	};
	useEffect(() => {
		if (author?.slug) {
			const fields: (keyof UserFormData)[] = [
				"first_name",
				"last_name",
				"email",
				"phone",
				"slug",
				"bio",
			];
			for (let field of fields) {
				register(field);
				setValue(field, author[field]);
			}
			if (author?.phone) {
				setPhone(getInTechTel(author?.phone));
			} 
		}
	}, [author, register, setValue]);

	useEffect(() => {
		if (isSuccess) {
			// console.log("data", data);
			if (data) {
				Swal.fire({
					icon: "success",
					iconColor: Color.success,
					confirmButtonColor: Color.success,
					title: !author
						? "Auteur ajouté avec succès!"
						: "Informations modifiées avec succès!",
					showConfirmButton: false,
					timer: 3000,
				}).then(() => {
					navigate(-1);
				});
			}
		}
		const err = error as any;

		if (isError) {
			console.log("error", err);

			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [data, error, isError, isSuccess, navigate]);
	useEffect(() => {
		console.log(errors)
		cleannerError(errors, clearErrors);
	}, [clearErrors, errors]);

	const onSubmit = async (data: UserFormData) => {
		if (!author) {
			data["user_type"] = "author";
		}
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		await sendData({ slug: data?.slug, userType: UserType.author, data: fd });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		avatar,
		handleChangeAvatar,
		phone,
		handleChangePhone,
		handleChangeBio,
		bio
	};
}

export default UseCrudAuthorForm;

export const useDeleteUser = (slug: string, message: string) => {
	const [deleteItem] = useDeleteUserMutation();

	const onDelete = async () => {
		await Swal.fire({
			title: message,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return deleteItem(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.isConfirmed) {
				if (result?.value && !result?.value?.error) {
					Swal.fire({
						icon: "success",
						title: `L'élément a été supprimé avec succès!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					let err = result?.value?.error;
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: `Une erreur de statut ${err?.status} est survenue!`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
			}
		});
	};
	return onDelete;
};

export function UseArchiveUserItem(item: IUser) {
	const [sendData] = useAddOrEditUserMutation();
	const { user } = useAppSelector((s) => s?.user);
	const onArchive = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir ${
				item?.is_archived ? "désarchiver" : "archiver"
			} cet utilisateur ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return sendData({
					slug: item?.slug,
					userType: user?.user_type,
					data: {
						is_archived: !item?.is_archived,
						is_active: item?.is_archived,
					},
				});
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.isConfirmed) {
				if (result?.value && !result?.value?.error) {
					console.log(result);
					Swal.fire({
						icon: "success",
						title: result?.value?.data?.is_archived
							? `Utilisateur archivé avec succès!`
							: `Utilisateur désarchivé avec succès!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 1500,
					});
				} else if (result?.value?.error) {
					Swal.fire({
						icon: "error",
						title: result?.value?.error?.data?.message
							? result?.value?.error?.data?.message
							: `Une erreur de statut ${result?.value?.error?.status} est survenue`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
			}
		});
	};
	return onArchive;
}

export const useSendAccess = (slug: string) => {
	const [sendAccess] = useLazySendAccessQuery();

	const onSend = async () => {
		await Swal.fire({
			title: "Êtes-vous sûr de vouloir renvoyer les accès à cet utilisateur ?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return sendAccess(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.isConfirmed) {
				if (result?.value && !result?.value?.error) {
					Swal.fire({
						icon: "success",
						title: `Les accès ont été envoyé avec succès!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					let err = result?.value?.error;
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: `Une erreur de statut ${err?.status} est survenue!`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
			}
		});
	};
	return onSend;
};
