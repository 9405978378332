import React from "react";
import { FiSearch } from "react-icons/fi";
import Modal from "react-modal";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import { customStylesSm } from "../../../../../utils/theme";
import { useGetAllUsersExceptMeQuery } from "../../../../../utils/api/user/user.api";
import { usePagination, useSearch } from "../../../../../utils/hooks";
import { getAvatar, getName } from "../../../../../utils/Utils";
import { IUser } from "../../../../../utils/api/user/user.type";
type Props = {
	isOpen: boolean;
	closeModal: () => void;
	onSelect: (user: IUser) => void;
};

const NewChatModal = ({ isOpen, closeModal, onSelect }: Props) => {
	const { page, limit, setPage } = usePagination(8);
	const { search, handleSearch } = useSearch();

	const { data } = useGetAllUsersExceptMeQuery({ page, limit, q: search });

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={customStylesSm}
			contentLabel="Example Modal"
		>
			<div className="modal-header pe-2 py-1">
				<h5 className="modal-title-admin" id="ChangePasswordModalLabel">
					Nouvelle discussion
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<div className="content-btn-send-message flex-r">
				<button
					className="btn btn-send-message-modal"
					onClick={() => setPage((page) => page + 1)}
				>
					Suivant
				</button>
			</div>
			<div className="pt-3">
				<div className="form-search-user-container position-relative">
					<input
						type="text"
						className="form-search-user form-control"
						placeholder="Rechercher des personnes"
						onChange={handleSearch}
					/>
					<FiSearch
						style={{
							color: "#919EAB",
							fontSize: 22,
							position: "absolute",
							top: "25%",
							left: "2%",
						}}
					/>
				</div>
			</div>
			<ul className="user-for-send-message-container mt-3  px-2">
				{data?.results?.map((item) => (
					<li
						className="user-for-send-message  mb-3 px-3 py-1"
						key={item?.slug}
						onClick={() => {
							onSelect(item);
							closeModal();
						}}
						role="button"
					>
						<div className="d-flex align-items-center gap-2">
							<div>
								<img
									src={getAvatar(item?.avatar)}
									alt="user-avatar"
									className="w-fluid img-profil-user-message"
								/>
							</div>
							<div className="user-for-send-message-infos">
								<h3 className="mb-0">{getName(item)}</h3>
								{/* <h4 className="mb-0">Online - Last seen, 2.02pm</h4> */}
							</div>
						</div>
					</li>
				))}
			</ul>
			{/* <MessageModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} /> */}
		</Modal>
	);
};

export default NewChatModal;
