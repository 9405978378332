import { yupResolver } from "@hookform/resolvers/yup";
import { useForm,Controller } from "react-hook-form";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";
import { useGetCategoriesQuery } from "../../../../../utils/api/category/category.api";
import { ForumFormData, ForumStatus, IForum } from "../../../../../utils/api/forum/forum.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useCreateOrEditPublicationMutation } from "../../../../../utils/api/forum/forum.api";

function useCrudPublication(item?: IForum) {
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		subject: yup.string().required().label("Titre"),
		text: yup.string().required().label("Texte"),
		categories: yup.array().required().label("Catégories"),
	});
	const { data, isLoading: loading } = useGetCategoriesQuery({limit:100});
	const { user: authUser } = useAppSelector((s) => s.user);

	const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
	const suggestions = data?.results?.map((item) => {
		return {
			id: item?.id,
			name: item?.name,
		};
	});

	const reactCatsRef = React.createRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		control
	} = useForm<ForumFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [createOrEditPublication, { isLoading }] = useCreateOrEditPublicationMutation();
	const [options, setOptions] = useState<{ label: string; value: number }[]>([]);
	const [option, setOption] = useState<{ label: string; value: number }[]>([]);

	useEffect(() => {
		if (data?.results) {
			setOptions(
				data?.results?.map((d) => {
					return {
						label: d?.name,
						value: d?.id,
					};
				})
			);
		}
	}, [data]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	// useEffect(() => {
	// 	if (item?.id) {
	// 		let fields: (keyof OfferFormData)[] = [
	// 			"title",
	// 			"price",
	// 			"description",
	// 			"frequence",
	// 			"position",
	// 			"duration",
	// 			"offer_text",
	// 		];
	// 		for (let field of fields) {
	// 			register(field);
	// 			setValue(field, item[field]);
	// 		}
	// 		setAdvantages(item?.description);
	// 	}
	// }, [item]);

	const handleSelectAuthor = (cats: any) => {
		setOption(cats)
		setValue("categories", cats?.map((item) => item?.value))
	}

	const onDelete = useCallback(
		(tagIndex) => {
			let cats = categories.filter((_, i) => i !== tagIndex);
			setCategories(cats);
			setValue(
				"categories",
				cats.map((item) => item?.id)
			);
		},
		[categories]
	);

	const onAddition = useCallback(
		(newTag) => {
			let cats = [...categories, newTag];
			setCategories(cats);
			setValue(
				"categories",
				cats.map((item) => item?.id)
			);
		},
		[categories]
	);

	const onSubmit = async (data: ForumFormData) => {
		const newData: ForumFormData = {
			...data,
			created_by: authUser?.id,
			status: ForumStatus.new,
		};
		console.log("data", newData);

		const res = await createOrEditPublication({ slug: item?.slug, data: newData });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Publiée avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				reset();
				navigate(-1);
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		categories,
		suggestions,
		reactCatsRef,
		onAddition,
		onDelete,
		handleSelectAuthor,
		options,
		option,
		control,
		Controller,
		loading
	};
}

export default useCrudPublication;
