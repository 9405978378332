import React from "react";
import Modal from "react-modal";
import { customStylesSm } from "../../../../../utils/theme";
import { InputPassword } from "../../../../common/Input";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import UseFormChangePassword from "../../ChangePassword/UseFormChangePassword";

const ChangePasswordModal = ({
  modalChangePassword,
  setModalChangePassword,
}) => {
  function closeModalChangePassword() {
    setModalChangePassword(false);
  }

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseFormChangePassword(closeModalChangePassword);

  return (
		<Modal
			isOpen={modalChangePassword}
			onRequestClose={closeModalChangePassword}
			style={customStylesSm}
			contentLabel="Example Modal"
		>
			<div className="modal-header flex-sb">
				<h5 className="modal-title-admin">Changer de mot de passe</h5>
				<CloseModalBtn onClick={closeModalChangePassword} />
			</div>

			<div className="pt-3">
				<form action="" onSubmit={onSubmit}>
					<div className="row py-3 px-2">
						<div className="col-12 mb-3">
							<InputPassword
								label="Ancien mot de passe"
								id="password"
								placeholder="Ancien mot de passe"
								{...register("old_password")}
								error={errors?.old_password}
							/>
						</div>
						<div className="col-12 mb-3">
							<InputPassword
								label="Nouveau mot de passe"
								id="new_password"
								placeholder="Nouveau mot de passe"
								onChange={passwordHandleChange}
								error={errors?.new_password}
							/>
						</div>
						<div className="col-md-12">
							<div className="input-group my-2 password-validation-terms-row">
								<div className="password-validation-terms-container">
									<h4 className="password-validation-terms-title">
										Votre mot de passe doit contenir :{" "}
									</h4>
									<ul className="password-validation-terms-ul p-l-14">
										<li
											className={
												"password-rule-item " +
												(haveMinCharacter && "text-success")
											}
										>
											Au minimum 8 caractères
										</li>
										<li
											className={
												"password-rule-item " +
												(haveMinUppercase && "text-success")
											}
										>
											Au minimum 1 caractère en majuscule
										</li>
										<li
											className={
												"password-rule-item " +
												(haveMinLowercase && "text-success")
											}
										>
											Au minimum 1 caractère en minuscule
										</li>
										<li
											className={
												"password-rule-item " +
												(haveMinNumber && "text-success")
											}
										>
											Au minimum 1 nombre
										</li>
										<li
											className={
												"password-rule-item " +
												(haveMinSpecialCharacter && "text-success")
											}
										>
											Au minimum 1 caractère spéciale
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-12 mb-4">
							<InputPassword
								label="Confirmer le mot de passe"
								id="confirm_password"
								placeholder="Confirmer le mot de passe"
								{...register("confirm_password")}
								error={errors?.confirm_password}
							/>
						</div>
						<div className="col-12">
							<button className="btn btn-page-title-admin w-100" disabled={isLoading}>
								{!!isLoading ? (
									<>
										<span
											className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
											role="status"
										></span>
										<span>En cours...</span>
									</>
								) : (
									"Changer"
								)}
							</button>
						</div>
					</div>
				</form>
			</div>
		</Modal>
  );
};

export default ChangePasswordModal;
