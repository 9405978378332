import React from "react";
import { useStep } from "react-hooks-helper";
import { useSearch } from "../../../../utils/hooks";
import { useStepType } from "../../../../utils/type";
import { SearchInput } from "../../../common/Input";
import Abonnement from "../../Auteur/ProfilAuteur/ProfilTabs/Abonnement";
import FilActualite from "./AuteurTabs/FilActualite";
import ListeAuteurs from "./AuteurTabs/ListeAuteurs";
import "./AuteurUtilisateur.css";

const steps = [
	{ id: "auteur", Component: ListeAuteurs },
	{ id: "actualite", Component: FilActualite },
	{ id: "abonnements", Component: Abonnement },
];

const tabs = ["Liste des auteurs", "Fil d’actualité", "Mes abonnements"];

function AuteurUtilisateur() {
	const {
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { search, handleSearch } = useSearch();
	const { search: author, handleSearch: handleSearchAuthor } = useSearch();
	return (
		<div className="dashbord-visiteur-component">
			<div className="user-dash-page-content-container mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="tabs-search-input-container">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										className={`tab-item  ${
											index === key && "tab-active-item"
										}`}
										key={key}
										onClick={() => go(key)}
										role="button"
									>
										{tab}
									</li>
								))}
							</ul>
							<div className="search-form-container">
								{index === 0 ? (
									<SearchInput label="" onChange={handleSearchAuthor} />
								) : (
									<SearchInput label="" onChange={handleSearch} />
								)}
							</div>
						</div>
						<div className="tabs-component-container">
							{index === 0 ? (
								<ListeAuteurs search={author} />
							) : index === 1 ? (
								<FilActualite search={search} />
							) : (
								<Abonnement />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AuteurUtilisateur;
