import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import "./Bibliotheque.css";
import Favoris from "./BibliothequeTabs/Favoris";
import LivresAchetes from "./BibliothequeTabs/LivresAchetes";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

const steps = [
	{ id: "livres", Component: LivresAchetes },
	{ id: "favoris", Component: Favoris },
];

const tabs = ["Livres achetés", "Favoris"];

function Bibliotheque() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	const { search } = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if (search?.includes("status=success")) {
			Swal.fire({
				icon: "success",
				title: "Votre paiement a été effectué avec succès !",
				iconColor: Color.success,
				showConfirmButton: true,
			}).then(() => navigate("/utilisateur/bibliotheque", { replace: true }));
		} else if (search?.includes("status=error")) {
			Swal.fire({
				icon: "error",
				title: "Erreur de paiement",
				iconColor: Color.red,
				showConfirmButton: true,
			}).then(() => navigate("/utilisateur/bibliotheque", { replace: true }));
		}
	}, [search]);
	return (
		<div className="dashbord-visiteur-component">
			<div className=" mb-3">
				<div className="yeewuna-parcours-utilisateur-titre-parcourir-container mb-4">
					<div className="yeewuna-tabs-container py-3">
						<div className="yeewuna-page-bibliotheque-titre">Ma bibliothèque</div>
						<hr className="yeewuna-page-bibliotheque-titre-divider" />
						<div className="tabs-search-input-container mt-3">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										className={`tab-item  ${
											index === key && "tab-active-item"
										}`}
										key={key}
										onClick={() => go(key)}
										role="button"
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
						<div className="tabs-component-container">
							<Component />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Bibliotheque;
