import React from "react";
import DevisTable from "./DevisTable";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { hasAccess } from "../../../../routes/routerUtils";

const Devis = () => {
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "demande_devis")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin mb-3">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Démandes de devis</h3>
				</div>
			</div>
			<DevisTable />
		</div>
	);
};

export default Devis;
