import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useMeQuery } from "./utils/api/user/user.api";
import { onlogout, onSetUser } from "./redux/slice/User.slice";
import { Toaster } from "react-hot-toast";

function FetchUser() {
	const { user } = useAppSelector((s) => s?.user);
    const { isSuccess, data, isError } = useMeQuery();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isSuccess) {
            // console.log("user", data);
            if (data?.id === user?.id) {
                dispatch(onSetUser(data));
            }
        }

        if (isError) {
            dispatch(onlogout());
        }
    }, [isSuccess, data, isError]);
	const { pathname } = useLocation();

	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
	return null;
}

function App() {
	const routes = useRoutes(AppRoutes);
	const isConnected = useAppSelector((s) => !!s.user?.token);

	return (
		<div className="app-routing-container">
			{isConnected && <FetchUser />}
			{routes}
			<Toaster/>
		</div>
	);
}

export default App;
