import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "./Input";

type InputPropsType = InputHTMLAttributes<HTMLSelectElement> & {
	label: string;
	error?: string | FieldError;
	hiddenFirstOption?: boolean;
	options?: {
		label: string;
		value: string | number;
	}[];
};
export const SelectInput = React.forwardRef<HTMLSelectElement, InputPropsType>((props, ref) => {
	const { name, error, label, options, hiddenFirstOption, required, ...rest } = props;
	return (
		<div className="mb-3">
			<label
				htmlFor="nombre_stock"
				className="form-label form-label-modal-custom"
				aria-labelledby={name}
			>
				{label}
				{required && (
					<span className="text-danger" style={{ fontSize: 17 }}>
						*
					</span>
				)}
			</label>
			<select
				name={name}
				id={name}
				ref={ref}
				className="form-select form-select-modal-custom mb-2"
				{...rest}
			>
				{!hiddenFirstOption && <option value="">{label}</option>}
				{options?.map((option) => (
					<option value={option.value} key={option.value}>
						{option.label}
					</option>
				))}
			</select>
			{<FormError error={error} />}
		</div>
	);
});
