import React from "react";
import { NavLink } from "react-router-dom";

const BannerPage = () => {
	return (
		<div className="banner-page-container">
			<div className="row row-banner pt-md-4">
				<div className="col-lg-6 col-left-banner-home-page">
					<div className="content-col-left-home-page">
						<h1 className="title-home-page-banner">
							Yeewuna, tout le <br /> plaisir de la
							<br /> lecture
						</h1>
						<div className="content-text-description-home-page-banner my-2 my-md-4 banner-infos">
							{/* <li className="text-description-home-page-banner">
								Découvrez Yeewuna, l'expérience de lecture ultime pour les amoureux
								des livres. Toujours continuer de progresser grâce à la lecture !
							</li> */}
							<p className="text-description-home-page-banner">
								Yeewuna, la plateforme de vente de livres idéale pour les auteurs en
								quête de visibilité et de succès ! Découvrez différentes manières
								d’exploiter vos ouvrages.
							</p>
						</div>
						<div className="content-btn-connect-banner">
							<NavLink
								className="btn btn-connect-banner btn-orange"
								to="/inscription"
							>
								Commencer maintenant
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BannerPage;
