import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { LoginFormData, SocialRegisterFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError, convertToLowerExceptFirst } from "../../../utils/Utils";
import { onSetUserToken, onSetUserTokenOnLocalStorage } from "../../../redux/slice/User.slice";
import { useAppDispatch } from "../../../redux/hooks";
import {
	useLoginUserMutation,
	useLoginUserWithSocialMediaMutation,
} from "../../../utils/api/auth/auth.api";
import { useLocation, useNavigate } from "react-router-dom";
import { UserType } from "../../../utils/api/user/user.type";
import { useLazyMeAdminQuery } from "../../../utils/api/admin/admin.api";
import { AppStorage } from "../../../utils/storage";
import { IResolveParams } from "reactjs-social-login";

function useLoginForm() {
	const dispatch = useAppDispatch();
	const validationSchema = yup.object().shape({
		email: yup.string().required().label("Email"),
		password: yup.string().required().label("Mot de passe"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<LoginFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [loginUser, { isLoading }] = useLoginUserMutation();
	const { search } = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if (search?.includes("status=success")) {
			Swal.fire({
				icon: "success",
				title: "Votre paiement a été effectué avec succès, vous pouvez utiliser la plateforme en vous connectant",
				iconColor: Color.success,
				showConfirmButton: true,
			}).then(() => navigate("/connexion", { replace: true }));
		} else if (search?.includes("status=error")) {
			Swal.fire({
				icon: "error",
				title: "Erreur de paiement",
				iconColor: Color.red,
				showConfirmButton: true,
			}).then(() => navigate("/connexion", { replace: true }));
		}
	}, [search]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors, clearErrors]);

	const onSubmit = async (data: LoginFormData) => {
		console.log({ data });
		const { remember_me, ...rest } = data;
		console.log({ rest });
		// if(remember_me)
		if (rest?.email) {
			rest["email"] = (data?.email).toLowerCase();
		}
		console.log(rest);
		const res = await loginUser(rest);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Connexion réussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
			if (remember_me) {
				localStorage.setItem("remember", remember_me ? "1" : "0");
				dispatch(
					onSetUserTokenOnLocalStorage({ user: res.data.data, token: res.data.token })
				);
			} else {
				dispatch(onSetUserToken({ user: res.data.data, token: res.data.token }));
			}
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;

			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			setError("email", { message: message });
		}
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useLoginForm;

export function socialLogin() {
	const [socialLoginUser] = useLoginUserWithSocialMediaMutation();
	const dispatch = useAppDispatch();
	const [fetchMe] = useLazyMeAdminQuery();
	const onsocialLogin = async (data: SocialRegisterFormData) => {
		console.log({ data });
		const res = await socialLoginUser(data);
		if ("data" in res) {
			const { data } = res;
			AppStorage.setItem("user", {
				token: data?.token,
			});
			if (data?.data?.user_type === UserType.admin) {
				const res = await fetchMe();
				if ("data" in res) {
					const { data: adminData } = res;
					// console.log("admin", adminData);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const user = AppStorage.getItem("user") as any;
					dispatch(onSetUserToken({ user: adminData, token: user?.token }));
				}
			}
			Swal.fire({
				icon: "success",
				title: "Connexion reussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				if (data?.data?.user_type !== UserType.admin) {
					dispatch(onSetUserToken({ user: data?.data, token: data?.token }));
				}
			});
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log("err", err.data);
			const { message = "Une erreur a survenue lors de la connexion" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
	};

	return onsocialLogin;
}

export function useSocialLogin() {
	const onSocialLogin = socialLogin();

	const loginWithGoogle = ({ data }: IResolveParams) => {
		// console.log({ data });
		const authUserData: SocialRegisterFormData = {
			email: data?.email as string,
			first_name: data?.given_name,
			last_name: convertToLowerExceptFirst(data?.family_name),
		};
		console.log({ authUserData });
		onSocialLogin(authUserData);
	};

	return {
		loginWithGoogle,
	};
}
