export const Color = {
	jaune: "#FCAE3B",
	bleu: "#1F3CAD",
	vert: "#1AA981",
	themeColor: "#E73628",
	success: "#1AA981",
	red: "#C22A29",
	blueDark: "#283262",
};


export const customStylesXSm = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "25%",
		zIndex: 99,
		overflowY: "auto",
		height: "700px",
	},
};

export const customStylesSm = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "30%",
		zIndex: 99,
		overflowY: "auto",
		height: "700px",
	},
};

export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "50%",
		zIndex: 99,
		overflowY: "auto",
		height: "700px",
	},
};