import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { Subscription, SubscriptionFormData } from "./subscription.type";
import { QueryUrl } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";

export const SubscriptionApi = createApi({
	reducerPath: "subscriptionApi",
	tagTypes: ["subscriptionByUser", "subscriptionList", "subscriptionBySlug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getSubscriptions: builder.query<PaginationResults<Subscription>, TypeQuery>({
			providesTags: ["subscriptionList"],
			query: (query) => {
				return QueryUrl(`subscription/`, query);
			},
		}),
		addOrEditSubscription: builder.mutation<Subscription, SubscriptionFormData>({
			invalidatesTags: ["subscriptionByUser", "subscriptionList", "subscriptionBySlug"],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `subscription/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `subscription/`,
					method: "POST",
					body: data,
				};
			},
		}),
		getSubscriptionBySlug: builder.query<Subscription, string>({
			query: (slug) => `subscription/${slug}`,
			providesTags: ["subscriptionBySlug"],
		}),
	}),
});

export const {
	useAddOrEditSubscriptionMutation,
	useGetSubscriptionBySlugQuery,
	useGetSubscriptionsQuery,
} = SubscriptionApi;
