import { MdAddShoppingCart } from "react-icons/md";
import StarRatingComponent from "react-star-rating-component";
import { BsChatRightText, BsMicFill, BsPhone } from "react-icons/bs";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FiBook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Book } from "../../../../utils/api/book/book.type";
import { getImage, formatMontant, getName } from "../../../../utils/Utils";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useRateBook, useToggleFavoriBook } from "../Bibliotheque/hooks/useBook";

export default function LivreItem({ item }: { item: Book }) {
	const { isFavoris, handleToggleFavoris } = useToggleFavoriBook(item);
	const { rate } = useRateBook(item);
	return (
		<div className="yeewuna-livre-item">
			<div className="yeewuna-couverture-du-livres-container">
				<div className="container-yeewuna-couverture-du-livres">
					<img
						src={getImage(item?.couverture)}
						alt="photos de couverture du livre"
						className="yeewuna-couverture-du-livres image"
					/>
				</div>
				<div className="middle">
					<div className="yeewuna-couverture-btn-container">
						{!!item?.epub && (
							<Link
								to={`/utilisateur/livre/${item?.slug}`}
								className="btn yeewuna-couverture-btn"
								state={item}
							>
								<BsPhone /> E-book <span>{formatMontant(item?.pdf_price)}</span>
							</Link>
						)}

						<Link
							to={`/utilisateur/bibliotheque/details-livre/${item?.slug}`}
							className="btn yeewuna-couverture-btn"
							state={item}
						>
							<FiBook /> Livre <span>{formatMontant(item?.price)}</span>
						</Link>
						{!!item?.audio && (
							<Link
								to={`/utilisateur/bibliotheque/livre-audio/${item?.slug}`}
								className="btn yeewuna-couverture-btn"
								state={item}
							>
								<BsMicFill /> Audiobook{" "}
								<span>{formatMontant(item?.audio_price)}</span>
							</Link>
						)}
					</div>
				</div>
			</div>
			<div className="yeewuna-livre-item-body">
				<div className="yeewuna-titre-du-livre-container mb-1">
					<div className="yeewuna-titre-du-livres">
						<Link
							to={`/utilisateur/bibliotheque/details-livre/${item?.slug}`}
							className="no-link"
							state={item}
						>
							{item?.title}
						</Link>
					</div>
					<div className="yeewuna-titre-du-livres-icone-container">
						<Link to={`/utilisateur/bibliotheque/livre/${item?.slug}`} state={item}>
							<MdAddShoppingCart className="yeewuna-titre-du-livres-icone" />
						</Link>
						<Link to={`/utilisateur/bibliotheque/livre/${item?.slug}`} state={item}>
							<BsChatRightText className="yeewuna-titre-du-livres-icone" />
						</Link>
						{isFavoris ? (
							<AiFillHeart fontSize="16px" color="#f8bf5c" onClick={() => handleToggleFavoris()} className="linkable" />
						) : (
							<AiOutlineHeart
								className="yeewuna-titre-du-livres-icone linkable"
								onClick={() => handleToggleFavoris()}
							/>
						)}
					</div>
				</div>
				<h5 className="yeewuna-autheur-du-livres mb-2">{getName(item?.author)}</h5>
				<div className="yeewuna-rate-star-container">
					{/* <AiFillStar className="yeewuna-rate-star-fill" />
					<AiFillStar className="yeewuna-rate-star-fill" />
					<AiFillStar className="yeewuna-rate-star-fill" />
					<AiFillStar className="yeewuna-rate-star-fill" />
					<AiOutlineStar className="yeewuna-rate-star-outline" /> */}
					<StarRatingComponent
						starCount={5}
						name="rate__book"
						editing={false}
						value={rate}
						starColor="#F8BF5C"
						emptyStarColor="#BDBDBD"
					/>
				</div>
			</div>
		</div>
	);
}
export function LivreItemSkeleton() {
	return (
		<div className="col-md-6 col-lg-3">
			<div className="yeewuna-couverture-du-livres-container">
				<VolkenoSkeleton
					variant="rect"
					className="yeewuna-couverture-du-livres image"
					style={{ borderRadius: 0 }}
				/>
			</div>
			<div className="yeewuna-livre-item-body">
				<div className="yeewuna-titre-du-livre-container mb-1">
					<VolkenoSkeleton variant="rect" width={100} height={10} />
					<div className="yeewuna-titre-du-livres-icone-container">
						<VolkenoSkeleton variant="rect" width={20} height={20} />
						<VolkenoSkeleton variant="rect" width={20} height={20} />
						<VolkenoSkeleton variant="rect" width={20} height={20} />
					</div>
				</div>
				<VolkenoSkeleton variant="rect" width="100%" height={10} className="mb-2" />
				<VolkenoSkeleton variant="rect" width="100%" height={10} />
			</div>
		</div>
	);
}
