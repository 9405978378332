import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "./theme";
import { IQuery, QueryError } from "./type";

export function useDelete<T>(props: IQuery<T>) {
	const { item, deleteItem, isSuccess, isError, error, question, successMessage, redirectUrl } =
		props;
	const navigate = useNavigate();
	const onDelete = useCallback(async () => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir supprimer ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				deleteItem(item?.slug);
			},
		});
	}, []);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (redirectUrl) {
					navigate(redirectUrl);
				}
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}

export function usePagination(initialPerPageValue: number) {
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(initialPerPageValue);
	return {
		page,
		setPage,
		limit: perPage,
		setPerPage,
		perPage,
	};
}

export function useModal<T>() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
	const [item, setItem] = useState<T>();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	const openEditModal = (item?: T) => {
		setItem(item);
		toggle();
	};
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		isShowModal,
		toggle,
		openModal,
		openEditModal,
		closeModal: openModal,
		item,
		getItem,
	};
}

export function useItem<T>() {
	const [item, setItem] = useState<T>();
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		item,
		getItem,
	};
}

export function useSearch() {
	const [search, setSearch] = useState<string>();
	const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value) {
			setSearch(e.currentTarget.value);
		} else {
			setSearch("");
		}
	};

	return {
		search,
		handleSearch,
	};
}

export function useToggleModal() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);

	return {
		isShowModal,
		toggle,
		openModal: toggle,
		closeModal: toggle,
	};
}

export function useToggle() {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleActive = () => {
		setIsActive((isActive) => !isActive);
	};

	return {
		isActive,
		toggleActive,
	};
}
