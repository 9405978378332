import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IArticle, ArticleFormData, SimilarArticleResult } from "./article.type";

export const ArticleApi = createApi({
	reducerPath: "articlesApi",
	tagTypes: ["blog", "similarArticles", "blogByAuthor"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		getArticles: build.query<PaginationResults<IArticle>, TypeQuery>({
			query: (query) => QueryUrl(`blog/`, query),
			providesTags: ["blog"],
		}),
		getSimilarArticles: build.query<SimilarArticleResult, string>({
			query: (slug) => `visiteur/${slug}/blog/`,
			providesTags: ["blog", "similarArticles"],
		}),
		getArticlesForVisitors: build.query<PaginationResults<IArticle>, TypeQuery>({
			query: (query) => QueryUrl(`visitor/blog/`, query),
			providesTags: ["blog"],
		}),
		getArticlesByAuthor: build.query<PaginationResults<IArticle>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`author/${slug}/blogs/`, query),
			providesTags: ["blog"],
		}),
		addOrUpdateArticle: build.mutation<
			IArticle,
			{ slug?: string; data: ArticleFormData | FormData }
		>({
			invalidatesTags: ["blog"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `blog/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `blog/`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: ({ data }) => data,
		}),
		deleteArticle: build.mutation<IArticle, string>({
			query: (slug) => ({
				url: `blog/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["blog"],
		}),
	}),
});

export const {
	useGetArticlesQuery,
	useLazyGetArticlesQuery,
	useDeleteArticleMutation,
	useAddOrUpdateArticleMutation,
	useGetArticlesForVisitorsQuery,
	useGetSimilarArticlesQuery,
	useGetArticlesByAuthorQuery,
} = ArticleApi;
