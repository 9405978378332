import React from "react";
import { BsCameraFill } from "react-icons/bs";
import Modal from "react-modal";
import { IAdmin } from "../../../../../utils/api/admin/admin.type";
// import { customStyles } from "../../../../../utils/theme";
import { IModalProps } from "../../../../../utils/type";
import { getAvatar } from "../../../../../utils/Utils";
import { BtnSubmit } from "../../../../common/Button";
import { FormError, InputAdmin } from "../../../../common/Input";
import CloseModalBtn from "../../../../shared/CloseModalBtn";
import useCrudAdmin from "../hooks/useCrudAdmin";
import PhoneInput from "react-phone-input-2";
import { Authorisations } from "../../../../../utils/constante";

Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "75%",
		zIndex: 99,
		overflowY: "auto",
		height: "700px",
	},
};
const AddAdminModal: React.FC<IModalProps<IAdmin>> = ({ isOpen, closeModal, item }) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		avatar,
		handleChangeAvatar,
		handleChangePhone,
		phone,
	} = useCrudAdmin(closeModal, item);
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="addAdminModal"
		>
			<div className="modal-header pe-2 py-1">
				<h5 className="modal-title-admin" id="ChangePasswordModalLabel">
					{item ? "Modifer l'" : "Ajouter un"} admin
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<form className="mt-4" onSubmit={onSubmit}>
				<div className="row row-add-student">
					<div className="col-md-12 mb-4">
						<div className="content-pp-profil-admin text-center pb-3">
							<div className="d-flex justify-content-center align-items-center pb-4 mb-4 admin-user-avatar-container">
								<label htmlFor="input-test" className="input-testt">
									<img
										src={avatar ? avatar : getAvatar(item?.avatar)}
										alt="profil utilisateur"
										className="pp-profil-user-admin"
									/>
									<div className="admin-user-avatar-edit-hover">
										<BsCameraFill className="edit-avatar-camera-icon" />
									</div>
								</label>

								<input
									type="file"
									className="input-add-avatar"
									id="input-test"
									onChange={handleChangeAvatar}
								/>
							</div>
							<FormError error={errors.avatar?.message} />
						</div>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="text"
							label="Nom"
							id="last_name"
							required
							placeholder="Nom"
							{...register("last_name")}
							error={errors?.last_name?.message}
						/>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="text"
							label="Prénom"
							id="first_name"
							required
							placeholder="Prénom"
							{...register("first_name")}
							error={errors?.first_name?.message}
						/>
					</div>

					<div className="col-md-6 col-form-add-student mb-3">
						<InputAdmin
							type="email"
							label="Adresse email"
							id="adresse_email"
							required
							placeholder="Email"
							{...register("email")}
							error={errors?.email?.message}
						/>
					</div>
					<div className="col-md-6 col-form-add-student mb-3">
						{/* <InputAdmin
							type="text"
							label="Téléphone"
							id="phone"
							required
							placeholder="Téléphone"
							{...register("phone")}
							error={errors?.phone?.message}
						/> */}
						<div className="mb-3">
							<label className="form-label form-label-modal-custom ">
								Téléphone{" "}
								<span className="text-danger" style={{ fontSize: 15 }}>
									*
								</span>
							</label>
							<PhoneInput
								defaultMask={".. ... .. .."}
								inputClass="form-control-modal-custom input-custom"
								country={"sn"}
								placeholder=""
								inputProps={{
									name: "telephone",
									required: true,
									autoFocus: true,
								}}
								countryCodeEditable={false}
								enableAreaCodes={true}
								prefix="+"
								value={phone}
								onChange={(phone, country: any) =>
									handleChangePhone(phone, country)
								}
								inputStyle={{
									width: "100%",
									background: "rgba(232, 240, 253, 0.7)",
								}}
							/>
							<FormError error={errors?.phone?.message} />
						</div>
					</div>
					<div className="col-md-12">
						<div className="container-title-dash">
							<h2 className="custom-title mb-0">Permissions</h2>
						</div>
						<div className="all-permissions-content pt-4">
							{Authorisations.map((perm, key) => (
								<label
									htmlFor={`permission-${key}`}
									className="permission-small-card"
									key={key}
								>
									<input
										type="checkbox"
										className="permission-input"
										{...register(`${perm?.field}`)}
										id={`permission-${key}`}
									/>
									<label htmlFor={`permission-${key}`}>{perm?.label}</label>
								</label>
							))}
						</div>
					</div>
				</div>
				<div className="row row-content-btn-action pt-3">
					<div className="col-md-4 offset-md-8">
						<BtnSubmit
							label={item ? "Modifier" : "Ajouter"}
							isLoading={isLoading}
							full
						/>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddAdminModal;
