import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useAppSelector } from "../../../../redux/hooks";
import { useStepType } from "../../../../utils/type";
import { formatMontant } from "../../../../utils/Utils";
import "./Panier.css";
import CartePaiement from "./PaiementComponentTabs/CartePaiement";
import useBuyCart from "./requestOrder/useBuyCart";
import InfosComplementaires from "./InfosComplementaires";
import CheckPaiementStatus from "../../../HomePage/SoumettreOuvrages/Tabs/CheckPaiementStatus";

const steps = [
	{ id: "infos", Component: InfosComplementaires },
	{ id: "carte", Component: CartePaiement },
	{ id: "check-paiement-status", Component: CheckPaiementStatus },
];

function BuyCartUser() {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const { subAmount } = useAppSelector((state) => state.panier);
	const { Component } = step;
	const {
		register,
		errors,
		onSubmit,
		setValue,
		phone,
		handleChangePhone,
		user,
		onGetTransactionStatus,
		isFetching,
		seconds,
		isLoading,
		adress,
		handleChangeAdress,
	} = useBuyCart(navigation);

	const props = {
		navigation,
		register,
		errors,
		onSubmit,
		phone,
		handleChangePhone,
		user,
		onGetTransactionStatus,
		isFetching,
		seconds,
		isLoading,
		adress,
		handleChangeAdress,
	};
	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	return (
		<div className="row">
			<div className="col-lg-8 mb-3">
				<div className="BuyCartUser-left-side-container bg-white p-3">
					<div className="BuyCartUser-left-side-page-container">
						<Component {...props} />
					</div>
				</div>
			</div>
			<div className="col-lg-4 mb-3">
				<div className="BuyCartUser-right-side-container bg-white p-2">
					<div className="BuyCartUser-left-side-title">Détails du prix</div>
					<hr />
					<div className="detail-prix-page-container">
						<div className="detail-prix-page-label-container mb-3">
							<div className="detail-prix-label">Sous total:</div>
							<div className="detail-prix-value">{formatMontant(subAmount)}</div>
						</div>
					</div>
					<hr />
					<div className="detail-prix-page-container">
						<div className="detail-prix-page-label-container mb-3">
							<div className="detail-prix-label">Total:</div>
							<div className="detail-prix-value">{formatMontant(subAmount)}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BuyCartUser;
