import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { INotification, NotificationFormData } from "./notification.type";

export const NotificationApi = createApi({
	reducerPath: "notificationApi",
	tagTypes: ["notification"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getNotifByUser: builder.query<
			PaginationResults<INotification>,
			{ slug: string; type: "admin" | "recever" }
		>({
			query: ({ slug, type }) => `${type}/${slug}/notifications/`,
			providesTags: ["notification"],
		}),
		readNotification: builder.mutation<INotification, NotificationFormData>({
			query: ({ slug, ...data }) => ({
				url: `notification/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["notification"],
		}),
		deleteNotification: builder.mutation<INotification, NotificationFormData>({
			query: (slug) => ({
				url: `notification/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["notification"],
		}),
	}),
});

export const {
	useLazyGetNotifByUserQuery,
	useReadNotificationMutation,
	useGetNotifByUserQuery,
	useDeleteNotificationMutation,
} = NotificationApi;

export function useNotification(type: "admin" | "recever") {
	const [count, setCount] = useState(0);
	const user = useAppSelector((s) => s.user.user);
	const { data, isLoading } = useGetNotifByUserQuery({slug:user?.slug, type});
	const [readMessage] = useReadNotificationMutation();

	const functionCountunreadNotif = useCallback(() => {
		let count = 0;
		data?.results?.map((notif) => {
			if (!notif?.is_read) {
				count++;
			}
			return count;
		});
		setCount(count);
	}, [data]);

	useEffect(() => {
		functionCountunreadNotif();
	});

	const readAll = () => {
		data?.results?.map((item) => {
			return readMessage({ slug: item?.slug, is_read: true });
		});
	};
	const readNotif = (slug: string) => {
		return readMessage({ slug: slug, is_read: true });
	};

	return {
		count,
		data: data?.results,
		isLoading,
		readAll,
		readNotif,
	};
}
