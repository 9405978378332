/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Pagination as ReactPagination, PaginationItem, PaginationLink } from "reactstrap";
const PER_PAGE = 24;
type Page = {
	page: number;
	next?: boolean;
	previous?: boolean;
	active: boolean;
};
type PaginationProps = {
	page: number;
	onPageChange: (nextPage: number) => any;
	total?: number;
	perPage?: number;
};
type PageItemProps = {
	page: Page;
	onClick: (page: number) => any;
};

function PageItem({ page, onClick }: PageItemProps) {
	return (
		<PaginationItem active={page?.active} onClick={() => onClick(page?.page)}>
			<PaginationLink first href="#" previous={page?.previous} next={page.next}>
				{page?.page}
			</PaginationLink>
		</PaginationItem>
	);
}

function Pagination({ page, onPageChange, total, perPage = PER_PAGE }: PaginationProps) {
	const [pages, setPages] = useState<Page[]>([]);
	useEffect(() => {
		const numberOfPages = Math.ceil((total || 0) / perPage);
		const pages: Page[] = [];
		for (let i = 1; i <= numberOfPages; i++) {
			const newPage: Page = {
				active: page === i,
				page: i,
			};
			pages.push(newPage);
		}
		// console.log("p", pages, PER_PAGE, perPage);
		setPages(pages);
	}, [total, page, perPage]);
	if (pages?.length < 1) return null;
	return (
		<ReactPagination className="pagination-custom">
			{pages?.map((p, i) => (
				<PageItem page={p} onClick={onPageChange} key={i} />
			))}
		</ReactPagination>
	);
}

export default Pagination;
