import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";
import {
	ForumCommentFormData,
	ICommentForum,
	IForum,
} from "../../../../../utils/api/forum/forum.type";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddCommentForumMutation } from "../../../../../utils/api/forum/forum.api";

function useCrudComment(item?: IForum) {
	const validationSchema = yup.object().shape({
		response: yup.string().required("Ce champ est obligatoire"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ForumCommentFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [createOrEditPublication, { isLoading }] = useAddCommentForumMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: ForumCommentFormData) => {
		const newData: ForumCommentFormData = {
			...data,
			message: item?.id as number,
			created_by: authUser?.id,
		};
		console.log("data", newData);

		const res = await createOrEditPublication(newData);
		if ("data" in res) {
			reset();
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export function useCrudResponseComment(comment?: ICommentForum) {
	const validationSchema = yup.object().shape({
		response: yup.string().required("Ce champ est obligatoire"),
	});
	const { user: authUser } = useAppSelector((s) => s.user);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<ForumCommentFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [createOrEditPublication, { isLoading }] = useAddCommentForumMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: ForumCommentFormData) => {
		const newData: ForumCommentFormData = {
			...data,
			response_message: comment?.id as number,
			created_by: authUser?.id,
		};
		console.log("data", newData);

		const res = await createOrEditPublication(newData);
		if ("data" in res) {
			reset();
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useCrudComment;
