import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useGetReputedAuthorsQuery } from "../../utils/api/user/user.api";
import { usePagination } from "../../utils/hooks";
import { formatFollowerNumber, getImage, getName } from "../../utils/Utils";

const responsiveAuthor = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 1800 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 1800, min: 1024 },
		items: 3,
		itemsToScroll: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 768 },
		items: 2,
		itemsToScroll: 1,
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		items: 1,
		itemsToScroll: 1,
	},
};

const ReputedAuthors = () => {
	const { limit, page } = usePagination(10);
	const { data, isLoading } = useGetReputedAuthorsQuery({ limit, page });
	console.log({ data });
	return (
		<section className="section-avantage-home-page pt-5">
			<div className="container-meilleur-ouvrage">
				<div className="content-title-section-avantage text-center">
					<h2 className="h2-theme position-relative">Les auteurs les plus réputés</h2>
				</div>
				<div className="pt-5 auteur-carousel-container">
					{!isLoading && data && (
						<Carousel
							swipeable={false}
							draggable={false}
							showDots={false}
							responsive={responsiveAuthor}
							ssr={false}
							infinite={true}
							autoPlay={true}
							autoPlaySpeed={1000}
							keyBoardControl={false}
							arrows={false}
							customTransition="transform 1000ms ease-in-out"
							transitionDuration={1000}
							containerClass="carousel-container"
							removeArrowOnDeviceType={["tablet", "mobile"]}
							// dotListClass="custom-dot-list-style"
							itemClass="px-2"
						>
							{data?.map((item, index) => (
								<div className="col-auteur mb-3" key={item?.slug + index}>
									<div className="col-auteur">
										<div className="card card-auteur-item">
											<div className="content-card-img-top-auteur">
											<img 
												src={getImage(item?.avatar)}
												className="card-img-top-auteur"
												alt={item?.first_name}
											/>
											</div>
											<div className="card-body">
												<div className="content-head-card-auteur pe-3">
													<h5 className="card-title card-title-auteur mb-0">
														{getName(item)}
													</h5>
													<p className="text-nombre-livre mb-0">
														{item?.published_books_count} livres
													</p>
												</div>
												<p className="card-text card-text-auteur">
													{formatFollowerNumber(
														item?.subscribers?.length
													) ?? 0}{" "}
													abonnés
												</p>
											</div>
										</div>
									</div>
								</div>
							))}
						</Carousel>
					)}
				</div>
			</div>
		</section>
	);
};

export default ReputedAuthors;
