import StarRatingComponent from "react-star-rating-component";
import { BsHandThumbsDown, BsHandThumbsUp } from "react-icons/bs";
import { formatDate, getAvatar } from "../../../../../../utils/Utils";
import { Book, INotice } from "../../../../../../utils/api/book/book.type";
import {
	useToggleLikeNotice,
	useToggleDisLikeNotice,
	useSendNotice,
	useRateBook,
} from "../../hooks/useBook";
import { useGetNoticeByBookQuery } from "../../../../../../utils/api/book/book.api";
import { AlertInfo } from "../../../../../common/Alert";
import { FormError } from "../../../../../common/Input";
import { BtnSubmit } from "../../../../../common/Button";
getAvatar;

export function AvisItem({ item }: { item: INotice }) {
	const { handleToggleLikeNotice } = useToggleLikeNotice(item);
	const { handleToggleDislikeNotice } = useToggleDisLikeNotice(item);

	return (
		<div className="description-livre-avis-image-profile-username-container mb-5">
			<div className="description-livre-avis-image-profile-container">
				<img
					src={getAvatar(item?.user?.avatar)}
					alt=""
					className="description-livre-avis-image-profile"
				/>
			</div>
			<div className="description-livre-avis-profile-container">
				<div className="description-livre-avis-profile-username-container">
					<div className="description-livre-avis-profile-username">
						{item?.user?.first_name}
					</div>
					<div className="description-livre-avis-profile-date">
						{formatDate(item?.created_at)}
					</div>
				</div>
				<div className="description-livre-avis-profile-paragraphe-container">
					<p className="description-livre-avis-profile-paragraph">{item?.message}</p>
					<div className="description-livre-avis-profile-icone-container">
						<div className="description-livre-avis-profile-icone">
							<BsHandThumbsUp role="button" onClick={handleToggleLikeNotice} />{" "}
							{item?.nb_likes ?? 0}
						</div>
						<div className="description-livre-avis-profile-icone">
							<BsHandThumbsDown role="button" onClick={handleToggleDislikeNotice} />{" "}
							{item?.nb_dislikes ?? 0}
						</div>
						{/* <div className="description-livre-avis-profile-icone">
										<BsChatLeft /> 23
									</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}
type Props = { item: Book };

export const ListAvis = ({ item }: Props) => {
	const { data, isLoading } = useGetNoticeByBookQuery({ slug: item?.slug, type: "avis" });
	console.log({ data });
	return (
		<div className="user-dash-page-content-container mb-3">
			<div className="description-livre-titre-avis mb-4">Avis {data?.length}</div>
			{!isLoading && data && data?.length > 0 ? (
				data?.map((item) => <AvisItem item={item} key={item?.slug} />)
			) : (
				<AlertInfo message="Aucun avis" />
			)}
		</div>
	);
};

export const FormAvis = ({ item }: Props) => {
	const { register, onSubmit, errors, isLoading } = useSendNotice(item);
	const { onRateBook, rate } = useRateBook(item);
	return (
		<div className="user-dash-page-content-container mb-3">
			<div className="yeewouna-accordeon-container yeewuna-custom-icone-accordeon">
				<div className="accordion" id="accordionExample">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingOne">
							<button
								className="accordion-button"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseOne"
								aria-expanded="true"
								aria-controls="collapseOne"
							>
								Laisser votre avis
							</button>
						</h2>
						<div
							id="collapseOne"
							className="accordion-collapse collapse show"
							aria-labelledby="headingOne"
							data-bs-parent="#accordionExample"
						>
							<div className="accordion-body">
								<div className="description-livre-soumettre-avis-container">
									<div className="description-livre-soumettre-avis-titre">
										Cliquez sur une étoile pour noter {item?.title}
									</div>
									<div className="content-rate-livre">
										<StarRatingComponent
											starCount={5}
											name="rate__book"
											editing={true}
											value={rate}
											starColor="#F8BF5C"
											emptyStarColor="#BDBDBD"
											onStarClick={(index) => onRateBook(index)}
										/>
									</div>
									{/* <div className="description-livre-soumettre-avis-titre-aucun-avis">
										Aucun vote pour l’instant! Soyez le premier à noter ce livre
									</div> */}
									<div className="description-livre-soumettre-avis-text-area-container my-3">
										<form onSubmit={onSubmit}>
											<textarea
												className="form-control form-control-utilisateur"
												rows={5}
												placeholder="Message"
												{...register("message")}
											></textarea>
											<FormError error={errors?.message} />
											<div className="flex-r mt-4">
												<BtnSubmit label="Envoyer" isLoading={isLoading} />
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
