import React from "react";
import "./GestionPayment.css";
import ListPaymentAuteurTable from "./ListPaymentAuteurTable/ListPaymentAuteurTable";

const GestionPayment = () => {
  return (
    <div className="list-admin-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Paiements</h3>
        </div>
      </div>
      <div className="container-admin-page-table admin-table mt-4">
        <ListPaymentAuteurTable />
      </div>
    </div>
  );
};

export default GestionPayment;
