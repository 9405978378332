import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAddOrUpdateFaqMutation } from "../../../../../utils/api/faq/faq.api";
import { FaqFormData, IFaq } from "../../../../../utils/api/faq/faq.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/Utils";

function useFaqForm(closeModal: () => void, item?: IFaq) {
	const validationSchema = yup.object().shape({
		question: yup.string().required().label("Question"),
		answer: yup.string().required().label("Description"),
	});

	const [response, setResponse] = useState<string>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<FaqFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading }] = useAddOrUpdateFaqMutation();

	const onChangeResponse = (data: string) => {
		setResponse(data);
		setValue("answer", data);
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.question) {
			setValue("question", item?.question);
			setResponse(item?.answer);
			setValue("answer", item?.answer);
		} else {
			setValue("question", "");
			setResponse("");
			setValue("answer", "");
		}
	}, [item]);

	const onSubmit = async (data: FaqFormData) => {
		const res = await addOrUpdate({ slug: item?.slug, data: data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: item ? "Question modifiée avec succès!" : "Question ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				setResponse("");
				closeModal();
			});
		} else if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		onChangeResponse,
		response,
	};
}

export default useFaqForm;
