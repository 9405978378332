import React from "react";
import { AutheurDescriptionItem, LivredescriptionItem } from "./DetailLivreComponents";
import { Toaster } from "react-hot-toast";
import { useBookFromLocation } from "../../../../utils/api/book/book.api";
import { FormAvis, ListAvis } from "./BibliothequeTabs/Avis/Avis";

function DescriptionLivre() {
	const [item] = useBookFromLocation();
	return (
		<>
			<div className="user-dash-page-content-container pb-5 mb-3">
				<div className="yeewuna-details-actu-container px-0">
					<div className="yeewuna-description-livre-titre mb-5">Description du livre</div>
					<LivredescriptionItem item={item} />
					<AutheurDescriptionItem author={item?.author} />
				</div>
			</div>
			<ListAvis item={item} />
			<FormAvis item={item} />
			<Toaster />
		</>
	);
}

export default DescriptionLivre;
