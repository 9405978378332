import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import "./ParametresAdmin.css";
import ListAdmins from "./ParametreTabs/ListAdmins";
import OffersList from "./ParametreTabs/OffersList";
import Teams from "./Teams";
import { useGetConditionsQuery } from "../../../../utils/api/condition/condition.api";
import { ICondition, conditionType } from "../../../../utils/api/condition/condition.type";
import Condition from "./ParametreTabs/Condition";
import SocialMedia from "./ParametreTabs/SocialMedia";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { hasAccess } from "../../../../routes/routerUtils";

const steps = [
	{ id: "admins", Component: ListAdmins },
	{ id: "offers", Component: OffersList },
	{ id: "team", Component: Teams },
	{ id: "cgu", Component: Condition },
	{ id: "policy", Component: Condition },
	{ id: "social", Component: SocialMedia },
];
const tabs = [
	"Sous-admin",
	"Abonnements",
	"Équipe",
	"CGU",
	"Politique de Confidentialité",
	"Réseaux sociaux",
];
const ParametresAdmin = () => {
	const {
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { data: conditions } = useGetConditionsQuery();
	let [searchParams] = useSearchParams();
	let key = searchParams.get("step") || "";
	let pc = conditions?.filter((item: ICondition) => item?.type === conditionType.pc)[0];
	let cgu = conditions?.filter((item: ICondition) => item?.type === conditionType.cgu)[0];
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "parameter")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	
	useEffect(() => {
		if (key) {
			go(key);
		}
	}, [key]);

	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Paramètres</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<div className="setting-admin">
					<div className="container-tabs-admin-setting">
						<ul className="tabs-container">
							{tabs.map((tab, key) => (
								<li
									className={`tab-item  ${index === key && "tab-active-item"}`}
									key={key}
									onClick={() => go(key)}
									role="button"
								>
									{tab}
								</li>
							))}
						</ul>
					</div>
					<div className="tabs-component-container pt-4">
						{index === 0 && <ListAdmins />}
						{index === 1 && <OffersList />}
						{index === 2 && <Teams />}
						{index === 3 && <Condition content={cgu} type={conditionType.cgu} />}
						{index === 4 && <Condition content={pc} type={conditionType.pc} />}
						{index === 5 && <SocialMedia />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParametresAdmin;
