import moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IArticle } from "../../../../../utils/api/article/article.type";
import { createMarkup, getImage, getName, useLocationState } from "../../../../../utils/Utils";
import { GoBackBtn } from "../../../../common/Button";

function DetailsActualite() {
	const item = useLocationState<IArticle>(undefined);
	return (
		<>
			<div className="mb-3">
				<GoBackBtn />
			</div>
			<div className="user-dash-page-content-container mb-3">
				<div className="yeewuna-details-actu-container">
					<div className="yeewuna-details-actu-image-container">
						<img
							src={getImage(item?.image)}
							alt={item?.title}
							className="yeewuna-details-actu-image"
						/>
					</div>
					<div className="yeewuna-details-actu-text-container">
						<h5 className="yeewuna-details-actu-titre">{item?.title}</h5>
						<div
							className="yeewuna-detaims-actu-para"
							dangerouslySetInnerHTML={createMarkup(item?.content)}
						/>
					</div>
					<div className="yeewuna-utilisateur-actualite-item-content-auteur-infos my-5">
						<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-container">
							<img
								src={getImage(item?.author.avatar)}
								alt="photos profile de l'autheur"
								className="yeewuna-utilisateur-actualite-item-content-auteur-profile-image"
							/>
							<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile">
								<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-username">
									{getName(item?.author)}
								</div>
								<div className="yeewuna-utilisateur-actualite-item-content-auteur-profile-fonction">
									Auteur
								</div>
							</div>
						</div>
						<div className="yeewuna-utilisateur-actualite-item-content-auteur-date-publication">
							<AiOutlineClockCircle className="yeewuna-utilisateur-actualite-item-content-auteur-date-publication-icone" />
							<span>{moment(item?.created_at)?.fromNow()}</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DetailsActualite;
