import React from "react";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import AboutMe from "../../Auteur/ProfilAuteur/ProfilTabs/AboutMe";
import ListAbonnes from "../../Auteur/ProfilAuteur/ProfilTabs/ListAbonnes";
import SubscriptionUser from "../../Auteur/ProfilAuteur/ProfilTabs/SubscriptionUser";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import { formatFollowerNumber, getAvatar, getImage } from "../../../../utils/Utils";
import { AiFillHeart } from "react-icons/ai";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const steps = [
	{ id: "a-propos", Component: AboutMe },
	{ id: "abonnes", Component: ListAbonnes },
	{ id: "offre", Component: SubscriptionUser },
];
const tabs = ["Informations personnelles", "Abonnés", "Abonnement"];
function DétailsAuteur() {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	const [user] = useUserFromLocation();
	const navigate = useNavigate();
	let bgImg = user?.banniere ? getImage(user?.banniere) : "";
    const props = {user}
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin d-flex align-items-center gap-2">
					<MdOutlineArrowBackIos className="linkable" onClick={() => navigate(-1)} />
					<h3 className="h3-title-admin">Détails auteur</h3>
				</div>
			</div>
			<div className="bg-white mt-4">
				<div
					className="container-profil-auteur mb-5"
					style={!!bgImg ? { backgroundImage: `url(${bgImg})` } : {}}
				>
					<div className="content-pp-profil-auteur">
						<div className="content-img-profil-auteur-dash pb-3 text-center">
							<img
								src={getAvatar(user?.avatar)}
								alt="Photos de profil"
								className="img-profil-auteur-dash"
							/>
						</div>
						<h3 className="name-profil-auteur-dash">
							{user?.first_name + " " + user?.last_name}{" "}
						</h3>
						<div className="container-info-profil-auteur-dash">
							<p className="info-item-profil-auteur-dash mb-0">
								{user?.books?.length}{" "}
								<span style={{ fontWeight: 400, fontSize: 16 }}>oeuvres</span>
							</p>
							<p className="info-item-profil-auteur-dash mb-0">
								{formatFollowerNumber(user?.subscribers?.length)}{" "}
								<span style={{ fontWeight: 400, fontSize: 16 }}>abonnés</span>
							</p>
							<p className="info-item-profil-auteur-dash mb-0">
								{formatFollowerNumber(user?.favorite_books_count)}
								<span style={{ fontSize: 26 }}>
									<AiFillHeart />
								</span>
							</p>
						</div>
					</div>
				</div>
				<div className="admin-table">
					<div className="setting-admin">
						<div className="container-tabs-admin-setting">
							<ul className="tabs-container">
								{tabs.map((tab, key) => (
									<li
										className={`tab-item  ${
											index === key && "tab-active-item"
										}`}
										key={key}
										onClick={() => go(key)}
										role="button"
									>
										{tab}
									</li>
								))}
							</ul>
						</div>
						<div className="tabs-component-container pt-4">
							<Component {...props} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DétailsAuteur;
