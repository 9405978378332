import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { IOffer, OfferFormData } from "../../../../../utils/api/offer/offer.type";
import { useAddOrUpdateOfferMutation } from "../../../../../utils/api/offer/offer.api";
import { useNavigate } from "react-router-dom";
import { cleannerError } from "../../../../../utils/Utils";
import { QueryError } from "../../../../../utils/type";

function useCrudOffer(item?: IOffer) {
	const [advantages, setAdvantages] = React.useState<string>();
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Titre"),
		price: yup.string().required().label("Prix"),
		frequence: yup.string().required().label("Format"),
		duration: yup
			.number()
			.required()
			.label("Durée")
			.typeError("Durée est un champ obligatoire"),
		position: yup
			.number()
			.required()
			.label("Durée")
			.typeError("Position est un champ obligatoire"),
		description: yup.string().required().label("Avantages"),
		offer_text: yup.string().required().label("Description"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<OfferFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdateOffer, { isLoading }] = useAddOrUpdateOfferMutation();

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.id) {
			let fields: (keyof OfferFormData)[] = [
				"title",
				"price",
				"description",
				"frequence",
				"position",
				"duration",
				"offer_text",
			];
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
			setAdvantages(item?.description);
		}
	}, [item]);

	const handleChange = (value: string) => {
		setAdvantages(value);
		setValue("description", value);
	};

	const onSubmit = async (data: OfferFormData) => {
		console.log("data", data);
		const newData = { ...data };
		const res = await addOrUpdateOffer({ slug: item?.slug, data: newData });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: `Offre ${!item ? "ajoutée" : "modifiée"} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				reset();
				navigate(-1);
			});
		} else if ("error" in res) {
			const err = res.error as QueryError;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Erreur de statut ${err?.status}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		advantages,
		handleChange,
	};
}

export default useCrudOffer;
