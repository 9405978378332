import React from "react";
import { NavLink } from "react-router-dom";
import VersionChecker from "../../../../../versionChecker/VersionChecker";
// import logoHeader from "../../../../../assets/appImages/logoHeader.png";
import "./SidebarUser.css";
import { GrHomeRounded } from "react-icons/gr";
import { FiMail, FiUser, FiUsers } from "react-icons/fi";
import { IoLibraryOutline } from "react-icons/io5";
import { MdAddShoppingCart } from "react-icons/md";
import { BiUserCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import Logo from "../../../../shared/FrontHeader/Logo";

function SidebarUser() {
  return (
		<div className="navbar-vertical-customisation-v2 no-view-mobile">
			<div className="hoverable mt-4" id="scrollbar">
				<div className="content">
					<div className="move-left-side-menu"></div>
					<div className="container-user-connect-info pb-4">
						<Link to="/" className="container-info-user-connect-navmenu">
							{/* <img
								src={logoHeader}
								className="yeewuna-logo-header"
								alt="logo de yeewuna"
							/> */}
							<Logo />
						</Link>
					</div>
					<ul className="nav nav-items-custom flex-column">
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/accueil"
								data-tooltip-content="Accueil"
							>
								<span className="icon-container-nav-link-vertival">
									<GrHomeRounded />
								</span>
								<span className="hiddenable-navlink-label">Accueil</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/auteurs"
								data-tooltip-content="Auteurs"
							>
								<span className="icon-container-nav-link-vertival">
									<FiUser />
								</span>
								<span className="hiddenable-navlink-label">Auteurs</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/bibliotheque"
								data-tooltip-content="Bibliothèque"
							>
								<span className="icon-container-nav-link-vertival">
									<IoLibraryOutline />
								</span>
								<span className="hiddenable-navlink-label">Bibliothèque</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/communaute"
								data-tooltip-content="Communauté"
							>
								<span className="icon-container-nav-link-vertival">
									<FiUsers />
								</span>
								<span className="hiddenable-navlink-label">Communauté</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/panier"
								data-tooltip-content="Panier"
							>
								<span className="icon-container-nav-link-vertival">
									<MdAddShoppingCart />
								</span>
								<span className="hiddenable-navlink-label">Panier</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/messages"
								data-tooltip-content="Messages"
							>
								<span className="icon-container-nav-link-vertival">
									<FiMail />
								</span>
								<span className="hiddenable-navlink-label">Messages</span>
							</NavLink>
						</li>
						<li className="nav-item nav-item-vertical-custom">
							<NavLink
								className="nav-link nav-link-vertival-custom with-tooltip-menu"
								to="/utilisateur/mon-profil"
								data-tooltip-content="Mon profil"
							>
								<span className="icon-container-nav-link-vertival">
									<BiUserCircle />
								</span>
								<span className="hiddenable-navlink-label">Mon profil</span>
							</NavLink>
						</li>
					</ul>
					<div className="container-btn-logout">
						<div className="version-view-container ps-3">{"v" + VersionChecker()}</div>
					</div>
				</div>
			</div>
		</div>
  );
}

export default SidebarUser;
