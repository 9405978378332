/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BsTrash, BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill, RiSendPlaneFill } from "react-icons/ri";
import "./ListAuteursTable.css";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useGetAuthorsQuery } from "../../../../../utils/api/user/user.api";
import { IUser } from "../../../../../utils/api/user/user.type";
import { formatDate, getAvatar } from "../../../../../utils/Utils";
import { Book } from "../../../../../utils/api/book/book.type";
import { NavLink } from "react-router-dom";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import {
	UseArchiveUserItem,
	useDeleteUser,
	useSendAccess,
} from "../requestAuthorForm/UseCrudAuthorForm";
import { FaLock, FaTrash, FaUnlock } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";

export const BtnDelete = ({ slug, message }) => {
	const onDelete = useDeleteUser(slug, message);
	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content={"Supprimer"}
			style={{ color: "#E53E3E" }}
			onClick={onDelete}
		>
			<BsTrash />
		</button>
	);
};

export const BtnSend = ({ slug }) => {
	const onSend = useSendAccess(slug);

	return (
		<button
			className="btn with-tooltip btn-action-icon-send"
			data-tooltip-content={"Renvoyer les accès"}
			style={{ color: "#283262" }}
			onClick={onSend}
		>
			<RiSendPlaneFill />
		</button>
	);
};

export function ToggleArchiveBtn({ item }: { item: IUser }) {
	const onDelete = UseArchiveUserItem(item);

	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content={item?.is_archived ? "Désarchiver" : "Archiver"}
			style={{ color: item?.is_archived ? "#E53E3E" : "" }}
			onClick={() => onDelete()}
		>
			{/* {!item?.is_archived ? <BsTrashFill /> : <FaTrash />} */}
			{item?.is_archived ? <FaUnlock /> : <FaLock />}
		</button>
	);
}
function ListAuteursTable() {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetAuthorsQuery({
		page,
		limit,
	});

	const actionFormatter = (cell: any, row: any) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<BtnSend slug={cell} />
					</div>
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/auteurs/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Voir"
						>
							<AiFillEye />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/admin/modifier-auteur/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Modifier"
						>
							<RiEdit2Fill />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<ToggleArchiveBtn item={row} />
					</div>
					<div className="container-btn-action-icon-modal">
						<BtnDelete
							slug={cell}
							message={`Êtes-vous sûr de vouloir supprimer cet auteur ?`}
						/>
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell: string, row: IUser) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-auteur-admin-table">
					<img
						src={getAvatar(row?.avatar)}
						alt={cell}
						className="photo-profil-auteur-admin-table"
					/>
				</div>{" "}
				<p className="name-auteur-admin-table mb-0">{cell + " " + row?.last_name}</p>
			</div>
		);
	};

	const rowClasses = () => {
		return "custom-row-class-student";
	};
	const columns = [
		{
			dataField: "first_name",
			text: "Noms",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "published_books",
			text: "Nbre livres publiés",
			style: { textAlign: "center" },
			formatter: (cell: Book[]) => cell?.length,
		},
		{
			dataField: "date_joined",
			text: "Date d’inscription",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						// selectRow={selectRow}
						rowClasses={rowClasses}
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setLimit(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default ListAuteursTable;
