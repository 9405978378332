import * as React from "react";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import { useGetCguQuery } from "../../../utils/api/condition/condition.api";
import { createMarkup } from "../../../utils/Utils";
import Skeleton from "react-loading-skeleton";

const Cgu = () => {
	const { data = [], isLoading, refetch } = useGetCguQuery();

	React.useEffect(() => {
		if (!isLoading) {
			refetch();
		}
	}, []);
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<div className="container-banner-policy">
					<h2 className="title-banner-policy">
						{data?.length ? data[0]?.title : "Conditions générales d'utilisation"}
					</h2>
				</div>
				<div className="container">
					<div className="container-text-policy">
						{!!isLoading && (
              <Skeleton count={100} width={`100%`} />
            )}
						{!isLoading && <p
							className="text-policy"
							dangerouslySetInnerHTML={createMarkup(
								data?.length ? data[0]?.description : ""
							)}
						/>}
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
};

export default Cgu;
