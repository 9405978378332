import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../utils/Utils";
import { NewsletterForm } from "../../utils/api/newsletter/newsletter.type";
import { useSubscribeNewsletterMutation } from "../../utils/api/newsletter/newsletter.api";
import toast from "react-hot-toast";
import { FormError } from "../common/Input";
import { CircleSpinner } from "../common/Button";

const NewsLetterForm = () => {
	const validationSchema = yup.object().shape({
		email: yup.string().required().email().label("L'email"),
	});
	const [subscribeNewsletter, { isLoading }] = useSubscribeNewsletterMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		reset,
		clearErrors,
	} = useForm<NewsletterForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 6000);
		}
	}, [errors]);

	const onSubmit = async (data) => {
		console.log(data);
		const res = await subscribeNewsletter(data);

		if ("data" in res) {
			toast.success("Souscription réussie !");
			reset();
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			console.log(err.data);
			if (err?.data?.email?.includes("newsletter with this email already exists.")) {
				setError("email", { message: "Cet email existe déjà!" });
			} else {
				const { message = "Une erreur inconnue s'est produite" } = err.data || {};
				setError("email", { message: message });
			}
		}
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="col-right-newsletter">
				<input
					type="email"
					id="email"
					placeholder="Adresse email"
					className="form-control form-control-newsletter"
					{...register("email")}
				/>
				{isLoading ? (
					<CircleSpinner />
				) : (
					<button className="btn-subscribe-newsletter">S’incrire</button>
				)}
			</div>
			<FormError error={errors.email} />
		</form>
	);
};

export default NewsLetterForm;
