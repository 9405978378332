/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IAdmin, IAdminFormData } from "../../../../../utils/api/admin/admin.type";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
	useAddOrUpdateAdminMutation,
	useDeleteAdminMutation,
	useEditAdminMutation,
} from "../../../../../utils/api/admin/admin.api";
import { onSetUser } from "../../../../../redux/slice/User.slice";
import { Color } from "../../../../../utils/theme";
import { UserType } from "../../../../../utils/api/user/user.type";
import { cleannerError, validatePhone } from "../../../../../utils/Utils";

function useCrudAdmin(closeModal: () => void, user?: IAdmin) {
	const [code, setCode] = useState<string>("");
	const [phone, setPhone] = useState<string>();
	const validationSchema = yup.object().shape({
		// avatar: user?.slug
		// 	? yup.mixed().label("Photo de profil").typeError("Photo de profil est obligatoire")
		// 	: yup
		// 			.mixed()
		// 			.required()
		// 			.label("Photo de profil")
		// 			.typeError("Photo de profil est obligatoire"),
		last_name: yup.string().required().label("Nom"),
		first_name: yup.string().required().label("Prénom"),
		phone: validatePhone(yup, code).required(),
		email: yup.string().email().required().label("Email"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<IAdminFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();
	const [addOrUpdateUser, { isLoading }] = useAddOrUpdateAdminMutation();
	const { user: authUser } = useAppSelector((s) => s.user);
	const [avatar, setAvatar] = useState<string>("");

	const handleChangePhone = (item: any, country: any) => {
		setPhone(item);
		setCode(country?.countryCode);
		if (parseInt(item) !== parseInt(country?.dialCode)) {
			setValue("phone", item);
		} else {
			setValue("phone", "");
		}
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (user?.id) {
			let fields: (keyof IAdminFormData)[] = [
				"last_name",
				"first_name",
				"phone",
				"email",
				"adress",
				"accueil",
				"auteur",
				"utilisateur",
				"blog",
				"categorie",
				"commande_texte",
				"demande_devis",
				"faq",
				"livre",
				"message",
				"support",
				"parameter",
			];
			if (user?.user_type !== "admin") {
				fields = ["last_name", "first_name", "phone", "email", "adress"];
			}
			for (let field of fields) {
				register(field);
				setValue(field, user[field]);
			}
			if (user?.phone) {
				setPhone(user?.phone);
			}
		} else {
			// Authorisations.forEach((item) => {
			// 	setValue(item?.field, true);
			// });
		}
	}, [user]);

	const handleChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			let file: any = e.currentTarget.files[0];
			setAvatar(URL.createObjectURL(file));
			setValue("avatar", file);
		}
	};

	const onSubmit = async (data: IAdminFormData) => {
		console.log("data", data);
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			if (key === "avatar") {
				if (data[key]) {
					fd.append(key, value);
				}
			} else {
				fd.append(key, value);
			}
		}

		const res = await addOrUpdateUser({
			slug: user?.slug,
			user_type: UserType.admin,
			data: fd,
		});

		if ("data" in res) {
			if (authUser?.id === res.data?.id) {
				dispatch(onSetUser(res.data));
			}
			closeModal();
			Swal.fire({
				icon: "success",
				title: !user ? "Admin ajouté avec succès" : "L'admin a été modifié avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				setPhone("+221");
				reset();
			});
		}
		if ("error" in res) {
			const err = res.error as any;
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Un utilisateur avec cet email exite déjà!",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Erreur de statut ${err?.status}`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		authUser,
		avatar,
		handleChangeAvatar,
		handleChangePhone,
		phone,
	};
}

export default useCrudAdmin;

export const useDeleteAdmin = (slug: string) => {
	const [deleteItem] = useDeleteAdminMutation();

	const onDelete = async () => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ce sous admin ?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			confirmButtonColor: Color.themeColor,
			preConfirm: () => {
				return deleteItem(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.isConfirmed) {
				if (result?.value && !result?.value?.error) {
					Swal.fire({
						icon: "success",
						title: `L'administrateur a été supprimé avec succès!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					let err = result?.value?.error;
					Swal.fire({
						icon: "error",
						title: err?.data?.message
							? err?.data?.message
							: `Une erreur de statut ${err?.status} est survenue!`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
			}
		});
	};
	return onDelete;
};

export function useToggleArchiveAdmin(user: IAdmin) {
	const [editData] = useEditAdminMutation();
	const onArchive = async () => {
		let data: IAdminFormData = {
			user_type: user?.user_type,
			is_archived: !user.is_archived,
			first_name: user?.first_name,
			last_name: user?.last_name,
			email: user?.email,
			phone: user?.phone,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${
				user?.is_archived === true ? "désarchiver" : "archiver"
			} cet admin ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: user?.is_archived ? Color.success : Color.red,
			confirmButtonColor: user?.is_archived ? Color.success : Color.red,
			preConfirm: () => {
				return editData({ slug: user.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.isConfirmed) {
				if (result?.value && !result?.value?.error) {
					Swal.fire({
						icon: "success",
						title: `Admin ${
							result?.value?.data?.is_archive ? "archivé " : "désarchivé"
						} avec succèss!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 1500,
					});
				} else {
					Swal.fire({
						icon: "error",
						title: "Une erreur a survenue",
						iconColor: Color.red,
						showConfirmButton: false,
						timer: 1500,
					});
				}
			}
		});
	};
	return onArchive;
}
