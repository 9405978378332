import * as React from "react";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import "./MentionLegale.css";
import { useGetPolicyQuery } from "../../../utils/api/condition/condition.api";
import Skeleton from "react-loading-skeleton";
import { createMarkup } from "../../../utils/Utils";

const Policy = () => {
	const { data = [], isLoading, refetch } = useGetPolicyQuery();

	React.useEffect(() => {
		if (!isLoading) {
			refetch();
		}
	}, []);
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<div className="container-banner-policy">
					<h2 className="title-banner-policy">
						{data?.length ? data[0]?.title : "Politique de confidentialité"}
					</h2>
				</div>
				<div className="container">
					<div className="container-text-policy">
						{!!isLoading && <Skeleton count={100} width={`100%`} />}
						{!isLoading && (
							<p
								className="text-policy"
								dangerouslySetInnerHTML={createMarkup(
									data?.length ? data[0]?.description : ""
								)}
							/>
						)}
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
};

export default Policy;
