import React from "react";
import { FaUsers } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import "./DashboardAuteur.css";
import LineChart from "./LineChat/LineChart";
import { ImBook } from "react-icons/im";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetAuthorDashboardQuery } from "../../../../utils/api/user/user.api";
import { formatAmount, formatFollowerNumber } from "../../../../utils/Utils";

function DashboardAuteur() {
	const { user } = useAppSelector((s) => s?.user);
	const { data } = useGetAuthorDashboardQuery(user?.slug);
	return (
		<div className="dashbord-admin-component">
			<div className="dash-admin-page-content-container mb-3">
				<div className="row mb-4">
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total clients
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{data?.total_clients}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-1">
									<FaUsers className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total Livres
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{data?.total_clients}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-2">
									<ImBook className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total Ventes
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatAmount(data?.total_ventes)}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-1">
									<MdPayments className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 mb-3 dis-flex">
						<div className="item-stat-dashboad-view">
							<div className="content-stat-dashboard-view">
								<div className="content-text-item-stat-dashboad-view">
									<div className="content-item-stat-dashboad-view mt-2">
										<p className="titre-item-stat-dashboad-view mb-1">
											Total followers
										</p>
									</div>
									<div className="icon-item-stat-dashboad-view">
										<p className="chiffre-item-stat-dashboad-view mb-1">
											{formatFollowerNumber(data?.total_followers)}
										</p>
									</div>
								</div>
								<div className="content-item-icon-stat-dashboard-view color-3">
									<FaUsers className="icon-item-stat-dashboad-view" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-graph-admin">
					<LineChart graphData={data?.graphe} />
				</div>
			</div>
		</div>
	);
}

export default DashboardAuteur;
