import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddContactMutation } from "../../../../utils/api/contact/contact.api";
import { ContactForm } from "../../../../utils/api/contact/contact.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal } from "../../../../utils/Utils";

function UseContactUsForm(modalId: string) {
	const refInput = useRef<any>();
	const [message, setMessage] = useState<any>();
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Le nom"),
		subject: yup.string().required().label("L'objet"),
		email: yup.string().email().required().label("L'email"),
		message: yup.string().required().label("Le message").nullable(),
	});

	const [sendMessage, { isLoading, isError, isSuccess, error }] = useAddContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
		setError,
	} = useForm<ContactForm>({
		resolver: yupResolver(validationSchema),
	});
	const handleChangeMessage = useCallback((value) => {
		setMessage(value);
		setValue("message", value);
	}, []);
	const handleReset = () => {
		refInput.current.editor.setText("");
		setMessage("");
		reset();
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succés!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
                closeModal(modalId)
				handleReset();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status || "inconnue"} est survenue`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: ContactForm) => {
		console.log(data);
		if (data["message"] === "<p><br></p>") {
			return setError("message", { message: "Le message est obligatoire" });
		} else {
			sendMessage(data);
		}
	};
	return {
		register,
		onSubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		message,
		handleChangeMessage,
		refInput,
	};
}

export default UseContactUsForm;
