import React from "react";
import ListeAuteurs from "../../Utilisateur/Autheurs/AuteurTabs/ListeAuteurs";

const ListesdesAuteurs = () => {
	return (
		<div className="listes-auteurs">
			<div className="content-title-admin d-fle">
				<h3 className="h3-title-admin">Liste des auteurs</h3>
			</div>
			<ListeAuteurs />
		</div>
	);
};

export default ListesdesAuteurs;
