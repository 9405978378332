import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useModal, usePagination } from "../../../../../utils/hooks";
import { useGetAdminsListQuery } from "../../../../../utils/api/admin/admin.api";
import { IAdmin } from "../../../../../utils/api/admin/admin.type";
import { getAvatar, isShowPagination } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import AddAdminModal from "../modal/AddAdminModal";
import { ButtonAdd, ButtonEdit } from "../../../../common/Button";
import { useDeleteAdmin, useToggleArchiveAdmin } from "../hooks/useCrudAdmin";
import { FaLock, FaUnlock } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { BtnSend } from "../../GestionAuteurs/ListAuteursTable/ListAuteursTable";

const BtnDelete = ({ slug }) => {
	const onDelete = useDeleteAdmin(slug);
	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content={"Supprimer"}
			style={{ color: "#E53E3E" }}
			onClick={onDelete}
		>
			<BsTrash />
		</button>
	);
};

function ToggleArchiveAdmin({ item }: { item: IAdmin }) {
	const toggleArchive = useToggleArchiveAdmin(item);

	return (
		<button
			className={`btn with-tooltip btn-action-icon-delete  ${
				item?.is_archived ? "btn-desarchiver" : ""
			}`}
			data-tooltip-content={item?.is_archived ? "Désarchiver" : "Archiver"}
			style={{ color: "#E53E3E" }}
			onClick={toggleArchive}
		>
			{item?.is_archived ? <FaUnlock /> : <FaLock />}
		</button>
	);
}

function ListAdminsTable() {
	const { page, setPage, perPage, setPerPage } = usePagination(10);
	const { data, isLoading } = useGetAdminsListQuery({
		page,
		limit: perPage,
	});
	const { isShowModal, closeModal, openModal, openEditModal, item } = useModal<IAdmin>();

	// console.log({ data });

	const actionFormatter = (cell: string, row: IAdmin) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<BtnSend slug={cell} />
					</div>
					<div className="container-btn-action-icon-modal">
						<ButtonEdit onClick={() => openEditModal(row)} />
					</div>
					<div className="container-btn-action-icon-modal">
						<ToggleArchiveAdmin item={row} />
					</div>
					<div className="container-btn-action-icon-modal">
						<BtnDelete slug={cell} />
					</div>
				</div>
			</>
		);
	};
	const nameFormatter = (cell, row: IAdmin) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-auteur-admin-table">
					<img
						src={getAvatar(row?.avatar)}
						alt={cell}
						className="photo-profil-auteur-admin-table"
					/>
				</div>{" "}
				<p className="name-auteur-admin-table mb-0">{cell}</p>
			</div>
		);
	};

	const selectRow = {
		mode: "checkbox",
	};

	const columns = [
		{
			dataField: "last_name",
			text: "Nom",
			formatter: (cell: string, row: IAdmin) => nameFormatter(cell, row),
		},
		{
			dataField: "first_name",
			text: "Prénom",
		},
		{
			dataField: "email",
			text: "Email",
		},
		{
			dataField: "phone",
			text: "N° téléphone",
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IAdmin) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			<div className="content-btn-action-title-page-admin flex-r mb-5">
				<ButtonAdd label="Ajouter un sous-admin" onClick={openModal} />
			</div>
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						selectRow={selectRow}
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					{isShowPagination(data?.count || 0, perPage) && (
						<CustomPagination
							nbPages={data?.count}
							page={page}
							onChange={(page, perPage) => {
								setPerPage(perPage);
								setPage(page);
							}}
							perPage={perPage}
						/>
					)}
				</>
			)}
			<AddAdminModal isOpen={isShowModal} closeModal={closeModal} item={item} />
		</>
	);
}

export default ListAdminsTable;
