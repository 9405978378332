import React, { useEffect, useState } from "react";
import { CgMenuGridO } from "react-icons/cg";
import { Outlet } from "react-router-dom";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./LayoutAdmin.css";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import SubscribtionModal from "../../Auteur/modals/SubscribtionModal";
import { useAppSelector } from "../../../../redux/hooks";
import { isAutheur } from "../../../../utils/Utils";

function LayoutAdmin() {
	var d = new Date();
	var date = d.getFullYear();
	const { user } = useAppSelector((s) => s?.user);
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (user && isAutheur(user) && !user?.last_subscription) {
			setShow(true);
		} else {
			setShow(false)
		}
	}, [user]);
	return (
		<div className="container-fluid body-background-dashboard">
			<div className="row">
				<input type="checkbox" name="nav" id="nav_button"></input>
				<label htmlFor="nav_button" className="on no-view-mobile">
					<CgMenuGridO className="icon-side-menu" />
				</label>
				<div className="nav-container no-view-mobile">
					<SidebarAdmin />
				</div>
				<div className="container-header">
					<HeaderAdmin />
				</div>
				<div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
					<div className="admin-page-container-layout admin-page-container-height">
						<Outlet />
					</div>
					<div className="footer-admin-conatiner">
						<p className="text-copyright-admin-footer mb-0 ps-4">
							Copyright © {date} <span style={{ fontWeight: 700 }}>YEEWUNA</span>
						</p>
						<p className="text-version-footer-admin mb-0">{"v" + VersionChecker()}</p>
					</div>
				</div>
			</div>
			<SubscribtionModal {...{ show, setShow }} />
		</div>
	);
}

export default LayoutAdmin;
