import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { CommandeText, CommandeTextFormData } from "./commandeText.type";

export const CommandeTextApi = createApi({
	reducerPath: "commandeTextApi",
	tagTypes: ["commandeText"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCommandeText: builder.query<PaginationResults<CommandeText>, TypeQuery>({
			providesTags: ["commandeText"],
			query: (query) => {
				return QueryUrl("commander_texte/", query);
			},
		}),

		addOrEditCommandeText: builder.mutation<CommandeText, CommandeTextFormData>({
			invalidatesTags: ["commandeText"],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `commander_texte/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `visitor/commander_texte/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCommandeText: builder.mutation<CommandeText, string>({
			query: (slug) => ({
				url: `commander_texte/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["commandeText"],
		}),
	}),
});

export const {
	useAddOrEditCommandeTextMutation,
	useDeleteCommandeTextMutation,
	useGetCommandeTextQuery,
} = CommandeTextApi;
