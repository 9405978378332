/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from "../assets/appImages/image-default.png";
import Avatars from "../assets/appImages/Avatars.png";
import defaultImage from "../assets/appImages/NoImage.png";
import bannerAuteur from "../assets/appImages/abonnement-tabs.png";
import { ApiBaseUrl } from "./http";
import { PhoneNumberUtil,PhoneNumberFormat } from "google-libphonenumber";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { IUser } from "./api/user/user.type";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const phoneUtil = PhoneNumberUtil.getInstance();

export function validateNumber(phone?: string, country?: string) {
	if (!phone || !country) return true;
	try {
		const number = phoneUtil.parse(phone, country);
		if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
			return true;
		}
	} catch (ex) {
		console.log("error phone", ex);
	}
	return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
	return yup
		.string()
		.test("passwords-match", "Numéro téléphone invalide", (val) => validateNumber(val, country)).label("Le numéro de téléphone")
		.nullable();
}

export function getInTechPhone(phone: string) {
	const number = phoneUtil.parse(phone, "SN");
	if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
	  return number.getNationalNumber();
	}
	console.log("phone",phone)
	return phone;
  }
  export function getInTechTel(phone: string) {
	const number = phoneUtil.parse(phone, "SN");
	if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
	  return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
	}
	return phone;
  }

export function createMarkup(text: string | any, showDots?: boolean, numberSlice?: number) {
	if (text) {
		return {
			__html: `${numberSlice ? text.slice(0, numberSlice) : text}${
				showDots === true ? "..." : ""
			}`,
		};
	}
	return;
}
export function readMarkup(text: string | any, showDots?: boolean, numberSlice?: number) {
	let content = text ? text.replace(/<.+?>/g, "") : text;
	return {
		__html: `${numberSlice ? content.slice(0, numberSlice) : content}${
			showDots === true ? "..." : ""
		}`,
	};
}

export function cleannerError(errors: any, cleanner: any, timeOut: number = 3000) {
	if (errors) {
		setTimeout(() => Object.entries(errors).map(([key]: any) => cleanner(key)), timeOut);
	}
}

export const getImage = (img: string | any) => {
	if (img && !img.includes("default.png") && !img.includes("static/media/")) {
		if (img.indexOf("http") === 0) return img;
		return ApiBaseUrl + img;
	}
	return defaultImage;
};

export const getBanner = (img: string | any) => {
	if (img && !img.includes("default.png") && !img.includes("static/media/")) {
		if (img.indexOf("http") === 0) return img;
		return ApiBaseUrl + img;
	}
	return bannerAuteur;
};

export const getInputAvatar = (file: File) => {
	if (!file) return "";
	return URL.createObjectURL(file);
};

export const getAvatar = (avatar: string | any) => {
	if (avatar && !avatar.includes("default.png")) {
		if (avatar.indexOf("http") === 0) return avatar;
		return ApiBaseUrl + avatar;
	}
	return Avatars;
};

export function getAvatarFromUser(user: any) {
	const av1 = getAvatar(user.avatar);
	if (av1 === Avatar) return getAvatar(user.profil.photo);
	return av1;
}

export function isVisiteur(user: any) {
	if (!user) return false;
	return ["reader"].includes(user.user_type);
}

export function isAutheur(user: any) {
	if (!user) return false;
	return ["author"].includes(user.user_type);
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
	if (additionalSearchQuery) {
		for (let key of Object.keys(additionalSearchQuery)) {
			let val = additionalSearchQuery[key];

			if (Array.isArray(val)) {
				if (val.length > 0) {
					const string = val.map((v) => `${key}=${v}`).join("&");
					if (baseUrl.includes("?")) {
						baseUrl += `&${string}`;
					} else {
						baseUrl += `?${string}`;
					}
				}
			} else if (typeof val === "boolean") {
				if (baseUrl.includes("?")) {
					baseUrl += `&${key}=${val}`;
				} else {
					baseUrl += `?${key}=${val}`;
				}
			} else {
				if (val) {
					if (baseUrl.includes("?")) {
						baseUrl += `&${key}=${val}`;
					} else {
						baseUrl += `?${key}=${val}`;
					}
				}
			}
		}
	}
	return baseUrl;
}

export function useLocationState<T>(
	defaultValue: T | any,
	redirectToWhenNull: string | null = null
): T {
	const state = useLocation().state as T;
	const navigate = useNavigate();
	useEffect(() => {
		if (!state && redirectToWhenNull) {
			navigate(redirectToWhenNull);
		}
	}, [state]);
	return state || defaultValue;
}

export const formatDate = (date: string | Date) => {
	return moment(new Date(date)).format("DD/MM/YYYY");
};

export const formatHour = (date) => {
	return moment(date).format("HH:mm");
};

export function formatterDateFrench(date: string | Date) {
	moment.locale("fr");
	return date ? moment(date).format("LL") : "";
}

export function formatAmount(amount?: number | string) {
	if (amount || amount === 0)
		return new Intl.NumberFormat("fr-FR", {
			style: "currency",
			currency: "XOF",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}).format(parseFloat(String(amount)) || 0);

	return "-";
}

export const formatMontant = (num?: number | string) => {
	return formatAmount(num);
};

export const onHide = (modalId: string) => {
	document.getElementById(modalId)?.classList.remove("show");
	$(".modal-backdrop").remove();
	$(`#${modalId}`).hide();
	$(`body`).css({ overflowY: "scroll" });
	document.getElementById("body")?.classList.remove("modal-open");
	document.getElementById("body")?.removeAttribute("style");
};

export const getUrl = (file: string | any) => {
	return ApiBaseUrl + file;
};

export function getName(item?: IUser) {
	if (item?.id) {
		return item?.first_name + " " + item?.last_name;
	}
	return "username";
}

export function formatFollowerNumber(number?: number) {
	const formatter = new Intl.NumberFormat("en-GB", {
		notation: "compact",
		compactDisplay: "short",
	});
	if (number) {
		return formatter.format(number);
	}
	return 0;
}

export function isShowPagination(count: number, perPage: number): boolean {
	if (count > perPage) {
		return true;
	}
	return false;
}

export const showModal = (id: string) => {
	// console.log("id", id);
	const element = document.getElementById(id);

	const myModal = new Modal(element);
	myModal.show();
};

export const closeModal = (id: string) => {
	const element = document.getElementById(id);
	const myModal = Modal.getInstance(element);
	$(".modal-backdrop").remove();
	$("body").removeClass("modal-open");
	$("body").css("padding-right", "");
	$("body").css("overflow", "auto");
	myModal?.hide();
};

export function convertToLowerExceptFirst(str) {
	if (str.length === 0) {
		return "";
	} else if (str.length === 1) {
		return str.toUpperCase();
	} else {
		const firstChar = str.charAt(0).toUpperCase();
		const restOfString = str.slice(1).toLowerCase();
		return firstChar + restOfString;
	}
}

export const isValid = (user: IUser) => {
	let validation = false;
	let subscription = user?.last_subscription;
	let date = moment(new Date());
	if (!subscription) {
		validation = false;
	} else {
		console.log(
			"dates",
			date,
			subscription?.date_fin,
			moment(subscription?.date_fin)?.isSameOrBefore(date),
			moment(subscription?.date_fin)?.isSameOrAfter(date)
		);
		if (moment(subscription?.date_fin)?.isSameOrBefore(date)) {
			validation = false;
		} else {
			validation = true;
		}
	}
	return validation;
};
