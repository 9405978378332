import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { BsChevronRight } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { IoFolderOpenOutline } from "react-icons/io5";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useStepType } from "../../../utils/type";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import BannerHeader from "../BannerHeader";
import "./DevenirAutheur.css";
import Abonnement from "./Tabs/Abonnement";
import AjoutOuvrage from "./Tabs/AjoutOuvrage";
import Inscription from "./Tabs/Inscription";
import useBecomeAuthor from "./hooks/useBecomeAuthor";
import CheckPaiementStatus from "./Tabs/CheckPaiementStatus";

const steps = [
	{ id: "ouvrage", Component: AjoutOuvrage },
	{ id: "inscription", Component: Inscription },
	{ id: "abonnement", Component: Abonnement },
	{ id: "check-transaction-status", Component: CheckPaiementStatus },
];

const tabs = ["Ajouter votre ouvrage", "Inscription", "Abonnement"];

function DevenirAutheur() {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const { Component } = step;

	const {
		register,
		onSubmit,
		errors,
		setValue,
		description,
		handleChangeDescription,
		bookCover,
		handleSelectFiles,
		handleChoiceOffer,
		offer,
		progress,
		incrementProgress,
		isLoading,
		isLoad,
		onGetTransactionStatus,
		isFetching,
		seconds,
		avatar,
		phone,
		handleChangePhone,
	} = useBecomeAuthor(navigation);

	const props = {
		navigation,
		register,
		onSubmit,
		errors,
		setValue,
		description,
		handleChangeDescription,
		bookCover,
		handleSelectFiles,
		handleChoiceOffer,
		offer,
		progress,
		incrementProgress,
		isLoading,
		isLoad,
		onGetTransactionStatus,
		isFetching,
		seconds,
		avatar,
		phone,
		handleChangePhone,
	};

	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	const onClickStep = (key: number) => {
		// console.log(key);
		if (index > key) {
			navigation.go(key);
			setValue("step", key + 1);
		}
	};

	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content">
				<BannerHeader title="Devenir auteur" />
				<section className="section-a-propos-qsn pb-5">
					<div className="container-app-yeewuna">
						<div className="row">
							<div className="col-md-4">
								<div className="yeeuna-devenir-autheur-col-left-content-container">
									<div className="yeeuna-devenir-autheur-col-left-content-infos-profile mb-4">
										<div className="yeewuna-progress-container">
											<CircularProgressbar
												value={progress}
												maxValue={1}
												text={`${Math.trunc(progress * 100)}%`}
												className="progress"
												styles={buildStyles({
													strokeLinecap: "butt",
													textSize: "20px",
													pathTransitionDuration: 1,
													textColor: "#fff",
													trailColor: "rgba(255, 255, 255, 0.1)",
													backgroundColor: "#fff",
													pathColor: "#fff",
												})}
											/>
										</div>
										<div>
											<div className="yeeuna-devenir-autheur-col-left-titre-infos-profile">
												Informations du profil
											</div>
											<div className="yeeuna-devenir-autheur-col-left-suggestion-profile">
												Compléter votre profil avant de pouvoir bénéficier
												des avantages d’être auteur sur Yeewuna.
											</div>
										</div>
									</div>
									<div className="yeeuna-devenir-autheur-col-left-content-btn-tabs">
										<ul className="tabs-container custom-tabs-container author-subscribe-parent-step">
											{tabs.map((tab, key) => (
												<li
													className={`tab-item  ${
														index === key && "tab-active-item"
													}`}
													key={key}
													onClick={() => onClickStep(key)}
													role="button"
												>
													{key === 0 ? (
														<MdOutlineCollectionsBookmark />
													) : key === 1 ? (
														<CiUser />
													) : (
														<IoFolderOpenOutline />
													)}
													<span className="tab-label"> {tab} </span>
													<BsChevronRight className="tab-item-icone" />
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<Component {...props} />
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
}

export default DevenirAutheur;
