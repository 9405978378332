import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import { IOffer, OfferFormData } from "./offer.type";

export const OfferApi = createApi({
	tagTypes: ["offer"],
	reducerPath: "offerApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getOffer: builder.query<IOffer[], void>({
			query: () => "offer/",
			providesTags: ["offer"],
			transformResponse: ({ results }) => results,
		}),
		getOfferForVisitors: builder.query<IOffer[], void>({
			query: () => "offerbyvisitor/",
			providesTags: ["offer"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateOffer: builder.mutation<IOffer, { slug?: string; data: OfferFormData }>({
			invalidatesTags: ["offer"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `offer/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `offer/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteOffer: builder.mutation<IOffer, string>({
			query: (slug) => ({
				url: `offer/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["offer"],
		}),
	}),
});

export const {
	useGetOfferQuery,
	useLazyGetOfferQuery,
	useAddOrUpdateOfferMutation,
	useDeleteOfferMutation,
	useGetOfferForVisitorsQuery,
} = OfferApi;
