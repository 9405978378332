import React from "react";
import "./ProfilAdmin.css";
import { NavLink, useLocation } from "react-router-dom";
import { IoCallOutline, IoLocationSharp } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { useAppSelector } from "../../../../redux/hooks";
import { createMarkup, getAvatar } from "../../../../utils/Utils";
import { FiEdit } from "react-icons/fi";

const ProfilAdmin = () => {
	const user = useAppSelector((s) => s?.user?.user);
	const location = useLocation();

	console.log("user admin", user);
	return (
		<div className="list-admins-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Mon profil</h3>
				</div>
			</div>
			<div className="container-profil-admin mt-4">
				<div className="card-profil-admin mb-3">
					<div className="container-info-user-profil">
						<div className="content-pp-profil-admin text-center pb-3">
							<img
								src={getAvatar(user?.avatar)}
								alt="profil utilisateur"
								className="pp-profil-user-admin"
							/>
						</div>
						<p className="name-pp-user-profil-admin text-center">
							{user?.first_name + " " + user?.last_name}
						</p>
						{!!user?.bio && (
							<p
								className="text-pp-user-profil-admin"
								dangerouslySetInnerHTML={createMarkup(user?.bio)}
							/>
						)}
						<div className="info-user-profil-btn-edit-container">
							<NavLink
								to={`${
									location?.pathname?.startsWith("/admin")
										? "/admin/modifier-profil"
										: "/utilisateur/modifier-profil"
								}`}
								className="btn btn-edir-profil d-none d-md-block"
							>
								Editer mon profil
							</NavLink>
							<NavLink
								to={`${
									location?.pathname?.startsWith("/admin")
										? "/admin/modifier-profil"
										: "/utilisateur/modifier-profil"
								}`}
								className="btn btn-edir-profil px-3 d-md-none"
								style={{ position: "absolute", top: "-30px", right: "-30px" }}
							>
								<FiEdit />
							</NavLink>
						</div>
					</div>
					<div className="container-user-info-contact-profil-admin mt-5 mb-4">
						{user?.adresse && (
							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Adresse</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<IoLocationSharp />
									</a>
									<p className="text-contact-profil-admin">{user?.adresse}</p>
								</div>
							</div>
						)}
						{user?.phone && (
							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Téléphone</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<IoCallOutline />
									</a>
									<p className="text-contact-profil-admin">{user?.phone}</p>
								</div>
							</div>
						)}
						{user?.email && (
							<div className="content-info-contact-profil-admin">
								<p className="libelle-text-contact-profil-admin">Adresse email</p>
								<div className="content-contact-profil-admin">
									<a href="#" className="btn-action-admin">
										<AiOutlineMail />
									</a>
									<p className="text-contact-profil-admin">{user?.email}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilAdmin;
