/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import TableSkeleton from "../../../../common/TableSkeleton";
import { useGetPublishedBooksByAuthorQuery } from "../../../../../utils/api/book/book.api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Book } from "../../../../../utils/api/book/book.type";
import { formatDate, getImage } from "../../../../../utils/Utils";
import { Category } from "../../../../../utils/api/category/category.type";
import { NavLink } from "react-router-dom";
import Pagination from "../../../../common/Pagination";
import { UseDeleteBookItem } from "../requestBook/UseCrudBookForm";

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				{nbPages > 0 && (
					<select
						className="form-control select-perpage"
						name="limit"
						id="limit"
						value={perPage}
						onChange={(e) => {
							onChange(1, parseInt(e.target.value, 10));
						}}
					>
						<option value="10">10</option>
						<option value="30">30</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				)}
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
export function DeleteBookItemBtn({ slug }: { slug: string }) {
	const onDelete = UseDeleteBookItem(slug);

	return (
		<button
			className="btn with-tooltip btn-action-icon-delete"
			data-tooltip-content="Supprimer"
			style={{ color: "#E53E3E" }}
			onClick={() => onDelete()}
		>
			<BsTrashFill />
		</button>
	);
}
function ListLivresAuteurTable() {
	const { user } = useAppSelector((s) => s?.user);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const { data = { results: [], count: 0 }, isLoading } = useGetPublishedBooksByAuthorQuery({
		slug: user?.slug,
		page,
		limit,
	});

	useEffect(() => {
		console.log("books", data);
	}, [data]);

	const actionFormatter = (cell: string, row: Book) => {
		return (
			<>
				<div className="table-actions-btn-container-commandes d-flex gap-3">
					<div className="container-btn-action-icon-modal">
						<NavLink
							to={`/auteur/modifier-livre/${cell}`}
							state={row}
							className="btn btn-action-icon-edit with-tooltip"
							data-tooltip-content="Modifier"
						>
							<RiEdit2Fill />
						</NavLink>
					</div>
					<div className="container-btn-action-icon-modal">
						<DeleteBookItemBtn slug={cell} />
					</div>
				</div>
			</>
		);
	};
	const imgFormatter = (cell, row: Book) => {
		return (
			<div className="table-actions-btn-container-img gap-2 d-flex align-items-center">
				<div className="content-img-pp-livre-admin-table">
					<img
						src={getImage(row?.couverture)}
						alt={cell}
						className="photo-profil-livre-admin-table"
					/>
				</div>{" "}
			</div>
		);
	};
	const priceFormatter = (cell) => {
		return (
			<div className="table-actions-btn-container-img">
				<p className="price-admin-table mb-0">{cell} FCFA</p>
			</div>
		);
	};

	const rowClasses = () => {
		return "custom-row-class-admin";
	};
	const columns = [
		{
			dataField: "id",
			text: "Image",
			formatter: (cell: any, row: any) => imgFormatter(cell, row),
		},
		{
			dataField: "title",
			text: "Nom",
		},
		{
			dataField: "created_at",
			text: "Date d’ajout",
			formatter: (cell: string) => formatDate(cell),
		},
		{
			dataField: "category",
			text: "Catégorie",
			formatter: (cell: Category) => cell?.name,
		},
		{
			dataField: "nombre",
			text: "Nombre de ventes",
		},
		{
			dataField: "price",
			text: "Prix",
			formatter: (cell: any) => priceFormatter(cell),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						// selectRow={selectRow}
						rowClasses={rowClasses}
						noDataIndication={() => "Aucune donnée trouvée"}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perpage) => {
							setPage(page);
							setLimit(perpage);
						}}
						perPage={limit}
					/>
				</>
			)}
		</>
	);
}

export default ListLivresAuteurTable;
