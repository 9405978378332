import React from "react";
import { BtnSubmit } from "../../../common/Button";
import { FormError, Input } from "../../../common/Input";
import { SelectInput } from "../../../common/SelectInput";

import { CommandeText } from "../../../../utils/api/commandeText/commandeText.type";
import useSendOrderTextForm from "../request/useSendOrderTextForm";
import ReactQuill from "react-quill";

function CommanderTextModal({ modalId, item }: { modalId: string; item?: CommandeText }) {
	const { register, options, onChange, onSubmit, errors, isLoading, description } =
		useSendOrderTextForm(modalId, item);
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header custom-modal-header px-2 py-1">
					<h5 className="modal-title mb-3" id={`${modalId}Label`}>
						Commander un texte
					</h5>
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-6">
									<div className="mb-3">
										<Input
											type="text"
											label="Nom"
											id="nom"
											placeholder="Nom"
											required
											{...register("nom")}
											error={errors?.nom?.message}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-3">
										<Input
											type="text"
											label="Prénom"
											id="prenom"
											placeholder="Prénom"
											required
											{...register("prenom")}
											error={errors?.prenom?.message}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-3">
										<Input
											type="email"
											label="Email"
											id="mail"
											placeholder="Email"
											required
											{...register("email")}
											error={errors?.email?.message}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-3">
										<SelectInput
											label="Type d’oeuvre"
											options={options}
											required
											{...register("type_oeuvre")}
											error={errors?.type_oeuvre?.message}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-3">
										<label
											htmlFor="desc"
											className="form-label form-label-modal-custom"
										>
											Description
											<span className="text-danger" style={{ fontSize: 17 }}>
												*
											</span>
										</label>
										<ReactQuill
											theme="snow"
											value={description}
											onChange={onChange}
											className="description-commande"
										/>
										<FormError error={errors?.description} />
									</div>
								</div>
								<div className="col-md-12">
									<div className="flex-r pt-5">
										<BtnSubmit label="Envoyer" isLoading={isLoading} />
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CommanderTextModal;
