import React, { useState } from "react";
import moment from "moment";
import { useAppSelector } from "../../../../../redux/hooks";
import { BiEdit } from "react-icons/bi";
import ChangePasswordModal from "../../../Admin/ProfilAdmin/modal/ChangePasswordModal";
import { NavLink } from "react-router-dom";
import {
	createMarkup,
	getImage,
	getName,
	isAutheur,
} from "../../../../../utils/Utils";
import { useGetPublishedBooksByAuthorQuery } from "../../../../../utils/api/book/book.api";
import { AlertInfo } from "../../../../common/Alert";
import { isAdminUser } from "../../../../../routes/routerUtils";

const AboutMe = ({ user }) => {
	const { user: userInfos } = useAppSelector((s) => s?.user);
	const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
	
	const { data, isLoading } = useGetPublishedBooksByAuthorQuery({ slug: user?.slug });

	const onOpenModalChangePassword = () => {
		// e.preventDefault();
		setOpenModalChangePassword(true);
	};

	

	return (
		<div className="about-me-component">
			<div className="row row-about-me">
				<div className="col-md-4 col-about-me mb-3">
					<div className="content-col-about-me mb-3">
						<>
							<h3 className="title-col-left-about-me py-3">A propos</h3>
							<div className="d-block px-2">
								<div className="user-offer-infos mb-2">
									<span>Nom:</span>
									<p> {getName(user)} </p>
								</div>
								<div className="user-offer-infos mb-2">
									<span>Email:</span>
									<p> {user?.email} </p>
								</div>
								<div className="user-offer-infos mb-2">
									<span>Téléphone:</span>
									<p> {user?.phone} </p>
								</div>
								<div className="user-offer-infos mb-2">
									<span>Adresse:</span>
									<p> {user?.adress} </p>
								</div>
							</div>
							<h4 className="title-col-left-about-me">Description</h4>
							<div
								className="text-pp-user-profil-admin px-2"
								dangerouslySetInnerHTML={createMarkup(user?.bio)}
							/>
						</>
						{!user?.bio && (
							<>
								<h3 className="title-col-left-about-me py-1">
									Faites parler de vous!!
								</h3>
								<div className="content-btn-add-describe-about-me text-center pb-5">
									<NavLink
										to={
											!isAdminUser(userInfos)
												? "/auteur/modifier-profil"
												: `/admin/modifier-auteur/${user?.slug}`
										}
										state={user}
										className="btn btn-page-title-admin"
									>
										Ajouter une description
									</NavLink>
								</div>
							</>
						)}
						<div className="content-footer-col-left-about-me b-t-1 p-2 mb-2 mt-5">
							<p className="text-footer-col-left-about-me">
								Inscrit le {moment(user?.date_joined).format("DD MMMM YYYY")}
							</p>
							
						</div>
					</div>
					{!!isAutheur(userInfos) && (
						<div className="content-btn-add-describe-about-me text-center pb-5">
							<button
								className="btn btn-page-title-admin"
								onClick={onOpenModalChangePassword}
							>
								<BiEdit />
								Modifier mon mot de passe
							</button>
						</div>
					)}
				</div>
				<div className="col-md-8 col-about-me mb-3">
					<div className="content-col-about-me p-3">
						<h2 className="title-col-right-about-me">Oeuvres publiés</h2>
						<div className="content-oeuvres-about-me my-3">
							{!isLoading ? (
								<>
									{data && data?.results?.length > 0 ? (
										<>
											{data?.results?.map((item) => (
												<div
													className="row row-oeuvres-about-me mb-3"
													key={item?.slug}
												>
													<div className="col-lg-2 col-md-3 col-left-oeuvres-about-me mb-3">
														<div className="content-col-left-oeuvres-about-me">
															<img
																src={getImage(item?.couverture)}
																alt="Oeuvres"
																className="img-oeuvres-about-me"
															/>
														</div>
													</div>
													<div className="col-lg-10 col-md-9 col-right-oeuvres-about-me mb-3">
														<div className="content-col-right-oeuvres-about-me">
															<h3 className="title-col-left-about-me pb-2 d-flex mb-0">
																{item?.title}
															</h3>
															{/* <div className="container-info-oeuvres-about-me mb-2">
																<div className="info-oeuvres-about-me-item">
																	<p className="text-info-oeuvres-about-me-item mb-0">
																		<span>
																			<AiFillEye />
																		</span>{" "}
																		15k
																	</p>
																</div>
																<div className="info-oeuvres-about-me-item">
																	<p className="text-info-oeuvres-about-me-item mb-0">
																		<span>
																			<AiFillStar />
																		</span>{" "}
																		15k
																	</p>
																</div>
																<div className="info-oeuvres-about-me-item">
																	<p className="text-info-oeuvres-about-me-item mb-0">
																		<span>
																			<FaComment />
																		</span>{" "}
																		15k
																	</p>
																</div>
															</div> */}
															<div className="content-text-describe-oeuvres-about-me">
																<div
																	className="text-describe-oeuvres-about-me mb-0"
																	dangerouslySetInnerHTML={createMarkup(
																		item?.description?.slice(
																			0,
																			350
																		)
																	)}
																/>
															</div>
															<div className="content-btn-see-all">
																{/* <button className="btn btn-see-all">
																	Voir tous les achats
																</button> */}
															</div>
														</div>
													</div>
												</div>
											))}
										</>
									) : (
										<AlertInfo message="Aucune donnée trouvée" />
									)}
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</div>
			<ChangePasswordModal
				modalChangePassword={openModalChangePassword}
				setModalChangePassword={setOpenModalChangePassword}
			/>
			
		</div>
	);
};

export default AboutMe;
