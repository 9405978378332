import React, { useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import video from "../../../../assets/video/Yeewuna.m4v";
import "./VideoModal.css";

function VideoModal({ show, setShow }) {
	const [videoLoading, setVideoLoading] = useState(true);

	const openModal = () => {
		setShow(!show);
	};

	const spinner = () => {
		setVideoLoading(!videoLoading);
	};
	return (
		<div>
			{show ? (
				<section className="modal__bg">
					<div className="modal__align">
						<div className="modal__content">
							<IoCloseOutline
								className="modal__close"
								arial-label="Close modal"
								onClick={() => openModal()}
							/>
							<div className="modal__video-align">
								{videoLoading ? (
									<div className="modal__spinner">
										<BiLoaderAlt
											className="modal__spinner-style"
											// fadeIn="none"
										/>
									</div>
								) : null}
								<iframe
									className="modal__video-style"
									onLoad={spinner}
									loading="lazy"
									width="800"
									height="500"
									src={video + "#toolbar=0"}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</div>
						</div>
					</div>
				</section>
			) : null}
		</div>
	);
}

export default VideoModal;
