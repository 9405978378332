import React from "react";
import { SelectInput } from "../../common/SelectInput";
import { BtnSubmit } from "../../common/Button";
import useSendDevis from "./useSendDevis";
import { Input } from "../../common/Input";
import { accept } from "../../../utils/type";
import { useGetCategoriesVisitorsQuery } from "../../../utils/api/category/category.api";

const FormDemandeService = () => {
	const { register, onSubmit, errors, isLoading, handleSelectDocument } = useSendDevis();
	const { data: categories } = useGetCategoriesVisitorsQuery();
	return (
		<form onSubmit={onSubmit}>
			<div className="yeewuna-page-service-obtenir-devis-col-right-titre mb-5" id="getDevis">
				Obtenir un devis
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="mb-3">
						<Input
							type="text"
							label="Nom"
							id="nom"
							placeholder="Nom"
							required
							{...register("last_name")}
							error={errors?.last_name}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<Input
							type="text"
							label="Prénom"
							id="prenom"
							required
							placeholder="Prénom"
							{...register("first_name")}
							error={errors?.first_name}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<Input
							type="email"
							label="Email"
							id="mail"
							required
							placeholder="Email"
							{...register("email")}
							error={errors?.email}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<SelectInput
							label="Type d’oeuvre"
							options={categories?.map((category) => {
								return {
									label: category?.name,
									value: category?.id,
								};
							})}
							required
							{...register("type_oeuvre")}
							error={errors?.type_oeuvre}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-3">
						<Input
							type="file"
							label="Charger le doc"
							id="doc"
							required
							placeholder="Doc"
							customClass="custom-class-input"
							error={errors?.document}
							accept={accept.pdf}
							onChange={handleSelectDocument}
						/>
					</div>
				</div>
				<div className="col-md-12">
					<div className="yeewuna-page-service-btn-submit-container">
						<BtnSubmit label="Envoyer" isLoading={isLoading} />
					</div>
				</div>
			</div>
		</form>
	);
};

export default FormDemandeService;
