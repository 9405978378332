/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
	addCartBook,
	calculateTax,
	getCartCount,
	getSubTotal,
	getTotalAmount,
} from "../../../../../redux/slice/Cart.slice";
import { Book } from "../../../../../utils/api/book/book.type";
import { toast } from "react-hot-toast";

const useAddBookToCart = (item: Book, closeModal?: () => void) => {
	const { user } = useAppSelector((s) => s?.user);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const addToCart = (type: "livre" | "ebook" | "audiobook", price: number) => {
		dispatch(
			addCartBook({
				item: item,
				qty: 1,
				item_type: type,
				price: price,
				user: user?.id,
			})
		);
		dispatch(getCartCount(user?.id));
		dispatch(getSubTotal(user?.id));
		dispatch(calculateTax());
		toast.success("Ajouté au panier avec succès");
		dispatch(getTotalAmount());
		closeModal && closeModal();
		navigate("/utilisateur/panier");
	};
	return { addToCart };
};

export default useAddBookToCart;
