import React from "react";
import { FormError, Input } from "../../../common/Input";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../../common/GoogleInput";
function InfosComplementaires({
	register,
	errors,
	onSubmit,
	phone,
	handleChangePhone,
	adress,
	handleChangeAdress,
}) {
	return (
		<form onSubmit={onSubmit} className="container-panier-component">
			<div className="row row-panier-user">
				<div className="col-md-12 col-panier-user mb-3">
					<div className="user-dash-page-content-container mb-3">
						<div className="yeewuna-page-bibliotheque-titre mb-4">
							Informations complémentaires
						</div>
						<div className="content-panier-element b-t-1  py-5">
							<div className="content-element-panier-item">
								<div className="row pb-5">
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<Input
												label="Prénom nom"
												type={"text"}
												{...register("name")}
												error={errors?.name}
												required
											/>
										</div>
									</div>
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<label
												htmlFor="phone"
												className="form-label form-label-modal-custom"
												aria-labelledby="phone"
											>
												Téléphone
												<span
													className="text-danger custom-required-text"
													style={{ fontSize: 17 }}
												>
													*
												</span>
											</label>
											<PhoneInput
												country={"sn"}
												value={phone}
												onChange={handleChangePhone}
												onlyCountries={["sn"]}
												masks={{ sn: ".. ... .. .." }}
												inputClass="form-control-modal-custom input-custom"
											/>
											<FormError error={errors.telephone} />
										</div>
									</div>
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<label
												htmlFor="adress"
												className="form-label form-label-modal-custom"
											>
												Adresse
												<span
													className="text-danger custom-required-text"
													style={{ fontSize: 17 }}
												>
													*
												</span>
											</label>
											<GoogleInput
												value={adress}
												onChange={handleChangeAdress}
												className="form-control form-control-modal-custom"
											/>
											<FormError error={errors?.adresse} />
										</div>
									</div>
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<Input
												label="Pays/Ville"
												type={"text"}
												{...register("country")}
												error={errors?.country}
												required
											/>
										</div>
									</div>
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<Input
												label="N° appartement/maison"
												type={"text"}
												{...register("appartment")}
												error={errors?.appartment}
												required
											/>
										</div>
									</div>
									<div className="col-md-6 form-info-buy-panier mb-3">
										<div className="content-form-info-buy-panier">
											<Input
												label="Point de repère, par ex. près de l'hôpital apollo "
												type={"text"}
												{...register("repere")}
												error={errors?.repere}
											/>
										</div>
									</div>
								</div>
								<div className="content-submit-form">
									<button className="btn btn-page-title-admin">Envoyer </button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="col-md-12 col-lg-4 col-panier-user mb-3">
					<div className="user-dash-page-content-container mb-3">
						<div className="yeewuna-page-bibliotheque-titre mb-4">
							Adresse sur la plateforme
						</div>
						<div className="content-info-panier-user b-t-1 b-b-1 py-4">
							<div className="info-panier-user-item pb-3">
								<p className="text-libelle-panier">Mara Dione</p>
								<p className="text-value-panier-user">Dakar, Sénégal</p>
								<p className="text-value-panier-user">Hlm grand yoff, apt 221</p>
								<p className="text-value-panier-user">
									30m du maboul qui est en face du terminus des bus DDD
								</p>
							</div>
						</div>
						<div className="content-btn-validate-panier my-3 mx-4 text-center">
							<button className="btn btn-page-title-admin">
								Envoyer cette adresse
							</button>
						</div>
					</div>
				</div> */}
			</div>
		</form>
	);
}

export default InfosComplementaires;
