import React from "react";
import CategorieTable from "./CategorieTable";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { hasAccess } from "../../../../routes/routerUtils";

const CategorieList = () => {
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (user) {
			if (hasAccess(user, "categorie")) {
				navigate("/admin/profil");
			}
		}
	}, [user]);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Catégories</h3>
				</div>
			</div>
			<CategorieTable />
		</div>
	);
};

export default CategorieList;
