import React from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetUserFavorisBookQuery } from "../../../../../utils/api/user/user.api";
import { AlertInfo } from "../../../../common/Alert";
import { FavorisBookItem } from "./FavorisBookItem";

function Favoris() {
	const { user } = useAppSelector((s) => s?.user);
	const { data, isLoading } = useGetUserFavorisBookQuery({ slug: user?.slug });
	console.log({ data });
	return (
		<>
			{!isLoading ? (
				<>
					{data && data?.results?.length > 0 ? (
						<>
							{data?.results?.map((item) => (
								<>
									<FavorisBookItem key={item.slug} item={item} />
								</>
							))}
						</>
					) : (
						<AlertInfo message="Aucune donnée trouvée" />
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default Favoris;
