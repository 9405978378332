import React from "react";

function PdfViewerModal({url}) {
  return (
    <div className="modal fade modal-lg" tabIndex={-1} role="dialog" id="pdfViewerModal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title">Fichier pdf</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
            <h5 className="modal-title" id="pdfViewerModal">
              Fichier pdf
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <iframe
              src={url}
              frameBorder="0"
              width={`100%`}
              height={800}
              allowFullScreen
              seamless
              title="doc"
            ></iframe>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default PdfViewerModal;
