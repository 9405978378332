import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePagination } from "../../../../utils/hooks";
import { useGetListHistoriqueQuery } from "../../../../utils/api/historique/historique.api";
import { AlertInfo } from "../../../common/Alert";
import moment from "moment";
import { formatHour, formatterDateFrench } from "../../../../utils/Utils";
// import { FaEye } from "react-icons/fa";
import "./HistoriquesAdmin.css";
import { Historique } from "../../../../utils/api/historique/historique.type";
import { CustomPagination } from "../../Auteur/GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";

const HistoriqueItem = ({ item }: { item: Historique }) => {
	return (
		<div className="card-item-info-historique-admin mb-3">
			<div className="text-card-item-info-historique-admin">
				<span className="text-hour-historique-admin pe-2">
					{formatHour(item?.created_at)}
				</span>
				<span className="text-info-historique-admin">
					{item?.content}
					{item?.user && (
						<>
							<span
								style={{
									color: "#EF802F",
									paddingRight: 5,
									paddingLeft: 5,
								}}
							>
								{item?.user?.first_name} {item?.user?.last_name}
							</span>
							{/* <span>et la patiente</span> */}
						</>
					)}
				</span>
			</div>
			{/* <div className="content-btn-detail-historique-admin">
				<button
					className="btn btn-detail-historique-admin"
					type="button"
					onClick={(e) => showModale(item)}
				>
					<span className="pe-2">Voir les détails</span>
					<span>
						<FaEye />
					</span>
				</button>
			</div> */}
		</div>
	);
};

const HistoriqueGroupItem = ({ group }) => {
	const today = moment();
	const yesterday = moment().subtract(1, "day");
	return (
		<div className="container-historiques-admin-page mb-3">
			<div className="content-historique-admin-card-item">
				<div className="date-historique-admin-card">
					{moment(group?.date).isSame(today, "day") ? (
						<p className="title-date-historique-admin-card">Aujourd’hui</p>
					) : moment(group?.date).isSame(yesterday, "day") ? (
						<p className="title-date-historique-admin-card">Hier</p>
					) : (
						<p className="title-date-historique-admin-card">
							{formatterDateFrench(group?.date)}
						</p>
					)}
				</div>
				<div className="content-text-card-historique-admin">
					{group?.historiques?.map((historique) => (
						<HistoriqueItem item={historique} key={historique?.id} />
					))}
				</div>
			</div>
		</div>
	);
};

function HistoriquesAdmin() {
	const { limit, page, setPage, setPerPage } = usePagination(10);
	const [type, setType] = useState<string>("week");
	const [date, setDate] = useState<Date>(new Date());
	const [query, setQuery] = useState("");
	const {
		data = { count: 0, results: [] },
		isLoading,
		refetch,
	} = useGetListHistoriqueQuery({
		page: page,
		limit: limit,
		filter: type === "custom" ? "" : type,
		date: moment(date).format("DD-MM-YYYY"),
		q: query,
	});

	const onChangeDate = (start: any) => {
		setDate(start);
	};

	useEffect(() => {
		if (!isLoading) {
			refetch();
		}
	}, []);
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Historiques</h3>
				</div>
			</div>
			<div className="container-admin-page-table admin-table mt-4">
				<div className="content-chart-title mb-4">
					<h3 className="chart-item-admin-title mb-0">Filtrer par date</h3>
					<div className="chart-item-admin-week-indicator-container">
						<DatePicker
							selected={date}
							onChange={(date: any) => onChangeDate(date)}
							startDate={date}
							locale="fr"
							className="chart-item-admin-week-indicator"
							showMonthYearPicker={type === "month"}
							showYearPicker={type === "year"}
							dateFormat={
								type === "month"
									? "MMMM/yyyy"
									: type === "year"
									? "yyyy"
									: "dd/MM/yyyy"
							}
						/>
						<div className="chart-item-admin-week-indicator-statut"></div>
					</div>
					<div className="content-select-date-graph gap-3 d-flex">
						<button
							className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${
								type === "year" ? "fc-button-active" : ""
							}`}
							onClick={() => setType("year")}
						>
							Année
						</button>
						<button
							className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${
								type === "month" ? "fc-button-active" : ""
							}`}
							onClick={() => setType("month")}
						>
							Mois
						</button>
						<button
							className={`fc-timeGridWeek-button fc-button fc-button-primary btn-type-date ${
								type === "week" ? "fc-button-active" : ""
							}`}
							onClick={() => {
								setType((prev) => {
									if (prev !== "week") {
										setDate(new Date());
									}
									return "week";
								});
							}}
						>
							Semaine
						</button>
					</div>
					<div className="mon-doc-btn-filter-container gap-4 pb-2">
						<input
							type="search"
							className="form-control form-control-search"
							placeholder="Tapez pour rechercher"
							onChange={(e) => setQuery(e?.target?.value)}
						/>
					</div>
				</div>
				{/* Content */}
				{!!isLoading && <p>Loading...</p>}
				{!isLoading &&
					(data?.results?.length ? (
						data?.results?.map((g) => <HistoriqueGroupItem group={g} key={g?.date} />)
					) : (
						<AlertInfo message="Aucune donnée trouvée" />
					))}
				<CustomPagination
					nbPages={data?.count}
					page={page}
					perPage={limit}
					onChange={(page, perPage) => {
						setPage(page);
						setPerPage(perPage);
					}}
				/>
			</div>
		</div>
	);
}

export default HistoriquesAdmin;
