import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiZap } from "react-icons/fi";
import { MdOutlineAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDeleteOfferMutation, useGetOfferQuery } from "../../../../../utils/api/offer/offer.api";
import { IOffer } from "../../../../../utils/api/offer/offer.type";
import { useDelete } from "../../../../../utils/hooks";
import { createMarkup, formatMontant } from "../../../../../utils/Utils";
import { ButtonDelete, ButtonEditLink } from "../../../../common/Button";

export function DeleteOffer({ item }: { item: IOffer }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteOfferMutation();
	const onDelete = useDelete<IOffer>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette offre?",
		successMessage: "Offre supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

const OffersList = () => {
	const { data } = useGetOfferQuery();
	return (
		<div className="offers-tabs">
			<div className="container-title-page-admin">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">Liste des offres</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<Link to="abonnements/ajouter" className="btn btn-page-title-admin">
						<MdOutlineAdd />
						<span className="ps-2">Ajouter une offre</span>
					</Link>
				</div>
			</div>
			<div className="list-offers row mt-5">
				{data?.map((item) => (
					<div className="col-md-4 col-abonnement-home-page mb-3" key={item?.slug}>
						<div className="content-col-abonnement-home-page py-1">
							<div className="flex-r mb-4">
								<div className="dropdown">
									<button
										className="dropdown-toggle btn-offer-dropdown"
										type="button"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<BsThreeDotsVertical />
									</button>
									<ul className="dropdown-menu dropdown-menu-start-custom px-3">
										<li className="d-flex align-items-center justify-content-between">
											<ButtonEditLink
												to={`abonnements/${item?.slug}/modifier`}
												state={item}
											/>
											<DeleteOffer item={item} />
										</li>
									</ul>
								</div>
							</div>
							<div className="content-icon-head flex-c mb-3">
								<div className="icon-head-abonnement">
									<FiZap />
								</div>
							</div>
							<p className="subtitle-abonnement-item">{item?.title}</p>
							<h2 className="title-abonnement-card offer-price">
								{formatMontant(item?.price)}/m
							</h2>
							<p className="text-describe-abonnement-card">{item?.offer_text}</p>
							<div className="abonnement-list-content p-3">
								<div
									className="list-offer"
									dangerouslySetInnerHTML={createMarkup(item?.description)}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default OffersList;
