import moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import {
	useDeleteArticleMutation,
	useGetArticlesByAuthorQuery,
} from "../../../../utils/api/article/article.api";
import { IArticle } from "../../../../utils/api/article/article.type";
import { useDelete, usePagination } from "../../../../utils/hooks";
import { createMarkup, getImage, isShowPagination } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { ButtonDelete, ButtonEditLink, ButtonViewLink } from "../../../common/Button";
import { CustomPagination } from "../GestionLivreAuteur/ListLivresAuteurTable/ListLivresAuteurTable";
import "./Actualites.css";

export function DeleteArticle({ item }: { item: IArticle }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteArticleMutation();
	const onDelete = useDelete<IArticle>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet article?",
		successMessage: "Article supprimé",
	});

	return (
		<div className="btn-action-item">
			<ButtonDelete onClick={onDelete} />
		</div>
	);
}
export function ArticleItem({ item }: { item: IArticle }) {
	return (
		<div className="card post-card">
			<div className="card-img">
				<img src={getImage(item?.image)} className="card-img-top w-100" alt="image" />
			</div>
			<div className="card-body">
				<h5 className="card-title">{item?.title?.slice(0, 30)}</h5>
				<div
					className="card-text card-desc"
					dangerouslySetInnerHTML={createMarkup(item?.content?.slice(0, 100))}
				/>

				<div className="post-relative-time d-flex align-items-center gap-1">
					<AiOutlineClockCircle fontSize={16} />
					<span>{moment(item?.created_at)?.fromNow()}</span>
				</div>
			</div>
			<div className="actions-btn">
				<div className="btn-action-item">
					<ButtonViewLink to={`${item?.slug}/`} state={item} />
				</div>
				<div className="btn-action-item">
					<ButtonEditLink to={`${item?.slug}/modifier`} state={item} />
				</div>
				<DeleteArticle item={item} />
			</div>
		</div>
	);
}

const GestionArticles = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { limit, page, setPage, setPerPage } = usePagination(12);
	const { data, isLoading } = useGetArticlesByAuthorQuery({ limit, page, slug: user?.slug });
	console.log({ data });
	return (
		<div className="list-admin-admin">
			<div className="container-title-page-admin">
				<div className="content-title-admin d-fle">
					<h3 className="h3-title-admin">Fil d’actualité</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<Link to="ajouter" className="btn btn-page-title-admin">
						<MdOutlineAdd />
						<span className="ps-2">Nouvel article</span>
					</Link>
				</div>
			</div>
			<div className="mt-4">
				<div className="row">
					{!isLoading ? (
						data && data?.results?.length > 0 ? (
							<>
								{data?.results?.map((item) => (
									<div className="col-md-6 col-lg-4 mb-3" key={item?.slug}>
										<ArticleItem item={item} />
									</div>
								))}
								{isShowPagination(data?.count || 0, limit) && (
									<CustomPagination
										nbPages={data?.count}
										page={page}
										onChange={(page, perPage) => {
											setPerPage(perPage);
											setPage(page);
										}}
										perPage={limit}
									/>
								)}
							</>
						) : (
							<AlertInfo message="Aucune donnée trouvée" />
						)
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default GestionArticles;
