import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import { ICondition, conditionType } from "../../../../../utils/api/condition/condition.type";
import { Link } from "react-router-dom";
import { createMarkup } from "../../../../../utils/Utils";
import { AlertInfo } from "../../../../common/Alert";

export interface ConditionProps {
	content?: ICondition;
	type?: conditionType;
}
const Condition = ({ content, type }: ConditionProps) => {
	return (
		<div className="cgu-admin">
			<div className="container-title-page-admin mb-4">
				<div className="content-title-admin">
					<h3 className="h3-title-admin">{content?.title}</h3>
				</div>
				<div className="content-btn-action-title-page-admin">
					<Link
						to={
							type == conditionType.cgu
								? "/admin/ajouter-cgu"
								: "/admin/ajouter-politique-de-confidentialite"
						}
						className="btn btn-page-title-admin"
						state={{ content, type }}
					>
						<MdOutlineAdd />
						<span className="ps-2">Mettre à jour</span>
					</Link>
				</div>
			</div>
			{content ? (
				<div
					className="content-text-policy-admin  text-policy-admin"
					dangerouslySetInnerHTML={createMarkup(content?.description)}
				/>
			) : (
				<AlertInfo message="Aucun contenu pour cette page" />
			)}
		</div>
	);
};
export default Condition;
